import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { FcRefresh } from 'react-icons/fc';
import { onError } from "../libs/errorLib";
import "./Profile.css";
import "../components/LoaderButton.css";
import { Card } from "react-bootstrap";


export default function Profile() {
  const [currentUser, setCurrentUser] = useState(null);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        setIsLoading(true);
        const currentUser = await Auth.currentAuthenticatedUser();
        setCurrentUser(currentUser);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function renderLoading() {
    return (
      <div className="Profile">
        <div>
          <h2>Profile</h2>
          `` <FcRefresh className="spinning"/>
        </div>
      </div>
    )
  }


  function renderProfile() {
    return (
      <div className="Profile">
        {currentUser && (
          <Card bg={"dark"}>
            <Card.Body style={{ padding: 20, marginTop: 0 }}>
              <Card.Text>Email: <span>{currentUser.attributes.email}</span></Card.Text>
              <Card.Text>Verified: <span>{currentUser.attributes.email_verified ? "YES" : "NO"}</span></Card.Text>
              <Card.Text>UserID: <span>{currentUser.attributes.sub.slice(0, 8)}</span></Card.Text>
            </Card.Body>
          </Card>
        )}
      </div>
    );
  }

  return (
    <div>
      {isLoading ? renderLoading() : renderProfile()}
    </div>
  )
}
