import React,
{ Component}  from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  FormLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./ResetPassword.css";
import { GrStatusGood } from 'react-icons/gr';


export default class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			code: "",
			email: "",
			password: "",
			codeSent: false,
			confirmed: false,
			confirmPassword: "",
			isConfirming: false,
			isSendingCode: false
		};
	}

	validateCodeForm() {
		return this.state.email.length > 0;
	}

	validateResetForm() {
		return (
			this.state.code.length > 0 &&
			this.state.password.length > 0 &&
			this.state.password === this.state.confirmPassword
		);
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	handleSendCodeClick = async event => {
		event.preventDefault();

		this.setState({ isSendingCode: true });

		try {
			await Auth.forgotPassword(this.state.email);
			this.setState({ codeSent: true });
		} catch (e) {
			alert(e.message);
			this.setState({ isSendingCode: false });
		}
	};

	handleConfirmClick = async event => {
		event.preventDefault();

		this.setState({ isConfirming: true });

		try {
			await Auth.forgotPasswordSubmit(
				this.state.email,
				this.state.code,
				this.state.password
			);
			this.setState({ confirmed: true });
		} catch (e) {
			alert(e.message);
			this.setState({ isConfirming: false });
		}
	};

	renderRequestCodeForm() {
		return (
			<form onSubmit={this.handleSendCodeClick}>
				<FormGroup bssize="large" controlId="email">
					<FormLabel>Email</FormLabel>
					<FormControl
						autoFocus
						type="email"
						value={this.state.email}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<LoaderButton
					block
					hei
					type="submit"
					bssize="large"
					variant="danger"
					loadingtext="Sending..."
					isLoading={this.state.isSendingCode}
					disabled={!this.validateCodeForm()}
				>
					Send Confirmation
				</LoaderButton>
			</form>
		);
	}


	renderConfirmationForm() {
		return (
			<form onSubmit={this.handleConfirmClick}>
				<FormGroup bssize="large" controlId="code">
					<FormLabel>Confirmation Code</FormLabel>
					<FormControl
						autoFocus
						type="tel"
						value={this.state.code}
						onChange={this.handleChange}
					/>
					<FormLabel>
						Please check your email ({this.state.email})
						for the confirmation code.
					</FormLabel>
				</FormGroup>
				<hr />
				<FormGroup bssize="large" controlId="password">
					<FormLabel>New Password</FormLabel>
					<FormControl
						type="password"
						value={this.state.password}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<FormGroup bssize="large" controlId="confirmPassword">
					<FormLabel>Confirm Password</FormLabel>
					<FormControl
						type="password"
						onChange={this.handleChange}
						value={this.state.confirmPassword}
					/>
				</FormGroup>
				<LoaderButton
					block
					type="submit"
					bssize="large"
					text="Confirm"
					loadingtext="Confirm..."
					isLoading={this.state.isConfirming}
					disabled={!this.validateResetForm()}
				>
					Confirm
				</LoaderButton>
			</form>
		);
	}

	renderSuccessMessage() {
		return (
			<div className="success">
				<GrStatusGood glyph="ok" />
				<p>Your password has been reset.</p>
				<p>
					<Link to="/login">
						Click here to login with your new credentials.
					</Link>
				</p>
			</div>
		);
	}

	render() {
		return (
			<div className="ResetPassword">
				{ !this.state.codeSent
					? this.renderRequestCodeForm()
					: !this.state.confirmed
						? this.renderConfirmationForm()
						: this.renderSuccessMessage()
				}
			</div>
		);
	}
}




