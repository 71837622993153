import React from "react"
import HLSVideoPlayer from "./HLSVideoPlayer"

import decibelPoster from "../images/decibelPoster.png"

import decibelStream from "../hoc/decibelStream"
const DvideoURL = decibelStream.channel100
// const posterImage = decibelStream.posterImage0

export default (props) => {
  // Add customized HLSSource component into video-react Player
  // The Component with `isVideoChild` attribute will be added into `Video` component
  // Please use this url if you test it from local:
  // http://www.streambox.fr/playlists/x36xhzz/x36xhzz.m3u8
  return (
    <div>
      <HLSVideoPlayer videoURL={DvideoURL} posterImage={decibelPoster} />
    </div>
  )
}
