import React from 'react'
import { useHistory } from 'react-router'

export default function CanceledStripeCharge() {
  const history = useHistory()
  return (
    <div onClick={()=>history.push("/for-sale")} className="canceled-stripe-charge">
      Your purchase has been canceled. If you would like to try again please click the "Try Again" button. 
      <div className="canceled-stripe-charge__div">Try Again
      </div>
    </div>
  )
}
