import React from "react";
import { useConversations } from "../contexts/ConversationProvider";
import OpenConversation from "./OpenConversation";
import Sidebar from "./Sidebar";

export default function Dashboard({ id }) {
    const {selectedConversation} = useConversations()
  return (
    <div className="d-flex" style={{ height: "80vh", backgroundColor: "lightGray" }}>
      <Sidebar id={id} />
      {selectedConversation && <OpenConversation />}
    </div>
  );
}