import React from 'react';
import { Link } from "react-router-dom";

import "./Purchase.css";

import movieData from "../hoc/movieData";
import { Card } from "react-bootstrap";

export default function Purchase(props) {
  const movie = movieData.movie;
  return (
    <div className="Purchase">
      <div className="row">
        <div className="col-sm-8">
          <Card bg={"dark"}>
            <Card.Img variant="top" src={movie.image}/>
            <Card.Body style={{ padding: 20, marginTop: 0 }}>
              <Card.Title>{movie.title}</Card.Title>
              <Card.Subtitle>{movie.name}</Card.Subtitle>
              <Card.Text style={{ marginTop: 20 }}>
                {movie.overview}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-sm-4">
          <Card bg={"dark"}>
            <Card.Body style={{ padding: 20, marginTop: 0 }}>
              <Card.Title>{movie.title}</Card.Title>
              <Card.Subtitle style={{ marginBottom: 20 }}>{movie.name}</Card.Subtitle>
              <Card.Text>Date <span className="float-right text1">{movie.air_date}</span></Card.Text>
              <Card.Text>Showtime <span className="float-right text1">{movie.showtime}</span></Card.Text>
              <Card.Text>Running Time<span className="float-right text1">{movie.runtime}</span></Card.Text>
              <Card.Text style={{marginTop: 20}}><strong>Ticket Price</strong><span className="float-right totalText1"><span
                className="totalText2">{movie.price_display}</span></span></Card.Text>
            </Card.Body>
            <Link to={`/checkout`} className="btn card-footer text-center">
              PURCHASE &#8594;
            </Link>
          </Card>
        </div>
      </div>
    </div>
  );
}














