import React, { useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Auth } from "aws-amplify"
import stripeCheckoutConfig from "../../hoc/stripeCheckoutConfig"

const stripeKey = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(stripeKey)


export default function StripeCheckout({ priceId, buttonText, card }) {
  const [userCogId, setUserCogId] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const handleCogId = async () => {
    try {
      const credentials = await Auth.currentUserCredentials()
      const cogIdTemp = credentials.identityId
      setUserCogId(cogIdTemp)
      Auth.currentSession().then((data) => {
        const email = data.idToken.payload.email
        setUserEmail(email)
      })
    } catch (error) {
      // console.log("[69] handleCogID error", JSON.stringify(error))
    }
  }

  useEffect(() => {
    handleCogId()
    return () => {}
  })

  const checkoutParams = {
    lineItems: [
      {
        price: priceId,
        quantity: 1,
      },
    ],
    clientReferenceId: userCogId,
    customerEmail: userEmail,
    mode: stripeCheckoutConfig.mode,
    successUrl: stripeCheckoutConfig.successUrl,
    cancelUrl: stripeCheckoutConfig.cancelUrl,
  }

  if (card && card.allowedCountries) {
    checkoutParams.shippingAddressCollection = {
      allowedCountries: card.allowedCountries
    }
  }


  // console.log("[17] priceId", checkoutParams)
  const handleClick = async (event) => {

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout(checkoutParams)
    // console.log("31 error", error)

    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  }


  return (
    <div>
      <button role="link" onClick={handleClick}>
        {buttonText}
      </button>
    </div>

  )
}
