const decibelStream = {
	channel100: "https://video.couchtour.tv/b8cb2e7c-d650-42e3-ba6f-187ce5a3fa86/hls/dB200thIssueShow_FullLivestream_1_Intro.m3u8",
	posterImage0: "/couchtour-pink-512x512.png",
	posterImage1: "/dB200thIssueShow_FacebookHeader.jpg",
	channel101: "https://decibel-stream.couchtour.tv/stream/index.m3u8",
}


export default decibelStream


