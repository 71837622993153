import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { useDispatch } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { useHistory } from "react-router-dom"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { onError } from "../libs/errorLib"
import BillingForm from "../components/BillingForm.js"
import "./Checkout.css"

import { processPaymentAsyncThunk } from "../redux-toolkit"

import movieData from "../hoc/movieData"

const stripeKey = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(stripeKey)

export default function Checkout() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  async function handleFormSubmit(amount, { token, error }) {
    if (error) {
      onError(error)
      return
    }

    setIsLoading(true)

    const movie = movieData.movies[0]
    const movieId = movie._id
    const moviePrice = movie.price
    const movieTitle = movie.title
    const currentUser = await Auth.currentAuthenticatedUser()
    const email = currentUser.attributes.email
    const purchaseOrder = {
      amount: moviePrice,
      source: token.id,
      movieId: movieId,
      movieTitle: movieTitle,
      email: email,
    }

    // console.log("[44] Checkout:handleFormSubmit", purchaseOrder)
    // dispatch(createTodoActionCreator({ desc: "purchase complete  $" + purchaseOrder.storage }))

    dispatch(processPaymentAsyncThunk(purchaseOrder))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // console.log("[50] Checkout:handleFormSubmit", originalPromiseResult)
        const successRoute = "/purchase/" + movieId + "/success"
        history.push(successRoute)
      })
      .catch((serializedError) => {
        // console.log("[55] Checkout:handleFormSubmit", serializedError)
        onError(serializedError)
        setIsLoading(false)
      })
  }

  return (
    <div className="CardInput">
      <Elements stripe={stripePromise}>
        <BillingForm
          movieData={movieData}
          isLoading={isLoading}
          onSubmit={handleFormSubmit}
        />
      </Elements>
    </div>
  )
}
