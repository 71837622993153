import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { logRocketUserConnect } from "../libs/errorLib"
// import { useLocation } from "react-router-dom"
import { Link, useHistory } from "react-router-dom"
import { Image, FormGroup, FormControl, FormLabel } from "react-bootstrap"
import LoaderButton from "../components/LoaderButton"
import { useAppContext } from "../libs/contextLib"
import { useFormFields } from "../libs/hooksLib"
import { onError } from "../libs/errorLib"
import { routeException } from "../libs/routeException"
import { useSocket } from "../libs/SocketProvider"
import "./Login.css"
import { ErrorMessageBox } from "../components/ErrorMessageBox"
// import { render } from "@testing-library/react";

export default function Login() {
  const {
    setIsAuthenticated,
    currentUser,
    setCurrentUser,
    setCurrentUserEmail,
    setCogId,
    // setStripeCustomerId
  } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  // const [loginUserVerified, setLoginUserVerified] = useState(false)
  const [showVerifyCodeEntry, setShowVerifyCodeEntry] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const history = useHistory()
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmationCode: "",
  })
  // const location = useLocation()

  const socket = useSocket()


  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0
  }

  //!~~~~~~~~~~~~HANDLE SUBMIT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  async function handleSubmit(event) {
    event.preventDefault()
    setIsLoading(true)
    const emailSubmit = fields.email.toLowerCase()

    try {
      const user = await Auth.signIn(emailSubmit, fields.password)

      const credentials = await Auth.currentUserCredentials()
      const cogId = credentials.identityId
      const currentUserAuthenticated = credentials.authenticated


      setCogId(cogId)
      // setLoginUserVerified(currentUserAuthenticated)
      setCurrentUser(user)

      Auth.currentSession()
        .then((data) => {
          const payload = {
            action: "user-login",
            message: {
              cogId: cogId,
              email: emailSubmit,
              emailVerified: currentUserAuthenticated,
              accessToken: data.accessToken,
              idToken: data.idToken,
              refreshToken: data.refreshToken,
              loggedIn: true,
            },
          }
          socket.send(JSON.stringify(payload))
          socket.onmessage = async (message) => {
            const socketRespData = JSON.parse(message.data)
            const socketRespAction = socketRespData.action
    
            if (socketRespAction === "user-login-success") {
              //! setStripeCustomerId(socketREspData.stripeUserId)
            } else {
            }
          }
        })

        .catch((err) => console.log(err)
        )

      if (currentUserAuthenticated === false) {
        resendConfirmationCode()
        // setLoginUserVerified(false)
      } else {
        // setLoginUserVerified(true)
      }

      logRocketUserConnect(user)
      setIsAuthenticated(true)
      setCurrentUserEmail(emailSubmit)
    } catch (e) {
      setIsLoading(false)

      onError(e)
      const routeExceptions = routeException(e)
      const route = routeExceptions.route
      const routeExceptionMessage = routeExceptions.message
      setErrorMessage(routeExceptionMessage)

      if (e.code === "UserNotConfirmedException") {
        setShowVerifyCodeEntry(true)
        resendConfirmationCode() //line 145
      }


      if (route === "/login" || e.code === "NotAuthorizedException") {
        if (e.code === "UserNotConfirmedException") {
          setShowVerifyCodeEntry(true)
          resendConfirmationCode() //line 159
        }
        // do nothing stay on page
      } else if (route !== null) {
        history.push({
          pathname: route.route,
          customNameData: emailSubmit,
          customFormData: fields.password,
        })
      }
    }
  }

  //!~~~~~~~~~~~~RESEND CONFIRMATION CODE~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  async function resendConfirmationCode() {
    const emailSubmit = fields.email.toLowerCase()

    try {
      await Auth.resendSignUp(emailSubmit)


      const payload = JSON.stringify({
        action: "user-verify-resend-success",
        message: {
          // cogId: cogIdState,
          username: emailSubmit,
          email: emailSubmit,
          // emailVerified: loginUserVerified,
          loggedIn: !!currentUser,
        },
      })
      socket.send(payload)

    } catch (err) {
      const payload = JSON.stringify({
        action: "user-verify-resend-error",
        message: {
          // cogId: cogIdState,
          username: emailSubmit,
          email: emailSubmit,
          // emailVerified: loginUserVerified,
          loggedIn: !!currentUser,
        },
      })
      socket.send(payload);
    }
  }

  //!~~~~~~~~~~~~HANDLE CONFIRM SIGNUP~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  async function handleConfirmSignUp(event) {
    event.preventDefault()
    setIsLoading(true)
    const emailSubmit = fields.email.toLowerCase()
    setShowVerifyCodeEntry(true)

    try {
      await Auth.confirmSignUp(emailSubmit, fields.confirmationCode)
      await Auth.signIn(emailSubmit, fields.password)
        .then((user) => console.log("[199] Auth.signin user", user))
        .catch((err) => {
          onError(err)
          const routeExceptions = routeException(err)
          const routeExceptionMessage = routeExceptions.message
          setErrorMessage(routeExceptionMessage)
          setIsLoading(false)
          setShowVerifyCodeEntry(false)
        })

      const credentials = await Auth.currentUserCredentials()
      const cogId = credentials.identityId
      const currentUserAuthenticated = credentials.authenticated
      setCogId(cogId)

      Auth.currentSession()
        .then((data) => {
          let payload = {
            action: "user-signup",
            message: {
              cogId: cogId,
              username: emailSubmit,
              email: emailSubmit,
              emailVerified: currentUserAuthenticated,
              accessToken: data.accessToken,
              idToken: data.idToken,
              refreshToken: data.refreshToken,
            },
          }
          socket.send(JSON.stringify(payload))
        })
        .catch((err) => console.log("[100] Auth.currentSession err", err))

      (true)
      setCurrentUser({
        username: emailSubmit,
        password: fields.password,
        emailVerified: currentUserAuthenticated,
      })

      history.push("/")
    } catch (error) {
      if (error.code === "CodeMismatchException") {
        const routeExceptions = routeException(error)
        const routeExceptionMessage = routeExceptions.message
        setErrorMessage(routeExceptionMessage)
        setIsLoading(false)
      }
    }
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmSignUp}>
        <FormGroup controlId="confirmationCode" bssize="large">
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <FormLabel>Please check your email for the code.</FormLabel>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    )
  }

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bssize="large">
          <FormLabel>
            <div>Email</div>
          </FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bssize="large">
          <FormLabel>
            <div>Password</div>
            {/* <div className="password-parameter">Must be 8 characters long with 1 lowercase and 1 number</div> */}
          </FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <Link to="/login/reset">Forgot password?</Link>
        <div className="Signup__login-link">
          <div>Haven't signed up yet?</div>
          <div
            className="Signup__login-link__link"
            onClick={() => history.push("/signup")}
          >
            {" "}
            Sign up here
          </div>
        </div>
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
          className="login-button"
        >
          LOGIN
        </LoaderButton>
        <Image
          className="icon-center decibel-login"
          src="decibel-header-logo.png"
          // src="CouchTourLogoRed2_300dpi-icon-flip.png"
          fluid
        />
      </form>
    )
  }

  return (
    <div className="Login">
      {errorMessage && <ErrorMessageBox>{errorMessage}</ErrorMessageBox>}
      {showVerifyCodeEntry === true ? renderConfirmationForm() : renderForm()}
    </div>
  )
}
