import React from "react";
import { Link } from "react-router-dom";
import Support from "./Support";
import "./Channels.css";


export default function Channels() {

	function renderChannels() {
		return (
			<div className="lander">
				<h1>CHANNELS</h1>
				<p>Enjoy our selection of 24 hour channels</p>
				<Link to="/channel100" className="btn btn-info btn-lg">Bisco 100</Link>
				<Link to="/channel101" className="btn btn-success btn-lg">Bisco 101</Link>
				<Link to="/channel102" className="btn btn-primary btn-lg">Bisco 102</Link>
			</div>
		);
	}

	return (
		<div className="Home">
			{renderChannels()}
			<Support />
		</div>
	);
}