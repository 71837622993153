import React from 'react'
import { Video } from '../../../components/Video/Video'
import { VideoMetadata } from '../../../components/VideoMetadata/VideoMetadata'
import { VideoInfoBox } from '../../../components/VideoInfoBox/VideoInfoBox'
// import { Comments } from '../../Comments/Comments'
// import { getAmountComments,getRelatedVideos, getVideoById } from '../../../store/reducers/videos'
// import { getChannel } from '../../../store/reducers/channels'
// import { getCommentsForVideo } from '../../../store/reducers/comments'
import { RelatedVideos } from '../../../components/RelatedVideos/RelatedVideos'
import './WatchContent.scss'
import { connect } from 'react-redux'
import { InfiniteScroll } from '../../../components/InfiniteScroll/InfiniteScroll'
import { concertsData } from "../../../hoc/concertsData"

class WatchContent extends React.Component {
  render() {
    if (!this.props.videoId) {
      return <div/>
    }
    const video = concertsData.find(item => item.id === this.props.videoId)
    return (
      <InfiniteScroll bottomReachedCallback={this.props.bottomReachedCallback} showLoader={this.shouldShowLoader()}>
        <div className='watch-grid'>
          <Video className='video' id={this.props.videoId} url={video.link}/>
          <VideoMetadata className='metadata' video={video}/>
          <VideoInfoBox className='video-info-box' video={video} channel={this.props.channel}/>
          <RelatedVideos className='related-videos' videos={this.props.relatedVideos}/>
          {/*<Comments className='comments' comments={this.props.comments}  amountComments={this.props.amountComments}/>*/}
        </div>
      </InfiniteScroll>
    )
  }

  shouldShowLoader() {
    return !!this.props.nextPageToken
  }
}

function mapStateToProps(state, props) {
  return {
    // relatedVideos: getRelatedVideos(state, props.videoId),
    // video: getVideoById(state, props.videoId),
    // channel: getChannel(state, props.channelId),
    // comments: getCommentsForVideo(state, props.videoId),
    // amountComments: getAmountComments(state, props.videoId)
  }
}

export default connect(mapStateToProps, null)(WatchContent)
