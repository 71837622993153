import React from "react"
import { Route, Switch } from "react-router-dom"
import NotFound from "./containers/NotFound"
import Home from "./containers/Home"
import Watch from "./containers/Watch/Watch"
import Login from "./containers/Login"
import Notes from "./containers/Notes"
import Signup from "./containers/Signup"
import Logout from "./containers/Logout"
import ChatApp from "./components/WebsocketChat/components/ChatApp"
import Support from "./containers/Support"
import NewNote from "./containers/NewNote"
import Profile from "./containers/Profile"
// import Checkout from "./payments/Checkout"
import Purchase from "./payments/Purchase"
import Settings from "./containers/Settings"
import Channels from "./containers/Channels"
// import Purchases from "./containers/Purchases"
import NotesIndex from "./containers/NotesIndex"
import Channel100 from "./containers/Channel100"
import Channel101 from "./containers/Channel101"
// import Channel102 from "./containers/Channel102"
import Livestream from "./containers/Livestream"
import Livestreams from "./containers/Livestreams"
import ChangeEmail from "./containers/ChangeEmail"
import ResetPassword from "./containers/ResetPassword"
import ChangePassword from "./containers/ChangePassword"
import ConfirmAccount from "./containers/ConfirmAccount"
import PurchaseSuccess from "./containers/PurchaseSuccess"
import AuthenticatedRoute from "./components/AuthenticatedRoute"
import UnauthenticatedRoute from "./components/UnauthenticatedRoute"
import "semantic-ui-css/semantic.min.css"
import { ConcertsDataForm } from "./components/VideoMetadataForm/VideoMetadataForm"
import { SixDigitVerification } from "./components/SixDigitVerification/SixDigitVerification"
import BillingForm from "./components/BillingForm"
import AddCardToStripe from "./payments/AddCardToStripe"
import { ChoosePaymentMethod } from "./components/ChoosePaymentMethod"
import Subscriptions from "./components/Subscriptions/Subscriptions"
import Products from "./components/Products/Products"
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy"
import TermsOfService from "./containers/TermsOfService/TermsOfService"
import Tickets from "./components/Tickets/Tickets"
import { WhatsForSale } from "./components/WhatsForSale/WhatsForSale"
import YourTickets from "./components/YourTickets/YourTickets"
import ViewVideoPage from "./components/ViewVideoPage/ViewVideoPage"
// import ViewVideoPageLive from "./components/ViewVideoPage/ViewVideoPageLive"
import StripeCheckout from "./components/StripeCheckout/StripeCheckout"
import CanceledStripeCharge from "./components/StripeCheckout/CanceledStripeCharge"
// import StripeCheckout from "./components/StripeCheckout/StripeCheckout"
// import { AppLayout } from "./components/AppLayout/AppLayout"
// import OnDemand from "./containers/OnDemand"

export default function Routes() {
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <AuthenticatedRoute path="/watch" render={() => <Watch />} />
        <Route exact path="/chat-app">
          <ChatApp />
        </Route>
        <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/logout">
          <Logout />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/concert-data-form">
          <ConcertsDataForm />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/pair">
          <SixDigitVerification />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/activate">
          <SixDigitVerification />
        </AuthenticatedRoute>
        <UnauthenticatedRoute exact path="/confirm-account">
          <ConfirmAccount />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/signup">
          <Signup />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/login/reset">
          <ResetPassword />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/purchase/:id">
          <Purchase />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/purchase/:id/success">
          <PurchaseSuccess />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/profile">
          <Profile />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/view-videos">
          <ViewVideoPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/stripe-checkout">
          <StripeCheckout />
        </AuthenticatedRoute>
        {/* <AuthenticatedRoute exact path="/purchases">
        <Purchases/>
      </AuthenticatedRoute> */}
        <AuthenticatedRoute exact path="/billing-form">
          <BillingForm />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/add-card">
          <AddCardToStripe />
        </AuthenticatedRoute>
        {/* <AuthenticatedRoute exact path="/checkout">
        <Checkout/>
      </AuthenticatedRoute> */}
        <AuthenticatedRoute exact path="/checkout">
          <ChoosePaymentMethod />
        </AuthenticatedRoute>
        {/* <AuthenticatedRoute exact path="/choose-payment-method">
        <ChoosePaymentMethod/>
      </AuthenticatedRoute> */}
        <AuthenticatedRoute exact path="/livestreams">
          <Livestreams />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/livestreams/:id">
          <Livestream />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/subscriptions">
          <Subscriptions />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/products">
          <Products />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/tickets">
          <Tickets />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/for-sale">
          <WhatsForSale />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/your-tickets">
          <YourTickets />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/ondemand">
          {/* <Home></Home> */}
          <ViewVideoPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/notes/new">
          <NewNote />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/notes/:id">
          <Notes />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/channels">
          <Channels />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/channel100">
          <Channel100 />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/channel101">
          <Channel101 />
        </AuthenticatedRoute>
        {/* <AuthenticatedRoute exact path="/channel102">
          <Channel102 />
        </AuthenticatedRoute> */}
        {/* <AuthenticatedRoute exact path="/video-broadcast">
          <ViewVideoPageLive />
        </AuthenticatedRoute> */}
        <AuthenticatedRoute exact path="/live">
          <Channel101 />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/cancel">
          <CanceledStripeCharge />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/notes">
          <NotesIndex />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings">
          <Settings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/support">
          <Support />
        </AuthenticatedRoute>
        <unAuthenticatedRoute exact path="/privacy-policy">
          <PrivacyPolicy />
        </unAuthenticatedRoute>
        <unAuthenticatedRoute exact path="/terms-of-service">
          <TermsOfService />
        </unAuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/email">
          <ChangeEmail />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/password">
          <ChangePassword />
        </AuthenticatedRoute>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  )
}
