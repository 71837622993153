import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";

import "./Settings.css";

export default class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = { };
	}

	render() {
		return (
			<div>
				<h2 className="icon-center">Settings</h2>
				<div className="Settings">
					<LinkContainer to="/settings/email">
						<LoaderButton block bssize="large">
							Change Email
						</LoaderButton>
					</LinkContainer>
					<LinkContainer to="/settings/password">
						<LoaderButton block bssize="large">
							Change Password
						</LoaderButton>
					</LinkContainer>
				</div>
			</div>
		);
	}
}
