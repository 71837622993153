const movieData = {
	movie: {
		movie_id: "oaejbv-asbdfsakjr3r23-u23ri13r1",
		_id: "MVh923hd",
		title: "Barber Acoustic",
		livestream: true,
		homescreen: true,
		name: "An Evening with Barber Acoustic",
		image: "/barber-acoustic.jpeg",
		release_date: null,
		air_date: "08.08.2020",
		showtime: "8 PM PST",
		number_of_episodes: 1,
		number_of_seasons: 1,
		runtime: "2hrs",
		episode_run_time: "2hrs",
		price_display: "$20",
		price: 2000,
		overview: "Barber Acoustic Live from the Oasis Pond Studios",
		video_url_ioio: "https://couchtour.prod.ioio.tv/streams/us-west-2/3859fdc0-2f69-43ba-95ca-bf05635ec9f9/master.m3u8",
		video_url: "https://cf98fa7b2ee4450e.mediapackage.us-east-1.amazonaws.com/out/v1/2cb24c9dab9646e5853a103f14d6391a/index.m3u8"
	},
	movies: [
		{
			movie_id: "4ihfaoa-aowjebfaowebfoawejfboawef",
			_id: "live_h28423",
			title: "Orchard Lounge & DrFameus",
			livestream: true,
			homescreen: true,
			text_overlay: false,
			name: "with DrFameus",
			image: "/OrchardLounge.jpg",
			release_date: null,
			air_date: "10.03.2020",
			showtime: "6 PM PST",
			number_of_episodes: 1,
			number_of_seasons: 1,
			runtime: "2hrs",
			episode_run_time: "4hrs",
			price_display: "$12",
			platform_fee: "0.90",
			platform_percent: "",
			price: 1200,
			overview: "Orchard Lounge DJ set with DrFameus show",
			video_url_ioio: "",
			video_url: ""
		},
		// {
		// 	text_overlay: true,
		// 	movie_id: "oaejbv-asbdfsakjr3r23-u23ri13r1",
		// 	_id: "MVh923hd",
		// 	title: "Barber Acoustic",
		// 	livestream: true,
		// 	homescreen: true,
		// 	name: "An Evening with Barber Acoustic",
		// 	image: "/barber-acoustic.jpeg",
		// 	release_date: null,
		// 	air_date: "08.08.2020",
		// 	showtime: "8 PM PST",
		// 	number_of_episodes: 1,
		// 	number_of_seasons: 1,
		// 	runtime: "2hrs",
		// 	episode_run_time: "2hrs",
		// 	price_display: "$20",
		// 	price: 2000,
		// 	overview: "Barber Acoustic Live from the Oasis Pond Studios",
		// 	video_url_ioio: "https://couchtour.prod.ioio.tv/streams/us-west-2/3859fdc0-2f69-43ba-95ca-bf05635ec9f9/master.m3u8",
		// 	video_url: "https://cf98fa7b2ee4450e.mediapackage.us-east-1.amazonaws.com/out/v1/2cb24c9dab9646e5853a103f14d6391a/index.m3u8"
		// }
	]
};


export default movieData;


// {
// 	"movieUuid": "oaejbv-asbdfsakjr3r23-u23ri13r1",
// 	"movieId": "MVh923hd",
// 	"title": "Barber Acoustic",
// 	"livestream": true,
// 	"homescreen": true,
// 	"name": "An Evening with Barber Acoustic",
// 	"image": "/barber-acoustic.jpeg",
// 	"releaseDay": 8,
// 	"releaseMonth": 10,
// 	"releaseYear": 2020,
// 	"showtimeHr24": 20,
// 	"showtimeMin": 0,
// 	"numberEpisodes": 1,
// 	"numberSeasons": 1,
// 	"runtime": "2hrs",
// 	"episodeRunTime": "2hrs",
// 	"priceDisplay": "20",
// 	"price": 2000,
// 	"currency": "usd",
// 	"overview": "Barber Acoustic Live from the Oasis Pond Studios",
// 	"videoURL": null
// }