import React, { useState, useRef, useEffect } from "react"
import { Auth } from "aws-amplify"
import { Link, NavLink, useHistory } from "react-router-dom"

import { Navbar } from "react-bootstrap"
import { useAppContext } from "../../libs/contextLib"
import { useSocket } from "../../libs/SocketProvider"

import { ReactComponent as ProfileIcon } from "../../images/profile-white.svg"
import { ReactComponent as MenuIcon } from "../../images/menu.svg"
// import { ReactComponent as LibraryMusicIcon } from "../../images/library_music-white.svg"
import { ReactComponent as SupportIcon } from "../../images/support-white.svg"
import { ReactComponent as SettingsIcon } from "../../images/settings-white.svg"
import { ReactComponent as LogoutIcon } from "../../images/logout-white.svg"
import { ReactComponent as LoginIcon } from "../../images/loginIconWhite.svg"
import { ReactComponent as SignupIcon } from "../../images/signupIcon.svg"
import { ReactComponent as FormIcon } from "../../images/formIcon.svg"
// import { ReactComponent as PairingIcon } from "../../images/pairingIcon.svg"
// import { SocketDisconnectHandler } from "../ErrorMessageBox"

// const headerImageLogo = "/CouchtourLogoBlue2_300dpi-03.png"
// const headerAlt = "Couchtour.tv Logo"
const headerImageLogo = "/decibel-header-logo.png"
const headerAlt = "Decibel Magazine Logo"

export default function NavbarWithDropDownMenu() {
  const [expanded, setExpanded] = useState(false)
  const history = useHistory()
  const {
    currentUserEmail,
    userId,
    setCurrentUser,
    isAuthenticated,
    setIsAuthenticated,
    // socketOpen
  } = useAppContext()
  const container = useRef(null)
  const socket = useSocket()

  const handleClickOutside = (event) => {
    if (container.current && !container.current.contains(event.target)) {
      setExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const Danbar = (props) => {
    return (
      <div>
        <nav className="danbar">
          <div className="danbar__link">
            <Navbar.Brand>
              <Link to="/" onClick={() => setExpanded(false)}>
                <img
                  className="navigation--container--logo"
                  src={headerImageLogo}
                  alt={headerAlt}
                />
              </Link>
            </Navbar.Brand>
            {/* <div className="danbar__link__tickets">
              <NavLink
                className="danbar__link__tickets__link"
                activeStyle={{ borderBottom: "2px solid #3c8fe6"}}
                to="/your-tickets"
                onClick={() => setExpanded(false)}
              >
                TICKETS
              </NavLink>
            </div> */}
            <div className="danbar__link__tickets">
              <NavLink
                className="danbar__link__tickets__link"
                activeStyle={{ borderBottom: "2px solid #3c8fe6"}}
                to="/for-sale"
                onClick={() => setExpanded(false)}
              >
                UPCOMING SHOWS
              </NavLink>
            </div>
            <div className="danbar__link__tickets">
              <NavLink
                className="danbar__link__tickets__link"
                activeStyle={{ borderBottom: "2px solid #3c8fe6"}}
                to="/view-videos"
                onClick={() => setExpanded(false)}
              >
                LIVE BROADCAST
              </NavLink>
            </div>
          </div>
          <ul className="danbar__nav">{props.children}</ul>
        </nav>
      </div>
    )
  }

  const DanItem = (props) => {
    return (
      <li className="dan-item">
        <button
          className="dan-icon-button"
          onClick={() => setExpanded(!expanded)}
        >
          <MenuIcon className="dan-item__icon" />
        </button>
        {expanded && props.children}
      </li>
    )
  }

  const DanDownMenu = () => {
    const DanDownItem = (props) => {
      return (
        <div className="dandown-menu-item">
          {/* <span className="dan-icon-button">{props.leftIcon}</span> */}
          <div className="dandown-menu-item__text">{props.children}</div>
          <span className="dan-icon-button-right">{props.rightIcon}</span>
        </div>
      )
    }

    return (
      <div className="dandown" ref={container}>
        {!isAuthenticated ? (
          <div>
            <div>
              <div onClick={() => setExpanded(false)}>
                <Link to="/signup">
                  <DanDownItem
                    rightIcon={
                      <SignupIcon style={{ transform: "scale(.8)" }} />
                    }
                  >
                    Sign Up
                  </DanDownItem>
                </Link>
              </div>
            </div>
            <div onClick={() => setExpanded(false)}>
              <Link to="/login">
                <DanDownItem
                  rightIcon={<LoginIcon style={{ transform: "scale(.8)" }} />}
                >
                  Login
                </DanDownItem>
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <div onClick={() => setExpanded(false)}>
              <Link to="/profile">
                <DanDownItem rightIcon={<ProfileIcon />}>Profile</DanDownItem>
              </Link>
            </div>
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/purchases">
                <DanDownItem rightIcon={<LibraryMusicIcon />}>
                  Purchases
                </DanDownItem>
              </Link>
            </div> */}
            <div onClick={() => setExpanded(false)}>
              <Link to="/support">
                <DanDownItem rightIcon={<SupportIcon />}>Support</DanDownItem>
              </Link>
            </div>
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/settings">
                <DanDownItem rightIcon={<SettingsIcon />}>Settings</DanDownItem>
              </Link>
            </div> */}
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/channel102">
                <DanDownItem rightIcon={<SettingsIcon />}>Broadcast</DanDownItem>
              </Link>
            </div> */}
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/concert-data-form">
                <DanDownItem
                  rightIcon={<FormIcon style={{ transform: "scale(.8)" }} />}
                >
                  Form
                </DanDownItem>
              </Link>
            </div> */}
            {/*<div onClick={() => setExpanded(false)}>
              <Link to="/view-videos">
                <DanDownItem
                  rightIcon={<FormIcon style={{ transform: "scale(.8)" }} />}
                >
                  View Videos
                </DanDownItem>
              </Link>
            </div>*/}
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/subscriptions">
                <DanDownItem
                  rightIcon={<FormIcon style={{ transform: "scale(.8)" }} />}
                >
                  Subscriptions
                </DanDownItem>
              </Link>
            </div> */}
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/products">
                <DanDownItem
                  rightIcon={<FormIcon style={{ transform: "scale(.8)" }} />}
                >
                  Products
                </DanDownItem>
              </Link>
            </div> */}
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/your-tickets">
                <DanDownItem
                  rightIcon={<FormIcon style={{ transform: "scale(.8)" }} />}
                >
                  Tickets
                </DanDownItem>
              </Link>
            </div> */}
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/your-tickets">
                <DanDownItem
                  rightIcon={<FormIcon style={{ transform: "scale(.8)" }} />}
                >
                  Your Tickets
                </DanDownItem>
              </Link>
            </div> */}
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/for-sale">
                <DanDownItem
                  rightIcon={<FormIcon style={{ transform: "scale(.8)" }} />}
                >
                  What's For Sale
                </DanDownItem>
              </Link>
            </div> */}
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/pair">
                <DanDownItem
                  rightIcon={<PairingIcon style={{ transform: "scale(.8)" }} />}
                >
                  Pairing
                </DanDownItem>
              </Link>
            </div> */}
            {/* <div onClick={() => setExpanded(false)}>
              <Link to="/checkout">
                <DanDownItem
                  rightIcon={<PairingIcon style={{ transform: "scale(.8)" }} />}
                >
                  Checkout
                </DanDownItem>
              </Link>
            </div> */}
            <div onClick={handleLogout}>
              <Link to="/logout">
                <DanDownItem rightIcon={<LogoutIcon />}>Logout</DanDownItem>
              </Link>
            </div>
          </div>
        )}
      </div>
    )
  }

  async function handleLogout() {
    const credentials = await Auth.currentUserCredentials()
    const cogId = credentials.identityId
    const currentUserAuthenticated = credentials.authenticated

    Auth.currentSession()
      .then((data) => {
        let payload = JSON.stringify({
          action: "user-logout",
          message: {
            userId: userId,
            cogId: cogId,

            email: currentUserEmail,
            emailVerified: currentUserAuthenticated,
            // accessToken: data.accessToken,
            // idToken: data.idToken,
            // refreshToken: data.refreshToken,
            loggedIn: false,
          },
        })
        socket.send(payload)
      })
      .catch((err) =>
        console.log("[158] navbar.js LOGOUT - currentSession err: ", err)
      )

    setIsAuthenticated(false)
    setExpanded(false)

    try {
      await Auth.signOut()
      setCurrentUser(null)
    } catch (e) {
    }
    history.push("/login")
  }

  return (
    <div>
      <Danbar>
        <DanItem icon={<ProfileIcon />}>
          <DanDownMenu />
        </DanItem>
      </Danbar>
    </div>
  )
}
