import React from "react"
import "./Video.scss"
import ReactHlsPlayer from "react-hls-player"

export function Video(props) {
  console.log("[6] video player props", props)
  return (
    <div className="video-container">
      <div className="video">
        <ReactHlsPlayer
          hlsConfig={{ enableWorker: false }}
          url={props.url}
          autoplay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </div>
    </div>
  )
}
