import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { useHistory } from "react-router-dom"
import { Image, FormGroup, FormControl, FormLabel } from "react-bootstrap"
import LoaderButton from "../components/LoaderButton"
import { useAppContext } from "../libs/contextLib"
import { useFormFields } from "../libs/hooksLib"
import { onError } from "../libs/errorLib"
import "./Signup.css"
import { useSocket } from "../libs/SocketProvider"
import { routeException } from "../libs/routeException"
import { ErrorMessageBox } from "../components/ErrorMessageBox"
// import { isStringTextContainingNode } from "typescript"

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  })
  const history = useHistory()
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const {
    setIsAuthenticated,
    // currentUserEmail,
    setCurrentUser,
    setCurrentUserEmail,
    setCogId,
    // setStripeCustomerId,
  } = useAppContext()

  const socket = useSocket()

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    )
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0
  }
  //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE SUBMIT~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  async function handleSubmit(event) {
    event.preventDefault()
    setIsLoading(true)
    const emailSubmit = fields.email.toLowerCase()
    try {
      const user = await Auth.signUp({
        username: emailSubmit,
        password: fields.password,
        attributes: {
          email: emailSubmit,
        },
      })

      setIsLoading(false)
      setCurrentUser(user)
      setUser(user)
    } catch (e) {
      onError(e)
      const routeExceptions = routeException(e)
      // const route = routeExceptions.route
      const routeExceptionMessage = routeExceptions.message
      setErrorMessage(routeExceptionMessage)
      setIsLoading(false)

      let payload = {
        action: "user-signup-error",
        message: {
          displayMessage: "user no created",
          email: emailSubmit,
        },
      }
      socket.send(JSON.stringify(payload))
    }
  }

  //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE CONFIRMATION SUBMIT~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  async function handleConfirmationSubmit(event) {
    event.preventDefault()
    setIsLoading(true)
    const emailSubmit = fields.email.toLowerCase()

    try {
      await Auth.confirmSignUp(emailSubmit, fields.confirmationCode)
      let confirmationPayload = {
        action: "user-verify-success",
        message: {
          username: emailSubmit,
          email: emailSubmit,
          loggedIn: false,
        },
      }
      socket.send(JSON.stringify(confirmationPayload))

      await Auth.signIn(emailSubmit, fields.password)

      const credentials = await Auth.currentUserCredentials()
      const cogId = credentials.identityId
      const currentUserAuthenticated = credentials.authenticated

      setCogId(cogId)

      Auth.currentSession()
        .then((data) => {
          let payload = {
            action: "user-signup",
            message: {
              cogId: cogId,
              username: emailSubmit,
              email: emailSubmit,
              emailVerified: currentUserAuthenticated,
              accessToken: data.accessToken,
              idToken: data.idToken,
              refreshToken: data.refreshToken,
            },
          }
          socket.send(JSON.stringify(payload))
          socket.onmessage = async (message) => {
            const socketRespData = JSON.parse(message.data)
            const socketRespAction = socketRespData.action
            if (socketRespAction === "user-signup-success") {
              //! setStripeCustomerId(socketREspData.stripeUserId)
            } else {
            }
          }
        })
        .catch((err) => console.log("[100] Auth.currentSession err", err))

      setCurrentUserEmail(emailSubmit)
      setIsAuthenticated(true)
      // history.push('/')
      //TODO recieve payload from api, add message.user to global state
    } catch (e) {
      let confirmationErrorPayload = JSON.stringify({
        action: "user-verify-error",
        message: {
          username: emailSubmit,
          email: emailSubmit,
          loggedIn: false,
        },
      })

      socket.send(confirmationErrorPayload)

      onError(e)
      setIsLoading(false)
      // alert(e)

      const routeExceptions = routeException(e)
      // const route = routeExceptions.route
      const routeExceptionMessage = routeExceptions.message
      setErrorMessage(routeExceptionMessage)
      // history.push(route)
    }
  }

  function renderConfirmationForm() {
    const resendConfirmationCode = () => {
      Auth.resendSignUp(fields.email)
    }
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bssize="large">
          <FormLabel>Please check your email for the code.</FormLabel>
          <FormLabel>
            To resend confirmation code
            <div
              className="confirm-account-link-blue"
              onClick={resendConfirmationCode}
            >
              {" "}
              Click Here
            </div>
          </FormLabel>
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    )
  }

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bssize="large">
          <FormLabel>
            <div>Email</div>
          </FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bssize="large">
          <FormLabel>
            <div>Password</div>
            <div className="password-parameter">Must be 8 characters long </div>
            <div className="password-parameter">Must contain 1 lowercase and 1 number</div>
          </FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bssize="large">
          <FormLabel>
            <div>Confirm Password</div>
          </FormLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <div className="Signup__login-link">
          <div>Already have an account?</div>
          <div
            className="Signup__login-link__link"
            onClick={() => history.push("/login")}
          >
            {" "}
            Login here
          </div>
        </div>
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
          className="login-button"
        >
          SIGNUP
        </LoaderButton>
        <Image
          className="icon-center decibel-login"
          src="decibel-header-logo.png"
          // src="CouchTourLogoRed2_300dpi-icon-flip.png"
          fluid
        />
      </form>
    )
  }

  return (
    <div className="Signup">
      {errorMessage && <ErrorMessageBox>{errorMessage}</ErrorMessageBox>}
      {user === null ? renderForm() : renderConfirmationForm()}
    </div>
  )
}
