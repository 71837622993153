import React from "react";
import { Jumbotron } from "react-bootstrap";
import "./Home.css";


import movieData from "../hoc/movieData";

export default function PurchaseSuccess() {

	const movie = movieData.movies[0];

	function renderSuccess() {
		return (
			<div className="">
				<h1>{movie.title}</h1>
				<h2>{movie.air_date}-{movie.showtime}</h2>
			</div>
		);
	}

	return (
		<div>
			{/*<Image src="couchtour-logo1.png" fluid className="center" />*/}
			<div className="Home">
				<Jumbotron>
					<h1>You've got tickets!</h1>
					{renderSuccess()}
				</Jumbotron>
			</div>
		</div>
	);
}