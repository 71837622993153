import {
	createSlice,
	configureStore,
	getDefaultMiddleware,
	createAsyncThunk
 } from '@reduxjs/toolkit'

import { v1 as uuid } from "uuid"
import { API } from "aws-amplify"

import logger from 'redux-logger'


import movieData from "./hoc/movieData"
const moviesInitialState = movieData.movies


// THUNKS

export const processPaymentAsyncThunk = createAsyncThunk(
	'payments/process',
	async (details) => {
		// console.log("[25] THUNKS:processPaymentAsyncThunk", details)
		const response = await API.post(
			"notes",
			"/billing",
			{ body: details }
		)
		// console.log("[30] THUNKS:processPaymentAsyncThunk", response)
		return response
	}
)


// SLICES


const todosInitialState = []

const todosSlice = createSlice({
	name: 'todos',
	initialState: todosInitialState,
	reducers: {
		create: {
			reducer: (state , { payload }) => {
				state.push(payload)
			},
			prepare: ({ desc }) => ({
				payload: {
					id: uuid(),
					desc,
					isComplete: false
				}
			})
		},
		edit: (state, { payload }) => {
			const index = state.findIndex(todo => todo.id === payload.id)
			if (index !== -1) {
				state[index].desc = payload.desc
			}
		},
		toggle: (state, { payload }) => {
			const index = state.findIndex(todo => todo.id === payload.id)
			if (index !== -1) {
				// console.log('HERE IS TOGGLE', state[index])
				state[index].isComplete = payload.isComplete
			}
		},
		remove: (state, { payload }) => {
			const index = state.findIndex(todo => todo.id === payload.id)
			if (index !== -1) {
				state.splice(index, 1)
			}
		}
	}
})

const selectedTodoSlice = createSlice({
	name: 'selectedTodo',
	initialState: null,
	reducers: {
		select: (state, { payload }) => payload.id
	}
})

const counterSlice = createSlice({
	name: 'counter',
	initialState: 0,
	reducers: {},
	extraReducers: {
		[todosSlice.actions.create]: state => state + 1,
		[todosSlice.actions.edit]: state => state + 1,
		[todosSlice.actions.toggle]: state => state + 1,
		[todosSlice.actions.remove]: state => state + 1,
	}
})

const paymentsSlice = createSlice({
	name: 'payments',
	initialState: {
		entities: [],
		loading: 'idle',
		currentRequestId: undefined,
		error: null
	},
	reducers: {},
	extraReducers: {
		[processPaymentAsyncThunk.pending]: (state, action) => {
			if (state.loading === 'idle') {
				state.loading = 'pending'
				state.currentRequestId = action.meta.requestId
			}
		},
		[processPaymentAsyncThunk.fulfilled]: (state, action) => {
			const { requestId } = action.meta
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle'
				state.entities.push(action.payload)
				state.currentRequestId = action.meta.requestId
			}
		},
		[processPaymentAsyncThunk.rejected]: (state, action) => {
			const { requestId } = action.meta
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle'
				state.error = action.error
				state.currentRequestId = action.meta.requestId
			}
		}
	}
})

const cardsSlice = createSlice({
	name: 'cards',
	initialState: [],
	reducers: {
		create: (state , { payload }) => {
			state.push(payload)
		},
	}
})

const moviesSlice = createSlice({
	name: 'movies',
	initialState: moviesInitialState,
	reducers: {
		homescreen: (state, { payload }) => {
			state.push(payload)
		},
		livestreams: (state, { payload }) => {
			state.push(payload)
		},
	}
})



// REDUX TOOLKIT CALLS combineReducers automatically here
const reducer = {
	todos: todosSlice.reducer,
	selectedTodo: selectedTodoSlice.reducer,
	counter: counterSlice.reducer,
	payments: paymentsSlice.reducer,
	cards: cardsSlice.reducer,
	movies: moviesSlice.reducer,
}

// ACTIONS

export const {
	homescreen: homescreenMoviesActionCreator,
	livestreams: livestreamMoviesActionCreator,
} = moviesSlice.actions

export const {
	process: processPaymentsActionCreator,
} = paymentsSlice.actions

export const {
	create: createCardActionCreator,
} = cardsSlice.actions

export const {
	create: createTodoActionCreator,
	edit: editTodoActionCreator,
	toggle: toggleTodoActionCreator,
	remove: deleteTodoActionCreator,
} = todosSlice.actions

export const {
	select: selectTodoActionCreator
} = selectedTodoSlice.actions



// CONFIGURE STORE + MIDDLEWARE

export default configureStore({
	reducer,
	middleware: [...getDefaultMiddleware(), logger],
	devTools: process.env.REACT_APP_ENVIRONMENT !== 'production'
})


















