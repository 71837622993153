import React, { useState, useEffect } from "react"
import { Auth } from "aws-amplify"

import Routes from "./Routes"
import { AppContext } from "./libs/contextLib"
import { SocketProvider } from "./libs/SocketProvider"
// import { onError } from "./libs/errorLib";
import ErrorBoundary from "./components/ErrorBoundary"
import NavbarWithDropDownMenu from "./components/Navbar/Navbar"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

// import SocialMedia from "./components/SocialMedia"

// import {
// 	createTodoActionCreator,
// 	editTodoActionCreator,
// 	toggleTodoActionCreator,
// 	removeTodoActionCreator,
// 	selectTodoActionCreator
// } from "./redux-toolkit"
import "./App.scss"
import { ShowBuyTixMessage, SocketDisconnectHandler } from "./components/ErrorMessageBox"

const stripeKey = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(stripeKey)
//TIP dont call loadSTripe with render method of component
// dont want to load more than you have to
// only load once per page

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [currentUser, setCurrentUser] = useState(null)
  const [currentUserEmail, setCurrentUserEmail] = useState(null)
  const [cogId, setCogId] = useState("")
  const [userId, setUserId] = useState("")
  const [cardOptions, setCardOptions] = useState([])
  const [paymentIntent, setPaymentIntent] = useState(null)
  const [purchaseSuccess, setPurchaseSuccess] = useState("")
  const [userPurchases, setUserPurchases] = useState("")
  const [chosenCardGlobal, setChosenCardGlobal] = useState("")
  const [clientSecretGlobal, setClientSecretGlobal] = useState("")
  const [socketOpen, setSocketOpen] = useState(false)
  const [paymentMethodGlobal, setPaymentMethodGlobal] = useState(false)
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(false)
  const [stripeCustomerId, setStripeCustomerId] = useState("")
  const [productList, setProductList] = useState([])
  const [events, setEvents] = useState([])
  const [showBuyTixMsg, setShowBuyTixMsg] = useState(false)
  // const [newUserConfirmed,setNewUserConfirmed] = useState(false);

  useEffect(() => {
    document.title = process.env.REACT_APP_COMPANY_NAME
    onLoad()
  }, [])

  async function onLoad() {
    try {
      await Auth.currentSession()
      const currentUser = await Auth.currentAuthenticatedUser()
      setIsAuthenticated(true)
      setCurrentUser({
        ...currentUser.attributes,
        ...currentUser.signInUserSession.accessToken,
      })
    } catch (e) {
      if (e !== "No current user") {
        // onError(e || "onLoad Error")
      }
    }

    setIsAuthenticating(false)
  }

  // async function handleLogout() {
  //   await Auth.signOut()

  //   setIsAuthenticated(false)
  //   setCurrentUser(null)
  //   // setExpanded(false)
  //   history.push("/login")
  // }

  const handleShowBuyTixMsg = () => {
    setTimeout(() => setShowBuyTixMsg(false), 5000)
    return <ShowBuyTixMessage />
  }

  return (
    !isAuthenticating && (
      <div className="App">
        {/* <div className="App container"> */}
        <ErrorBoundary>
          <AppContext.Provider
            value={{
              isAuthenticated,
              setIsAuthenticated,
              setIsAuthenticating,
              currentUser,
              setCurrentUser,
              currentUserEmail,
              setCurrentUserEmail,
              userId,
              setUserId,
              cogId,
              setCogId,
              paymentIntent,
              setPaymentIntent,
              cardOptions, //todo use card option to display users card options
              setCardOptions,
              purchaseSuccess, //todo use purchaseSuccess === "SUCCESS" to display purchase success screen, purchaseSuccess === "FAILURE" for error screen
              setPurchaseSuccess,
              userPurchases,
              setUserPurchases,
              chosenCardGlobal,
              setChosenCardGlobal,
              clientSecretGlobal,
              setClientSecretGlobal,
              socketOpen,
              setSocketOpen,
              paymentMethodGlobal,
              setPaymentMethodGlobal,
              paymentErrorMsg,
              setPaymentErrorMsg,
              stripeCustomerId,
              setStripeCustomerId,
              productList,
              setProductList,
              events,
              setEvents,
              showBuyTixMsg,
              setShowBuyTixMsg,
              // newUserConfirmed,
              // setNewUserConfirmed
            }}
          >
            <SocketProvider>
              <Elements stripe={stripePromise}>
                <div className="app__navbar">
                  <NavbarWithDropDownMenu />
                  {/* {socketOpen ? null : <SocketDisconnectHandler /> }: */}
                  {showBuyTixMsg ? handleShowBuyTixMsg() : null}:
                </div>
                <div className="app__body">
                  <Routes />
                </div>
              </Elements>
            </SocketProvider>
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    )
  )
}

export default App
