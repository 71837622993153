import React from "react"
// import { Image } from "react-bootstrap"
import {  useHistory } from "react-router-dom"
import { useAppContext } from "../libs/contextLib"

// import DecibelLanding from "../images/decibelLanding.jpeg"

import Channel100 from "./Channel100"
import "./Testers.css"

// import { useSelector } from "react-redux"

// import Livestreams from "./Livestreams.js"

export default function Home() {
  // const { isAuthenticated } = useAppContext()
  // const movies = useSelector((state) => state.movies)

  // function renderLander() {
  //   return (
  //     <div className="lander">
  //       <Image src="apple-touch-icon.png" fluid className="icon-center" />
  //       <p>See the best shows live from the comfort of your home</p>
  //       <Link to="/login" className="btn btn-info btn-lg">
  //         Login
  //       </Link>
  //       <Link to="/signup" className="btn btn-success btn-lg">
  //         Signup{" "}
  //       </Link>
  //     </div>
  //   )
  // }

  // function renderHome() {
  //   return (
  //     <div className="Home icon-center">
  //       {/*				<h2 className="center">Livestreams</h2>
	// 			<h4 className="center">Artists Direct to Audience</h4>*/}
  //       <Image src="/decibel-header-logo.png" fluid />
  //     </div>
  //   )
  // }

  function renderPlayer() {
    return (
      <div className="Home icon-center homepage__left__player">
        <Channel100 />
      </div>
    )
  }

  // function renderConstruction() {
  //   return (
  //     <div className="Home icon-center">
  //       <h2>Welcome!</h2>
  //       <p>
  //         We are now able to bring you the very best concert experience
  //         available on the web today
  //       </p>
  //       <p>Please join us for our first concert</p>
  //       <a href="https://player.couchtour.tv">
  //         <h3>Orchard Lounge and DrFameus</h3>
  //         <p>See the player and buy tickets!</p>
  //       </a>
  //       <p>
  //         You will have to make a temporary account in the player. We apologize
  //         for any inconvenience, this is only temporary
  //       </p>
  //     </div>
  //   )
  // }
  const { currentUser } = useAppContext()
  const history = useHistory()


  return (
    <div>
      <div className="homepage">
        {/* <div className="Home"> */}
        {/*{ isAuthenticated ? renderHome() : renderLander()}*/}
        <div className="homepage__header">
          DECIBEL MAGAZINE LIVESTREAM PORTAL
        </div>
        {!currentUser ? (
          <div
            onClick={() => history.push("/signup")}
            className="homepage__enter"
          >
            ENTER NOW
          </div>
        ) : null}
        <div className="homepage__left">{renderPlayer()}</div>
        {/* <div className="homepage__right">
          <img 
					alt="new"
					src={DecibelLanding}></img>
        </div> */}
      </div>
    </div>
  )
}
