import React, { useState, useContext, useEffect } from "react"
import { w3cwebsocket as W3CWebSocket } from "websocket"
import { concertsData } from "../hoc/concertsData"
import { useAppContext } from "../libs/contextLib"

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
const SocketContext = React.createContext()
const client = new W3CWebSocket(SOCKET_URL)

export function useSocket() {
  return useContext(SocketContext)
}

export function SocketProvider({ id, children }) {
  const {
    // setUserId,
    setCardOptions,
    setPaymentIntent,
    setUserPurchases,
    setPurchaseSuccess,
    setChosenCardGlobal,
    setClientSecretGlobal,
    setSocketOpen,
    // paymentMethodGlobal,
  } = useAppContext()

  const [socket, setSocket] = useState(client)

  useEffect(() => {
    client.onopen = () => {
      setSocketOpen(true)
      console.log("WEBSOCKET CONNECTED 445")
    }
    client.onmessage = (message) => {
      const socketRespData = JSON.parse(message.data)

      const socketRespMessage = socketRespData.message
      const socketRespAction = socketRespData.action

      //libs/routeException to mimic route library for socketRespActions:
      // create socketRespAction library
      // const SocketRespAction = (action) => {actions and functions}

      if (socketRespAction === "delivervideos") {
        // console.log("\n \n \n \n I AM A delivervideos MESSAGE ")
        concertsData.concat(socketRespMessage)
      } else if (socketRespAction === "user-verify-success-resp") {
        // console.log("[36] user-verify-success-resp: ", socketRespMessage)
      } else if (socketRespAction === "user-verify-error-resp") {
        // console.log("[38] user-verify-error-resp: ", socketRespMessage)
      } else if (socketRespAction === "user-verify-resend-success-resp") {
        // console.log("[41] user-verify-resend-success-resp: ", socketRespMessage)
      } else if (socketRespAction === "user-verify-resend-error-resp") {
        // console.log("[46] user-verify-resend-error-resp: ", socketRespMessage)
      } else if (socketRespAction === "user-signup-success") {
        // console.log("[50] user-signup-success: ", socketRespMessage)
        console.log("[56] user-login-success ID: ", socketRespMessage.ID)
      } else if (socketRespAction === "user-signup-error") {
        // console.log("[52] user-signup-error: ", socketRespMessage)
      } else if (socketRespAction === "user-login-success") {
        // console.log("[55] user-login-success: ", socketRespMessage)
        // console.log("[56] user-login-success ID: ", socketRespMessage.ID)
        // setUserId(socketRespMessage.ID)
      } else if (socketRespAction === "user-login-error") {
        // console.log("[58] user-login-error: ", socketRespMessage)
        console.log("[59] user-login-error ID: ", socketRespMessage.ID)
        // setUserId(socketRespMessage.ID)
      } else if (socketRespAction === "user-logout-success") {
        // console.log("[61] user-logout-success: ", socketRespMessage)
      } else if (socketRespAction === "user-login-error") {
        // console.log("[63] user-login-error: ", socketRespMessage)
        //!~~~~~~~~~~~~~~~~~~~` START OF STRIPE MESSAGE RESPONSES ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      } else if (socketRespAction === "create-payment-method-resp-success") {
        // console.log(
        //   "[65] create-payment-method-resp-success",
        //   socketRespMessage
        // )
      } else if (socketRespAction === "create-payment-method-resp-error") {
        // console.log("[67] create-payment-method-resp-error", socketRespMessage)
      } else if (socketRespAction === "get-payment-intent") {
        setPaymentIntent(socketRespMessage)
        // console.log("[79] get-payment-intent", socketRespMessage)
      } else if (socketRespAction === "get-user-credit-cards-resp-success") {
        // console.log(
        //   "[81] get-user-credit-cards-resp-success",
        //   socketRespMessage
        // )
        setCardOptions(socketRespMessage)
      } else if (socketRespAction === "get-user-credit-cards-resp-error") {
        // console.log("[84] get-user-credit-cards-resp-error", socketRespMessage)
      } else if (socketRespAction === "purchase-request-resp-success") {
        // console.log("[86] purchase-request-resp-success", socketRespMessage)
        setPurchaseSuccess("SUCCESS")
        setClientSecretGlobal(socketRespMessage)
      } else if (socketRespAction === "purchase-request-resp-error") {
        //B11 render error screen}
        const clientSecret = socketRespData.derp.client_secret
        // console.log("[90] client_secret", clientSecret)

        setClientSecretGlobal(clientSecret)
        setPurchaseSuccess("FAILURE")
        // console.log("[91] purchase-request-resp-error", socketRespMessage)
      } else if (socketRespAction === "view-purchases-resp-success") {
        // C set purchases to global state
        // console.log("[94] view-purchases-resp-success", socketRespMessage)
        setUserPurchases(socketRespMessage)
      } else if (socketRespAction === "view-purchases-resp-error") {
        // C  render error screen
        setPurchaseSuccess("FAILURE")
        // console.log("[99] view-purchases-resp-error", socketRespMessage)
      } else if (
        socketRespAction === "chose-existing-payment-method-resp-success"
      ) {
        setChosenCardGlobal(socketRespMessage)
        // console.log("[107] chose-existing-payment-resp-success")
      } else if (
        socketRespAction === "chose-existing-payment-method-resp-error"
      ) {
        // console.log("[109] chose-existing-payment-resp-error")
      }
    }

    client.onclose = function () {
      // console.log("echo-protocol Client Closed")
      setSocketOpen(false)
    }

    return () => {
      client.close()
    }
  }, [id, setCardOptions, setChosenCardGlobal, setClientSecretGlobal, setPaymentIntent, setPurchaseSuccess, setSocketOpen, setUserPurchases])

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}
