import React from "react"
import ReactDom from "react-dom"
import { ReactComponent as CloseCircle } from "../images/closeCircle.svg"

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",

  padding: "50px",
  zIndex: 1000,
  borderRadius: "20px",
}

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
}

export default function Modal({ open, children, onClose }) {
  if (!open) return null

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES}>
        <div className="modal_dan" style={MODAL_STYLES}>
          <div className="modal__button" onClick={onClose}>
            <CloseCircle />
          </div>
          <div className="modal__children">{children}</div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  )
}
