import React from "react"
import Checkout from "../payments/Checkout"
import "./Livestreams.css"


export default function Livestream() {

	return (
		<div className="">
			<Checkout />
		</div>
	)
}