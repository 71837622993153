import React, { useState, useEffect } from "react"
import { useHistory } from "react-router"
// import { ChoosePaymentMethod } from "../ChoosePaymentMethod"

export default function Products() {
  const history = useHistory()
  const [productList, setProductList] = useState([])
  const [productCount, setProductCount] = useState([])
  const product1 = {
    title: "The Disco Biscuits",
    date: "12/29/2021",
    price: 10000,
    platformFee: 10000 * .08,
    item_id: "jklh55345345jkhh3454kh4kjh223444",
  }
  const product2 = {
    title: "The Disco Biscuits",
    date: "12/30/2021",
    price: 12500,
    platformFee: 12500 * .08,
    item_id: "jklh55345345jkhh3454kh4kjh34555",
  }
  const product3 = {
    title: "The Disco Biscuits",
    date: "12/31/2021",
    price: 11500,
    platformFee: 11500 * .08,
    item_id: "jklh55345345jkhh3454kh4kjh55566",
  }

  const products = [product1, product2, product3]

  const handleOnClick = (product) => {
    // e.preventDefault()
    console.log("[23] product", product)

    setProductList(productList.concat(product))

    console.log("[25] productList", productList)
  }

  const handleCheckout = () => {
    // ChoosePaymentMethod(productList)
    history.push({
      pathname: "/checkout",
      state: { products: productList },
    })
  }

  const calcNumberOfProducts = () => {
    const listOfItemIds = productList.map((product) => {
      let listOfItemIds
      listOfItemIds = listOfItemIds.concat(product.item_id)
      return listOfItemIds
    })
    // console.log("[67] listOfItemIds", listOfItemIds)
    var counts = {}
    listOfItemIds.forEach((x) => {
      counts[x] = (counts[x] || 0) + 1
    })
    // console.log("[71] counts", counts)
    setProductCount(counts)

    //   listOfItemIds.sort()
    //   var current = null
    //   var cnt = 0
    //   for (var i = 0; i < listOfItemIds.length; i++) {
    //     if (listOfItemIds[i] != current) {
    //         if (cnt > 0) {
    //             document.write(current + ' comes --> ' + cnt + ' times<br>');
    //         }
    //         current = listOfItemIds[i];
    //         cnt = 1;
    //     } else {
    //         cnt++;
    //     }
    // }
    // if (cnt > 0) {
    //     document.write(current + ' comes --> ' + cnt + ' times');
    // }
  }

  useEffect(() => {
    calcNumberOfProducts()
    return () => {}
  }, [productList, calcNumberOfProducts])

  console.log("[108], productcount", productCount)

  return (
    <div className="merchandise-container">
      PRODUCTS
      {products.map((product, id) => {
        const productId = product.item_id
        const numOfItems = productCount[productId]
        return (
          <div className="merchandise" key={id} onClick={() => handleOnClick(product)}>
            <div className="merchandise__item">
              {product.title} {product.date}
            </div>
            <div className="merchandise__count">
              {numOfItems}
              {/* {productCount ? productCount.map((count, id)=> {
                console.log("[124] count", count)
                // if (product.item_id === productCount )
              }) : null } */}
            </div>
            {/* {productList[id].length} */}
          </div>
        )
      })}
      <button onClick={handleCheckout}>
        CHECKOUT
      </button>
    </div>
  )
}
