import decibelPoster from "../images/decibelPoster.png"

const eventDataDev = [
  {
    ID: "24f60b47-f814-4ece-8d98-fee2ec4e5e54",
    eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
    bandName: "200th Issue Show",
    image: decibelPoster,
    promoter: "Decibel Magazine",
    promoterLogo: null,
    timeOfEvent: "FRI/8:00 PM EST",
    isLive: false,
    landingImageUrl: "/dB200thIssueShow_logoRGB.png",
    eventFeature: {
      description: "200th Issue Show Extremely Ex-Stream",
      type: "coffee",
    },
    priceDescription: {
      description: "Tickets start at $6.66",
      type: "money",
    },
    eventDetail: {
      description: "Watch ",
      type: "flag",
    },
  },
]


const eventDataProd = [
  {
    ID: "24f60b47-f814-4ece-8d98-fee2ec4e5e54",
    eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
    bandName: "200th Issue Show",
    image: decibelPoster,
    promoter: "Decibel Magazine",
    promoterLogo: null,
    timeOfEvent: "FRI/8:00 PM EST",
    isLive: false,
    landingImageUrl: "/dB200thIssueShow_logoRGB.png",
    eventFeature: {
      description: "200th Issue Show Extremely Ex-Stream",
      type: "coffee",
    },
    priceDescription: {
      description: "Tickets start at $6.66",
      type: "money",
    },
    eventDetail: {
      description: "Watch ",
      type: "flag",
    },
  },
]



const eventExport = process.env.REACT_APP_ENVIRONMENT === 'production' ? eventDataProd : eventDataDev


export default eventExport;