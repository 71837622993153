import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./NotesIndex.css";


export default function Home() {
	const [notes, setNotes] = useState([]);
	const { isAuthenticated } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		async function onLoad() {
			if (!isAuthenticated) {
				return;
			}

			try {
				const notes = await loadNotes();
				setNotes(notes);
			} catch (e) {
				onError(e);
			}

			setIsLoading(false);
		}
		onLoad();

	}, [isAuthenticated]);

	function loadNotes() {
		return API.get("notes", "/notes");
	}

	function renderNotesList(notes) {
		return [{}].concat(notes).map((note, i) =>
			i !== 0 ? (
				<LinkContainer key={note.noteId} to={`/notes/${note.noteId}`}>
					<ListGroupItem header={note.content.trim().split("\n")[0]}>
						{"Created: " + new Date(note.createdAt).toLocaleString()}
					</ListGroupItem>
				</LinkContainer>
			) : (
				<LinkContainer key='new' to='/notes/new'>
					<ListGroupItem>
						<h4>
							<b>{"\uFF0B"}</b> Create a new note
						</h4>
					</ListGroupItem>
				</LinkContainer>
			)
		)
	}

	function renderNotes() {
		return (
			<div className="notes">
				<h2>Note Index</h2>
					<ListGroup>
						{!isLoading && renderNotesList(notes)}
					</ListGroup>
			</div>
		);
	}

	return (
		<div className="Home">
			{renderNotes()}
		</div>
	);
}