
let concertsData = []

// const concertsData = [
//   {
//     id: '20201016-1-tdb',
//     date: new Date('10-16-2020'),
//     publishedAt: new Date('10-16-2020'),
//     contentDetails: {
//       duration: 10000,
//     },
//     statistics: {
//       likeCount: 300,
//       dislikeCount: 100,
//     },
//     location: 'Yarmouth - MA',
//     bandName: 'The Disco Biscuits',
//     title: 'The Disco Biscuits - Yarmouth - MA',
//     description: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
//     accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
//     inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. \n
//     Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
//     quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque
//     porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
//     velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
//     aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
//     ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? \n
//     Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
//     molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?`,
//     photo: '/concertImages/01.png',
//     set: '1',
//     link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/8259de54144c4f6ab175dd7e35adeeda/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   },
//   {
//     id: '20201016-2-tdb',
//     date: new Date('10-16-2020'),
//     publishedAt: new Date('10-16-2020'),
//     contentDetails: {
//       duration: 10000,
//     },
//     statistics: {
//       likeCount: 300,
//       dislikeCount: 100,
//     },
//     location: 'Yarmouth - MA',
//     bandName: 'The Disco Biscuits',
//     title: 'The Disco Biscuits - Yarmouth - MA',
//     description: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
//     accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
//     inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. \n
//     Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
//     quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque
//     porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
//     velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
//     aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
//     ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? \n
//     Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
//     molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?`,
//     photo: '/concertImages/02.png',
//     set: '2',
//     link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/7896b196ce924ad0906b52904379fa71/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   },
//   {
//     id: '20201017-1-tdb',
//     date: new Date('10-17-2020'),
//     publishedAt: new Date('10-16-2020'),
//     contentDetails: {
//       duration: 10000,
//     },
//     statistics: {
//       likeCount: 300,
//       dislikeCount: 100,
//     },
//     location: 'Yarmouth - MA',
//     bandName: 'The Disco Biscuits',
//     title: 'The Disco Biscuits - Yarmouth - MA',
//     description: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
//     accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
//     inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. \n
//     Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
//     quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque
//     porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
//     velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
//     aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
//     ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? \n
//     Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
//     molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?`,
//     photo: '/concertImages/03.png',
//     set: '1',
//     link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/df6a669e2ec348c3b02506c5d7ec0427/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   },
//   {
//     id: '20201017-2-tdb',
//     date: new Date('10-17-2020'),
//     publishedAt: new Date('10-16-2020'),
//     contentDetails: {
//       duration: 10000,
//     },
//     statistics: {
//       likeCount: 300,
//       dislikeCount: 100,
//     },
//     location: 'Yarmouth - MA',
//     bandName: 'The Disco Biscuits',
//     title: 'The Disco Biscuits - Yarmouth - MA',
//     description: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
//     accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
//     inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. \n
//     Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
//     quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque
//     porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
//     velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
//     aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
//     ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? \n
//     Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
//     molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?`,
//     photo: '/concertImages/04.png',
//     set: '2',
//     link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/93397cd6da2b4610944feab9d4e407d6/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   },
//   {
//     id: '20201018-1-tdb',
//     date: new Date('10-18-2020'),
//     publishedAt: new Date('10-18-2020'),
//     contentDetails: {
//       duration: 10000,
//     },
//     statistics: {
//       likeCount: 300,
//       dislikeCount: 100,
//     },
//     location: 'Yarmouth - MA',
//     bandName: 'The Disco Biscuits',
//     title: 'The Disco Biscuits - Yarmouth - MA',
//     description: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
//     accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
//     inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. \n
//     Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
//     quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque
//     porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
//     velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
//     aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
//     ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? \n
//     Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
//     molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?`,
//     photo: '/concertImages/05.png',
//     set: '1',
//     link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/f3d77aa3e3574c399b5c13d70dc210f7/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   },
//   {
//     id: '20201018-2-tdb',
//     date: new Date('10-18-2020'),
//     publishedAt: new Date('10-18-2020'),
//     contentDetails: {
//       duration: 10000,
//     },
//     statistics: {
//       likeCount: 300,
//       dislikeCount: 100,
//     },
//     location: 'Yarmouth - MA',
//     bandName: 'The Disco Biscuits',
//     title: 'The Disco Biscuits - Yarmouth - MA',
//     description: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
//     accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
//     inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. \n
//     Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
//     quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque
//     porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
//     velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
//     aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
//     ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? \n
//     Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
//     molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?`,
//     photo: '/concertImages/06.png',
//     set: '2',
//     link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/3d81f67f007c474a96f362c3fee90088/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   },
//   // {
//   //   id: '20201019-1-tdb',
//   //   date: new Date('10-19-2020'),
//   //   location: 'Yarmouth - MA',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '1',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/c0cdfb224d514505ad26d5041706b15f/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201019-2-tdb',
//   //   date: new Date('10-19-2020'),
//   //   location: 'Yarmouth - MA',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '2',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/c7055d961fbf45c7b8c3c0eb1a117c98/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201023-1-tdb',
//   //   date: new Date('10-23-2020'),
//   //   location: 'Scranton, PA',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '1',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/48a8916ed65645c38352f9add2d9e708/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201023-2-tdb',
//   //   date: new Date('10-23-2020'),
//   //   location: 'Scranton, PA',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '2',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/c0cdfb224d514505ad26d5041706b15f/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201024-1-tdb',
//   //   date: new Date('10-24-2020'),
//   //   location: 'Scranton, PA',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '1',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/e8572109c05344eaae0fee718fce18a7/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201024-2-tdb',
//   //   date: new Date('10-24-2020'),
//   //   location: 'Scranton, PA',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '2',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/fe8b53592b384a8daacf518870ec84b7/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201025-1-tdb',
//   //   date: new Date('10-25-2020'),
//   //   location: 'Frederick, MD',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '1',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/3410854de57d428486fddcce9481a751/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201025-2-tdb',
//   //   date: new Date('10-25-2020'),
//   //   location: 'Frederick, MD',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '2',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/72c5693ad49d4ef5bc18cc902912aff5/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201029-1-tdb',
//   //   date: new Date('10-29-2020'),
//   //   location: 'LaFayette, NY',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '1',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/cabb1d7b25874e41abae2f9a00f266ce/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201029-2-tdb',
//   //   date: new Date('10-29-2020'),
//   //   location: 'LaFayette, NY',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '2',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/6d7b435704264e86bd286004e02558c8/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201030-1-tdb',
//   //   date: new Date('10-30-2020'),
//   //   location: 'LaFayette, NY',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '1',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/eba2fe11cd51407594ba449cd0d61fe6/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201030-2-tdb',
//   //   date: new Date('10-30-2020'),
//   //   location: 'LaFayette, NY',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '2',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/6d7b435704264e86bd286004e02558c8/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201031-1-tdb',
//   //   date: new Date('10-31-2020'),
//   //   location: 'LaFayette, NY',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '1',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/49e9ccd85dd9404a90655375eff09075/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // },
//   // {
//   //   id: '20201031-2-tdb',
//   //   date: new Date('10-31-2020'),
//   //   location: 'LaFayette, NY',
//   //   bandName: 'The Disco Biscuits',
//   //   photo: '02.png',
//   //   set: '2',
//   //   link: 'https://d0bca9b68a812ed71b07e4247e1836c1.egress.mediapackage-vod.us-east-1.amazonaws.com/out/v1/597f55b8bee44cadaa0c8f61682e3c2e/8ffa49d6e66f45c89f1bff09e08a908f/4bb3c45cd2df4693bbead2a9c6201e58/index.m3u8',
//   // }
// ]

export { concertsData };
