// import { createEmitAndSemanticDiagnosticsBuilderProgram } from "typescript"
import React, {  } from "react"
// import fakeImage from "../../images/landing.jpeg"
// import couchtourtv from "../../images/CouchTourLogoRed.png"
// import realBarCode from "../../images/barCode.svg"
// import { Card } from "react-bootstrap"
import { useHistory } from "react-router"
// import { useSocket } from "../../libs/SocketProvider"
// import { useAppContext } from "../../libs/contextLib"
// import { SocketProvider } from "../WebsocketChat/contexts/SocketProvider"

// import decibelLanding from "../../images/decibelLanding.jpeg"
import eventData from "../../hoc/hardCodeEvents"
//payload = {
// "action: "get-all-user-tickets",
// "message: {
//    all the typical user stuff
// }
// }

// action: “get-all-events”

// const handleYourTicketsButton = (history) => {
//   history.push("/your-tickets")
// }
const handleGetTicketsButton = (
  history,
  id,
  eventDetail,
  eventFeature,
  priceDescription,
  landingImageUrl,
  bandName,
  // promoter,
  ticketDate,
  eventName
) => {
  history.push({
    pathname: "/tickets",
    state: {
      eventId: id,
      eventDetail: eventDetail,
      eventFeature: eventFeature,
      priceDescription: priceDescription,
      landingImageUrl: landingImageUrl,
      bandName: bandName,
      ticketDate: ticketDate,
      eventName: eventName,
    },
  })
}

// useEffect(() => {
//   if(history.location.state.videoURLTemp)
//   return () => {
    
//   }
// }, [])

export const WhatsForSale = () => {
  const history = useHistory()

  // useEffect(() => {
  //   if (socketOpen) {
  //     socket.send(JSON.stringify({ action: "get-all-events" }))
  //     socket.send(JSON.stringify({ action: "get-all-tickets" }))
  //     socket.onmessage = async (message) => {
  //       const socketRespData = JSON.parse(message.data)
  //       const socketRespAction = socketRespData.action
  //       if (socketRespData.action === "get-all-events-resp-success") {
  //         // const events = JSON.parse(message.data.message.Items)
  //         // const events = socketRespData.message.items
  //         // console.log("[50] socketrespdata get all tickets", events)

  //         // setEvents(events)
  //         console.log("[53] socketrespdata get all events", socketRespData)
  //         const events = socketRespData.events
  //         const eventId = events.map((event) => {
  //           return event.eventId
  //         })
  //         console.log("[58]  events, event_id", events, eventId)
  //         setEvents(events)
  //         setEventId(eventId)
  //       } else if (socketRespData.action === "get-all-tickets-resp-success") {
  //         console.log("[55] get all tickets", socketRespData)
  //       } else {
  //         console.log("[55]err", socketRespData)
  //       }
  //     }
  //   }
  //   return () => {}
  // }, [socketOpen])

  return (
    <div className="for-sale">
      {/* <button onClick={tempOnClick}>CLOSE SOCKET</button> */}
      <div className="for-sale__top">
        <div className="for-sale__top__left">
          <div className="for-sale__top__left__upcoming-shows">
            Upcoming Shows
          </div>
          {/* <div className="for-sale__top__left__see-all">See all</div> */}
        </div>
        {/* <div className="for-sale__top__right">
          <button onClick={() => handleYourTicketsButton(history)}>
            Your Tickets
          </button>
        </div> */}
      </div>
      <div className="for-sale__bottom ">
        {eventData.map((card, id) => {
          let promoterText = card.promoter || process.env.REACT_APP_COMPANY_NAME
          // let promoterLogo = card.promoterLogo || couchtourtv
          return (
            <div key={id} className="for-sale__bottom__card">
              <div className="for-sale__bottom__card__left">
                <div className="for-sale__bottom__card__left__top">
                  <img alt="conert" src={card.image} />
                </div>
                <div
                  className={
                    card.isLive
                      ? "for-sale__bottom__card__left__bottom isLive"
                      : "for-sale__bottom__card__left__bottom notLive"
                  }
                >
                  {card.isLive ? (
                    <div className="for-sale__bottom__card__left__bottom__live-logo">
                      LIVE
                    </div>
                  ) : null}
                  <div className="for-sale__bottom__card__left__bottom__couchtour">
                    {/* <img src={promoterLogo} /> */}
                    <div className="for-sale__bottom__card__left__bottom__couchtour__text">
                      {promoterText} Presents
                    </div>
                  </div>
                  <div
                    className={
                      card.isLive
                        ? "for-sale__bottom__card__left__bottom__band band-live"
                        : "for-sale__bottom__card__left__bottom__band band-not-live"
                    }
                  >
                    {card.bandName}
                  </div>
                  <div
                    className={
                      card.isLive
                        ? "for-sale__bottom__card__left__bottom__live"
                        : "for-sale__bottom__card__left__bottom__date"
                    }
                  >
                    {card.isLive ? "LIVE NOW!" : `${card.timeOfEvent}`}
                  </div>
                  <div
                    className="for-sale__bottom__card__left__bottom__get-tickets"
                    onClick={() =>
                      handleGetTicketsButton(
                        history,
                        card.eventId,
                        card.eventDetail,
                        card.eventFeature,
                        card.priceDescription,
                        card.landingImageUrl,
                        card.bandName,
                        card.timeOfEvent,
                        card.eventFeature.description
                      )
                    }
                    // onClick={() => handleGetTicketsButton(history, card.ID)}
                  >
                    GET TICKETS
                    {/* <img src={realBarCode} /> */}
                  </div>
                </div>
              </div>
              <div className="for-sale__bottom__card__right"></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

// const eventData = [
//   {
//     ID: "24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     bandName: "200th Issue Show",
//     image: decibelPoster,
//     promoter: "Decibel Magazine",
//     promoterLogo: null,
//     timeOfEvent: "FRI/8:00 PM",
//     isLive: false,
//     landingImageUrl: "/dB200thIssueShow_logoRGB.png",
//     eventFeature: {
//       description: "200th Issue Show Extremely Ex-Stream",
//       type: "coffee",
//     },
//     priceDescription: {
//       description: "Tickets start at $6.66",
//       type: "money",
//     },
//     eventDetail: {
//       description: "Watch ",
//       type: "flag",
//     },
//   },
// ]

// const ticketData = [
//   {
//     ID: "f42298f7-bda8-4806-88ca-6dd1dc2ab985",
//     ticketId: "tic_f42298f7-bda8-4806-88ca-6dd1dc2ab985",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     title: "Decibel 200th: Extremely Ex-Stream",
//     price: "2424",
//     image: "https://temp-image-tickets.s3.amazonaws.com/musical-heart.jpg",
//     boxedItems: ["GA", "Free DVD", "T-SHIRT", "POSTER"],
//     attributes: [
//       "General Admission to Decibel 200th: Extremely Ex-Stream",
//       "Bonus DVD! The Decibel 200th Issue Show Extremely Ex-Stream, presented by Metal Blade Records.",
//       "Signed One-Time Offering Poster From The Label",
//       "Exclusive limited edition event T-shirt ",
//       "Available to ship within the U.S. and Canada only.",
//     ],
//     soldOut: false,
//   },
//   {
//     ID: "0b109cac-dba1-4ab3-a15f-f1ec64e580ad",
//     ticketId: "tic_0b109cac-dba1-4ab3-a15f-f1ec64e580ad",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     title: "Decibel 200th: Extremely Ex-Stream",
//     price: "1818",
//     image: "https://temp-image-tickets.s3.amazonaws.com/musical-heart.jpg",
//     boxedItems: ["GA", "Free DVD", "T-SHIRT"],
//     attributes: [
//       "General Admission to Decibel 200th: Extremely Ex-Stream",
//       "Bonus DVD! The Decibel 200th Issue Show Extremely Ex-Stream, presented by Metal Blade Records.",
//       "Exclusive limited edition event T-shirt ",
//       "Available to ship within the U.S. and Canada only.",
//     ],
//     soldOut: false,
//   },
//   {
//     ID: "cfb84032-6618-447e-b9f5-665f1122f8f1",
//     ticketId: "tic_cfb84032-6618-447e-b9f5-665f1122f8f1",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     title: "Decibel 200th: Extremely Ex-Stream",
//     price: "1212",
//     image: "https://temp-image-tickets.s3.amazonaws.com/musical-heart.jpg",
//     boxedItems: ["GA", "Free DVD"],
//     attributes: [
//       "General Admission to Decibel 200th: Extremely Ex-Stream",
//       "Bonus DVD! The Decibel 200th Issue Show Extremely Ex-Stream, presented by Metal Blade Records.",
//       "Available to ship within the U.S. and Canada only.",
//     ],
//     soldOut: false,
//   },
//   {
//     ID: "c73947fc-2a00-4518-ae35-090dad1fe9a4",
//     ticketId: "tic_c73947fc-2a00-4518-ae35-090dad1fe9a4",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     title: "Decibel 200th: Extremely Ex-Stream",
//     price: "666",
//     image: "https://temp-image-tickets.s3.amazonaws.com/musical-heart.jpg",
//     boxedItems: ["GA"],
//     attributes: [
//       "General Admission to Decibel 200th: Extremely Ex-Stream",
//       "Bonus DVD! The Decibel 200th Issue Show Extremely Ex-Stream, presented by Metal Blade Records.",
//       "Available to ship within the U.S. and Canada only.",
//     ],
//     soldOut: false,
//   },
// ]

// const cardData = [
//   {
//     "ID": "24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     "bandName": "Decibel Magazine",
//     "promoter": "Decibel Magazine",
//     "promoterLogo": null,
//     "timeOfEvent": "FRI/8:00 PM",
//     "isLive": true,
//     "eventId": "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     "landingImageUrl": "/dB200thIssueShow_logoRGB.png",
//     "eventFeature": {
//       "description": "200th Issue Show Extremely Ex-Stream",
//       "type": "coffee"
//     },
//     "priceDescription": {
//       "description": "Tickets start at $6.66",
//       "type": "money"
//     },
//     "eventDetail": {
//       "description": "Watch ",
//       "type": "flag"

//     }
//   }
// ]

// const cardData = [
//   {
//     Count: 1,
//     ScannedCount: 1,

//     Items: [
//       {
//         ID: "23987wkjhkjhlkhf",

//       {
//         image: fakeImage,
//         bandName: "THE DISCO BISCUITS",
//         isLive: true,
//         time: "WED/8:00 PM",
//       },
//       {
//         image: fakeImage,
//         bandName: "HOOTIE AND THE BLOWFISH",
//         isLive: false,
//         time: "WED/8:00 PM",
//       },
//       {
//         image: fakeImage,
//         bandName: "JUKEBOX THE GHOST",
//         isLive: false,
//         time: "WED/8:00 PM",
//       },
//     }
//     ]
//   }
// ]

// ID: "24f60b47-f814-4ece-8d98-c52ec2ed66be"
// eventDetail:
// description: "New 60+ minute show from Ardmore Music Hall"
// type: "flag"
// __proto__: Object
// eventFeature:
// description: "The Disco Biscuits Present 'In Space when I'm With You'"
// type: "coffee"
// __proto__: Object
// eventId: "evt_24f60b47-f814-4ece-8d98-c52ec2ed66be"
// landingImageUrl: "https://temp-image-tickets.s3.amazonaws.com/event-landing-biscuits.jpeg"
// priceDescription:
// description: "Tickets start at $15"
// type: "money"
