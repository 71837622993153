import React, { useState, useEffect } from "react"
import { Auth } from "aws-amplify"
import { logRocketUserConnect } from "../libs/errorLib"
import { useLocation } from "react-router-dom"
import { Link, useHistory } from "react-router-dom"
import { Image, FormGroup, FormControl, FormLabel } from "react-bootstrap"
import LoaderButton from "../components/LoaderButton"
import { useAppContext } from "../libs/contextLib"
import { useFormFields } from "../libs/hooksLib"
import { onError } from "../libs/errorLib"
import { routeException } from "../libs/routeException"
import "./Login.css"

export default function Logout() {
  const { userHasAuthenticated } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  const [newUser, setNewUser] = useState(null)
  const history = useHistory()
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  })

  const location = useLocation()

  useEffect(() => {
    // console.log("[29] Login.js ---- USE_EFFECT - HOOK"); // result: '/secondpage'
    // console.log(location.pathname); // result: '/secondpage'
    // console.log(location.search); // result: '?query=abc'
    // console.log(location.username); // result: 'some_value'
  }, [location])

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0
  }

  async function handleSubmit(event) {
    event.preventDefault()

    setIsLoading(true)

    try {
      const res = await Auth.signIn(fields.email.toLowerCase(), fields.password)
      setNewUser({ username: fields.email.toLowerCase() })
      // console.log(res, "here is the response");
      // console.log(res.username, res.email, res.pool.clientId, res.pool.userPoolId);
      // const atok = Auth.currentSession().accessToken;
      // console.log("NEW USER ATOK",atok );
      logRocketUserConnect(res)
      userHasAuthenticated(true)
    } catch (e) {
      setIsLoading(false)
      // console.log(e.code,'HERE IS THE ERROR 50');
      onError(e)
      const routeExceptions = routeException(e)
      const route = routeExceptions.route
      if (route === "/confirm-account") {
        setNewUser({
          username: fields.email.toLowerCase(),
          password: fields.password,
        })
        // console.log("CURRENT USER:", newUser, fields.email);
      }
      console.log("HERE IS ROUTE RESPONSE", route, {
        username: fields.email,
        newUser,
      })
      if (route === "/login" || e.code === "NotAuthorizedException") {
        // do nothing stay on page
      } else if (route !== null) {
        history.push({
          pathname: route,
          customNameData: fields.email,
          customFormData: fields.password,
        })
      }
    }
  }

  return (
    <div className="Login">
      <form style={{ width: "50%" }} onSubmit={handleSubmit}>
        <FormGroup controlId="email" bssize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bssize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <Link to="/login/reset">Forgot password?</Link>
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
        <Image
          className="icon-center iconsquare"
          src="CouchTourLogoRed2_300dpi-icon-flip.png"
          fluid
        />
      </form>
    </div>
  )
}
