import React, { useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import { useSocket } from "../../libs/SocketProvider";
// import { Redirect } from "react-router-dom";

export const SixDigitVerification = () => {
  const [value, setValue] = useState("");
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [changingKey, setChangingKey] = useState(0);
  const [codePaired, setCodePaired] = useState(false);
  const [codeDenied, setCodeDenied] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const socket = useSocket();

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  //TODO capitalize wigth javascript the input
  const onSend = () => {
    console.log("[15] six digit ver... COMPLETE");
    const payload = { action: "add-device-id", message: { code: value } };
    socket.send(JSON.stringify(payload));

    socket.onmessage(JSON.stringify(payload));

    // if (socket.onmessage === "success") {
    //   setCodePaired(true)
    // setTimeout(() => {
    //   //todo send to content page   return <Redirect to='/<content-page>'  />
    //todo set state to authorized on success
    // }, 3000);
    // } else {
    //   setCodeDenied(true)
    // }
    setShowSubmitButton(false);
    setChangingKey(changingKey + 1);
  };
  const onCompleted = (vals) => {
    setValue(vals.toUpperCase());
  };
  const handleDigitChange = (vals) => {
    const invalidEntry = (num) => {
      var regex = /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._\d]/g;
      return regex.test(num);
    };

    if (invalidEntry(vals) === true || vals.length < 6) {
      setShowSubmitButton(false);
    } else if (invalidEntry(vals) === false && vals.length >= 6) {
      setShowSubmitButton(true);
    }
    if (invalidEntry(vals) === true) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  return (
    <div className="six-digit-verification">
      <div className="pairing">
        <div className="pairing__text">
          Please enter the 6 digit code displayed on your television and press
          the appearing 'Verify' button when finished:
        </div>
        <ReactCodeInput
          autoComplete="off"
          id="pairing__input"
          className={
            !errorMessage ? "pairing__react-code-input" : "pairing__input-error"
          }
          fieldWidth={width >= 378 ? 60 : 52}
          fields={6}
          autoFocus={true}
          type="text"
          onChange={(vals) => handleDigitChange(vals)}
          onComplete={(val) => onCompleted(val)}
          key={changingKey}
        />
        {errorMessage ? (
          <div className="pairing__error-message">
            Capital letters only please. No numbers or special characters.
          </div>
        ) : null}
        {codePaired ? (
          <div className="pairing__success-message">
            SUCCESS! ENJOY THE SHOW!!
          </div>
        ) : null}
        {codeDenied ? (
          <div className="pairing__error-message">
            Invalid key. Please re-enter the correct 6 digit key and press the
            'Verify' button.
          </div>
        ) : null}

        {/* //! TESTING BUTTONS, UNCOMMENT below code TO TEST SUCCESS AND FAIL PAGES */}

        <button
        style={{zIndex:"4"}}
        type="submit"
        onClick={() => setCodePaired(!codePaired)}
      >
        Success
      </button>
      <button
      style={{zIndex:"4"}}
        type="submit"
        onClick={() => setCodeDenied(!codeDenied)}
      >
        Fail
      </button>

        {showSubmitButton ? (
          <div className="pairing-button-body">
            <div className="pairing-button">
              <a href="/#" >
                <button
                  type="submit"
                  onClick={() => onSend()}
                  className="pairing-button__button"
                >
                  <span>Verify</span>
                </button>
                {/* <div className="pairing-button__sun"></div> */}
              </a>
            </div>
          </div>
        ) : null}
      </div>

      <div className="six-digit-verification__bottom"></div>
    </div>
  );
};
