import * as Sentry from "@sentry/browser"
import LogRocket from "logrocket"

const isLocal = process.env.REACT_APP_ENVIRONMENT === "development"
// const isLocal = false

export function logRocketUserConnect(res_from_aws_amplify) {
  const userId = res_from_aws_amplify.attributes.sub
  const email = res_from_aws_amplify.attributes.email
  const verified = res_from_aws_amplify.attributes.email_verified
  const clientId = res_from_aws_amplify.pool.clientId
  const userPoolId = res_from_aws_amplify.pool.userPoolId

  LogRocket.identify(userId, {
    userId: userId,
    email: email,

    // Add your own custom user variables here, ie:
    email_verified: verified,
    clientId: clientId,
    userPoolId: userPoolId,
  })
}

export function initSentry() {
  if (isLocal) {
    return
  }

  LogRocket.init("rpgqga/couchtourtv")
  // console.log("\n\n  " + process.env.REACT_APP_SENTRY_DSN + "\n\n")
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL)
    })
  })
}

export function logError(error, errorInfo = null) {
  if (isLocal) {
    return
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo)
    Sentry.captureException(error)
  })
}

// AWS-amplify Auth errors
// {code: “UsernameExistsException”,
// 	name: “UsernameExistsException”,
// 	message: “An account with the given email already exists.“}

// SLS API errors
export function onError(error) {
  let errorInfo = {}
  let message = error.toString()

  console.log("\n[34] errorLib\n", error)

  if (!(error instanceof Error) && error.message) {
    // AWS-amplify Auth errors
    errorInfo = error
    message = error.message
    error = new Error(message)
  } else if (error.config && error.config.url) {
    // SLS API errors
    errorInfo.url = error.config.url
  }

  logError(error, errorInfo)
  console.log("\n[76] errorLib PRE-ALERT\n", error)
  // alert(message)
}
