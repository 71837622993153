import React, { useState, useEffect } from "react"
import { Auth } from "aws-amplify"
import { FormGroup, FormControl, FormLabel } from "react-bootstrap"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
// import { loadStripe } from "@stripe/stripe-js"
import LoaderButton from "../components/LoaderButton"
import { useFormFields } from "../libs/hooksLib"
import { useSocket } from "../libs/SocketProvider"
import { useAppContext } from "../libs/contextLib"
// import Card from "./Card"
// import {
//   createTodoActionCreator,
//   createCardActionCreator,
// } from "../redux-toolkit"
import { ReactComponent as CreditCardCheck } from "../images/creditCardCheck.svg"
import { ReactComponent as CreditCardPlus } from "../images/creditCardPlus.svg"
import { ErrorMessageBox } from "../components/ErrorMessageBox"

// const stripeKey = process.env.REACT_APP_STRIPE_KEY
// const stripePromise = loadStripe(stripeKey)

function AddCardToStripe({ isLoading, onSubmit, ...props }) {
  const { userId, setPaymentMethodGlobal, stripeCustomerId } = useAppContext()

  // const dispatch = useDispatch()
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    storage: "",
  })
  const [isProcessing, setIsProcessing] = useState(false)
  const [isCardComplete, setIsCardComplete] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMsg, setSuccessMsg] = useState(false)
  const [localCogId, setLocalCogId] = useState("")
  // const [disableForm, setDisableForm] = useState(false)
  // const [cardElementLocal, setCardElementLocal] = useState("")
  const [userEmail, setUserEmail] = useState("")

  const stripe = useStripe()
  const elements = useElements()
  const socket = useSocket()

  const { onClose, isCardUpdate, setIsCardUpdate, cardId } = props

  const handleCogId = async () => {
    try {
      const credentials = await Auth.currentUserCredentials()
      const cogIdTemp = credentials.identityId
      setLocalCogId(cogIdTemp)

      Auth.currentSession().then((data) => {
        const email = data.idToken.payload.email
        setUserEmail(email)
      })
    } catch (error) {
      console.log("[69] handleCogID error", JSON.stringify(error))
    }
  }

  useEffect(() => {
    handleCogId()
    return () => {}
  })

  isLoading = isProcessing || isLoading

  function validateForm() {
    return (
      fields.name !== "" &&
      // fields.storage !== "" &&
      isCardComplete === true
    )
  }
  //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE SUBMIT~~~~~~~~~~~~~~~
  async function handleSubmitClick(event) {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    setIsProcessing(true)
    const cardElement = elements.getElement(CardElement)
    // setCardElementLocal(cardElement)

    const createToken = await stripe.createToken(cardElement)

    if (createToken.error) {
      setErrorMessage(JSON.stringify(createToken.error))
      console.log(" [79] createToken.error", JSON.stringify(createToken.error))
    } else {
      console.log(" [81] createToken.token", JSON.stringify(createToken.token))
    }

    const createPayment = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    })
    const paymentMethod = createPayment.paymentMethod
    // const last4 = createPayment.paymentMethod.card.last4
    // const expMonth = paymentMethod.card.exp_month
    // const expYear = paymentMethod.card.exp_year

    setPaymentMethodGlobal(paymentMethod)
    console.log("[103] createPayment", JSON.stringify(createPayment))
    console.log("[104] paymentMethod", JSON.stringify(paymentMethod))

    if (createPayment.error) {
      setErrorMessage(JSON.stringify(createPayment.error))
      //todo display error message
      console.log(
        "[78] PAYMENT METHOD ERROR",
        JSON.stringify(createPayment.error)
      )
    } else {
      //?~~~~~~  isCardUpdate is boolean that determines whether or  ~~~~~
      //?~~~~~~  not user is trying to update card or add new card  ~~~~~
      if (isCardUpdate === false) {
        const addCardPayload = JSON.stringify({
          action: "wallet-add-user-credit-card",
          message: {
            stripeCardPayment: paymentMethod,
            paymentMethodId: paymentMethod.id,
            stripeCustomerId: stripeCustomerId,
            userId: userId,
            email: userEmail,
            cogId: localCogId,
          },
        })
        socket.send(addCardPayload)
      } else if (isCardUpdate === true) {
        // const updatePayload = JSON.stringify({
        //   action: "wallet-update-user-credit-card",
        //   message: {
        //     stripeCardPayment: paymentMethod,
        //     userId: userId,
        //     //todo check cardId
        //     cardId: cardId,
        //     email: userEmail,
        //     cogId: localCogId,
        //     lastFour: last4,
        //     expMonth: expMonth,
        //     expYear: expYear,
        //   },
        // })
        const deleteCardPayload = JSON.stringify({
          action: "wallet-delete-user-credit-card",
          message: {
            userId: userId,
            cogId: localCogId,
            email: userEmail,
            stripeCardPayment: paymentMethod,
            cardId: cardId
          },
        })
        socket.send(deleteCardPayload)
        const addCardPayload = JSON.stringify({
          action: "wallet-add-user-credit-card",
          message: {
            stripeCardPayment: paymentMethod,
            paymentMethodId: paymentMethod.id,
            stripeCustomerId: stripeCustomerId,
            userId: userId,
            email: userEmail,
            cogId: localCogId,
          },
        })
        socket.send(addCardPayload)

        // socket.send(updatePayload)
        setIsCardUpdate(false)
      }
      setSuccessMsg(true)
      setTimeout(() => {
        onClose()
      }, 3000)
    }
  }

  const handleChange = (event) => setIsCardComplete(event.complete)

  const SuccessMessage = () => {
    return (
      <div className="credit-card-success">
        {" "}
        {isCardUpdate ? (
          <div>Card successfully updated</div>
        ) : (
          <div>Card successfully created</div>
        )}
        <CreditCardCheck />
      </div>
    )
  }

  return (
    <div className="CardInput">
      {successMsg ? (
        <SuccessMessage />
      ) : (
        <form className="AddCardToStripe" onSubmit={handleSubmitClick}>
          {errorMessage && <ErrorMessageBox>{errorMessage}</ErrorMessageBox>}
          <FormGroup bssize="large" controlId="name">
            <FormLabel>Cardholder's name</FormLabel>
            <FormControl
              type="text"
              value={fields.name}
              onChange={handleFieldChange}
              placeholder="Name on the card"
            />
          </FormGroup>
          <FormLabel>Credit Card Info</FormLabel>
          <CardElement
            id="billing-form-card"
            className="card-field"
            onChange={handleChange}
            options={{
              iconStyle: "solid",
              style: {
                base: {
                  iconColor: "#c4a0aa",
                  color: "#222",
                  fontSize: "16px",
                },
                invalid: {
                  iconColor: "#AAC7EE",
                  color: "#AAC7EE",
                },
              },
            }}
          />
          <LoaderButton
            block
            type="submit"
            bssize="large"
            isLoading={isProcessing}
            disabled={!validateForm()}
            // disabled={!validateForm() || disableForm}
            className="AddCardToStripe__button"
          >
            {isCardUpdate ? <div>Update Card</div> : <div>Add Card</div>}
            <CreditCardPlus />
          </LoaderButton>
        </form>
      )}
    </div>
  )
}

export default AddCardToStripe
