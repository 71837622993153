import React, { useState, useEffect } from "react"
import { useHistory } from "react-router"

import { ReactComponent as Checkmark } from "../../images/checkDecagramIcon.svg"
import { useAppContext } from "../../libs/contextLib"
import { useSocket } from "../../libs/SocketProvider"

const SubscriptionCard = ({
  title,
  cost,
  listName,
  priceId,
}) => {
  // const [attributeValues, setAttributeValues] = useState([])

  const history = useHistory()
  // const socket = useSocket()

  // const attributeValuesTemp = Object.values(listName)
  const attributeKeysTemp = Object.keys(listName)
  useEffect(() => {
    // setAttributeValues(attributeValuesTemp)
    return () => {}
  }, [])
  const filteredKeys = () => {
    let attKeys = []
    attributeKeysTemp.map((item) => {
      if (isNaN(item)) {
        return null
      } else {
        attKeys = attKeys.concat(item)
      }
      return attKeys
    })
    return attKeys
  }
  const attributeKeys = filteredKeys()

  const listAttributes = () => {
    let attributes = []
    attributeKeys.map((num) => {
      attributes = attributes.concat(listName[num])
      return attributes
    })
    return attributes
  }
  const attributes = listAttributes()

  const handleOnClick = (title, cost, listName, priceId) => {
    history.push({
      pathname: "/checkout",
      state: {
        subscription: {
          title: title,
          cost: cost,
          features: listName,
          priceId: priceId,
        },
      },
    })
  }
  return (
    <div className="Subscriptions__column">
      <div className="Subscriptions__column__top">
        <div className="Subscriptions__column__top__title">{title}</div>
        <div className="Subscriptions__column__top__price">${cost / 100}</div>
      </div>
      <div className="Subscriptions__column__middle">
        <ul>
          {attributes &&
            attributes.map((item, index) => {
              if (isNaN(item)) {
                return (
                  <li key={index + 100}>
                    <Checkmark />
                    <div className="Subscriptions__list-item">{item}</div>
                  </li>
                )
              } else return
            })}
        </ul>
      </div>
      <div className="Subscriptions__column__bottom">
        <button onClick={() => handleOnClick(title, cost, attributes, priceId)}>
          Get Started
        </button>
      </div>
    </div>
  )
}

export default function Subscriptions() {
  const { socketOpen } = useAppContext()
  const socket = useSocket()
  const [stripeSubscriptionData, setStripeSubscriptionData] = useState([])
  useEffect(() => {
    if (socketOpen === true) {
      const getProductSubscriptionsPayload = JSON.stringify({
        action: "get-product-subscriptions",
      })
      socket.send(getProductSubscriptionsPayload)
      socket.onmessage = async (message) => {
        const socketRespData = JSON.parse(message.data)
        const socketRespAction = socketRespData.action
        if (socketRespAction === "get-product-subscriptions-success-resp") {
          setStripeSubscriptionData(socketRespData.message.data)
        } else if (
          socketRespAction === "get-product-subscriptions-error-resp"
        ) {
          // setErrorMessage("Trouble loading subscriptions")
        } else {
        }
      }
    }

    return () => {}
  }, [socketOpen])

  return (
    <div className="Subscriptions">
      {stripeSubscriptionData.map((item, id) => {
        if (item.active === true) {
          return (
            <SubscriptionCard
            key={id}
              title={item.name}
              cost={item.metadata.price}
              listName={item.metadata}
              stripeSubscriptionData={stripeSubscriptionData}
              priceId={item.id}
            />
          )
        } else return
      })}

    </div>
  )
}
