const stripeCheckoutConfigVarsDev = {
	mode: "payment",
	successUrl: "http://localhost:3003/live",
	cancelUrl: "http://localhost:3003/cancel"
}

const stripeCheckoutConfigVarsProd = {
	mode: "payment",
	successUrl: "https://video.decibelmagazine.com/live",
	cancelUrl: "https://video.decibelmagazine.com/cancel"
}

const stripeCheckoutConfigVars = process.env.REACT_APP_ENVIRONMENT === 'production' ? stripeCheckoutConfigVarsProd : stripeCheckoutConfigVarsDev

export default stripeCheckoutConfigVars;