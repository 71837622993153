export function routeException(error) {
  const errorMessages = {
    UserNotConfirmedException: {
      code: "UserNotConfirmedException",
      name: "UserNotConfirmedException",
      message: "User is not confirmed.",
      route: "/login",
      // route: "/confirm-account"
    },
    UsernameExistsException: {
      code: "UsernameExistsException",
      name: "UsernameExistsException",
      message: "An account with the given email already exists.",
      route: "/login",
    },
    // UserNotFoundException: {
    //   code: "UserNotFoundException",
    //   name: "UserNotFoundException",
    //   message: "Incorrect username or password.",
    //   route: "/login",
    // },
    UserNotFoundException: {
      code: "NotAuthorizedException",
      name: "NotAuthorizedException",
      message: "Incorrect username or password.",
      route: "/login",
    },
    InvalidParameterException: {
      code: "InvalidParameterException",
      name: "InvalidParameterException",
      message:
        "Custom auth lambda trigger is not configured for the user pool.",
    },
    NotAuthorizedException: {
      code: "NotAuthorizedException",
      name: "NotAuthorizedException",
      message: "Incorrect username or password.",
      route: "/login",
    },
    CodeMismatchException: {
      code: "CodeMismatchException",
      name: "CodeMismatchException",
      message: "Invalid verification code provided, please try again.",
      route: "/login",
    },
  }

  let errorValue = errorMessages[error.code]

  if (error.code === "NotAuthorizedException") {
    if (error.message && error.message.match("status is CONFIRMED")) {
      errorValue = {
        code: "NotAuthorizedException",
        name: "NotAuthorizedException",
        message: "User is confirmed. Current status is CONFIRMED",
        route: "/",
      }
    }
  }

  if (!errorValue || errorValue === null || !errorValue.route) {
    /* Missing ERROR exception Message */

    errorValue = {
      code: "MissingException",
      name: "UserNotConfMissingExceptionirmedException",
      message: "Error. Please refresh",
      route: "/",
    }
  }

  // console.log("[55] routeException.js", errorValue);

  return errorValue
  // return errorValue.route
}
