import React, { useState } from "react"
// import { useDispatch } from "react-redux"
import { FormGroup, FormControl, FormLabel } from "react-bootstrap"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import LoaderButton from "./LoaderButton"
import { useFormFields } from "../libs/hooksLib"
import { useAppContext } from "../libs/contextLib"
import "./BillingForm.css"
// import "../payments/Payment.css";

import { useSocket } from "../libs/SocketProvider"

// import {
//   createTodoActionCreator,
//   createCardActionCreator,
// } from "../redux-toolkit"
// import { ChoosePaymentMethod } from "./ChoosePaymentMethod"

//! TEST CREDIT CARD:  4242 4242 4242 4242 02/22 222
//                     Jane | jane@example.com | 185 berry st | San Francisco | California | 94103

function BillingForm({ isLoading, onSubmit, movieData, ...props }) {
  // const movie = movieData.movies[0];
  const {
    userId,
    cogId,
    currentUserEmail,
    clientSecretGlobal,

    // purhcaseSuccess, //todo use purchaseSuccess === "SUCCESS" to display purchase success screen,
    //todo purchaseSuccess === "FAILURE" for error screen
  } = useAppContext()

  // const dispatch = useDispatch()
  const socket = useSocket()
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    // storage: movie.price
  })
  const [isProcessing, setIsProcessing] = useState(false)
  const [isCardComplete, setIsCardComplete] = useState(false)
  // const [clientSecret, setClientSecret] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [showCreditCards, setShowCreditCards] = useState(false)


  const stripe = useStripe()
  const elements = useElements()

  isLoading = isProcessing || isLoading

  function validateForm() {
    return (
      fields.name !== "" &&
      fields.email !== "" &&
      fields.address !== "" &&
      fields.city !== "" &&
      fields.state !== "" &&
      // fields.zipcode !== "" &&
      // fields.storage !== "" &&
      isCardComplete === true
    )
  }

  // const billingDetails = {
  //   name: fields.name,
  //   email: fields.email,
  //   addres: {
  //     city: fields.city,
  //     line1: fields.address,
  //     state: fields.state,
  //     postal_code: fields.zipcode,
  //   },
  // }

  async function handleSubmitClick(event) {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    setIsProcessing(true)

    //payment intent
    // const paymentIntentPayload = JSON.stringify({
    //   action: "create-payment-intent",
    //   message: {
    //     amount: price * 100,
    //   },
    // })
    // socket.send(paymentIntentPayload)

    //this payment intent should result in the sls-api creating a payment intent and
    //return a client_secret of that payment intent
    // sls-api should look something like:
    // const paymentIntent = await stripe.paymentIntents.create({amount, currency: "usd"})

    //three pages
    //1. add card, show list of cards (do what amazon does)- listcards page, addcards page screenshot amazon
    // addcard: card comes form user, get tokenized from stripe, token goes to server. server sends success back to user
    // listcards: client goes to server and request cards for user, showing last 4, server sends cards (look at amazon) - no stripe involved
    //2. add payment method, choosing card
    //listing cards plus choosing one,
    //no stripe involved

    //3. these two working together when you dont have  a card, when no payment method avaialable,
    //dont want to use card avaialble , what's payment method? goto add card (screenshot amazon screens,
    // look at bumble, venmo,  snapchat, tech savy company, tesla, see how they do it)
    //

    //4. buy something: sending payment method id with cart to server

    const cardElement = elements.getElement(CardElement)

    const createToken = await stripe.createToken(cardElement)
    // const { token, error } = await stripe.createToken(cardElement)

    if (createToken.error) {
      setErrorMessage(JSON.stringify(createToken.error))
      console.log(" [127][createToken.error]", createToken.error)
    } else {
      console.log(" [129] [createToken.token", createToken.token)
    }

    const createPayment = await stripe.createPaymentMethod({
      // const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      // billing_details: billingDetails,
    })

    const paymentMethod = createPayment.paymentMethod

    if (createPayment.error) {
      setErrorMessage(JSON.stringify(createPayment.error))
      console.log(" [144][createPayment.error]", createPayment.error)
      // } else if (
      //   paymentMethod.card.checks.address_line1_check !== "pass" ||
      //   paymentMethod.card.checks.address_postal_code_check !== "pass" ||
      //   paymentMethod.card.checks.address_postal_code_check !== "pass"
      // ) {
      //   //todo display error message
      //   setErrorMessage("Payment method error.")
      //   //reset field
      //   handleFieldChange({
      //     name: "",
      //     email: "",
      //     address: "",
      //     city: "",
      //     state: "",
      //     zipcode: "",
      //   })
      console.log("[126] PAYMENT METHOD ERROR")
    } else {
      console.log(" [133] [PaymentMethod]", paymentMethod)
      const payload = JSON.stringify({
        action: "purchase-request",
        message: {
          paymentMethodObject: paymentMethod,
          userId: userId,
          email: currentUserEmail,
          cogId: cogId,
          token: createToken,
          items: [{ item_id: "4242429er", price_cents: 100 }],
        },
      })
      socket.send(payload)
    }

    const confirmCardPayment = await stripe.confirmCardPayment(clientSecretGlobal, { //!client secret comes from original payment intent object
      payment_method: paymentMethod,
    })
    console.log("[112] confirmCardPayment", confirmCardPayment)
    // confirmCardPayment returns a payment intent object which contains many values including
    // an id and status. (if confirmCardPayment.status === "succeeded") {some action}

    // const { token, error } = await stripe.createToken(cardElement)

    setIsProcessing(false)
    // dispatch(
    //   createCardActionCreator({ stripe: { token, error }, name: fields.name })
    // )
    // dispatch(
    //   createTodoActionCreator({ desc: fields.name + " uploaded a card" })
    // )

    // onSubmit(fields.storage, { token, error })
  }

  const handleChange = (event) => setIsCardComplete(event.complete)

  return (
    <div>
      {errorMessage ? <div>{errorMessage}</div> : null}
      {/* //todo display error message */}
      <div className="bg-billing">
        <div className="billing-header">
          {/* <h2>{movie.title}</h2> */}
          {/* <h3>{movie.air_date}</h3> */}
          <h2>Title Placeholder</h2>
          <h3>Date Placeholder</h3>
        </div>
        <div className="products">
          <h4>Checkout</h4>
          <hr />
          <div className="item">
            <span className="price">PRICE placeholer</span>
            {/* <span className="price">{movie.price_display}</span> */}
            <p className="item-name">Ticket</p>
            <p className="item-description"></p>
          </div>
          <div className="item">
            <span className="price">$0</span>
            <p className="item-name">Platform Fee</p>
            <p className="item-description"></p>
          </div>
          <hr />
          <div className="item">
            <span className="price">$12</span>
            <p className="item-name-total">Total</p>
          </div>
        </div>
        {!showCreditCards ? (
          <form
            className="BillingForm"
            onSubmit={handleSubmitClick}
            style={{ marginTop: 40 }}
          >
            <FormGroup bssize="large" controlId="name">
              <FormLabel>Cardholder&apos;s name</FormLabel>
              <FormControl
                type="text"
                value={fields.name}
                onChange={handleFieldChange}
                placeholder="Name on the card"
              />
            </FormGroup>
            <FormGroup bssize="large" controlId="email">
              <FormLabel>Cardholder&apos;s Email</FormLabel>
              <FormControl
                type="email"
                value={fields.email}
                onChange={handleFieldChange}
                placeholder="Email"
              />
            </FormGroup>
            <FormGroup bssize="large" controlId="address">
              <FormLabel>Cardholder&apos;s Address</FormLabel>
              <FormControl
                type="text"
                value={fields.address}
                onChange={handleFieldChange}
                placeholder="Address"
              />
            </FormGroup>
            <FormGroup bssize="large" controlId="city">
              <FormLabel>Cardholder&apos;s City</FormLabel>
              <FormControl
                type="text"
                value={fields.city}
                onChange={handleFieldChange}
                placeholder="City"
              />
            </FormGroup>
            <FormGroup bssize="large" controlId="state">
              <FormLabel>Cardholder&apos;s State</FormLabel>
              <FormControl
                type="text"
                value={fields.state}
                onChange={handleFieldChange}
                placeholder="State"
              />
            </FormGroup>
            {/* <FormGroup bssize="large" controlId="zipcode">
              <FormLabel>Cardholder&apos;s ZIP Code</FormLabel>
              <FormControl
                type="text"
                value={fields.zipcode}
                onChange={handleFieldChange}
                placeholder="ZIP Code"
              />
            </FormGroup> */}
            {/* <FormLabel>Credit Card </FormLabel> */}
            {/* <br /> */}
            {/* <div className="card-number-label"> */}
            <FormGroup>
              <FormLabel>
                Credit Card Number - Expiration - CVC - Billing Zipcode
              </FormLabel>
            <CardElement
              id="billing-form-card"
              className="card-field"
              onChange={handleChange}
              options={{
                iconStyle: "solid",
                style: {
                  base: {
                    iconColor: "#c4a0aa",
                    color: "#222",
                    fontSize: "16px",
                  },
                  invalid: {
                    iconColor: "#AAC7EE",
                    color: "#AAC7EE",
                  },
                },
                // hidePostalCode: true,
              }}
              />
              </FormGroup>
            {/* </div> */}
            <button
              type="button"
              onClick={() => setShowCreditCards(!showCreditCards)}
            >
              Choose payment method
            </button>

            <LoaderButton
              block
              type="submit"
              bssize="large"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Purchase
            </LoaderButton>
          </form>
        ) : null}

        {/* {showCreditCards ? (
          <ChoosePaymentMethod
            setShowCreditCards={setShowCreditCards}
            showCreditCards={showCreditCards}
            isLoading={isLoading}
          />
        ) : null} */}
      </div>
    </div>
  )
}

export default BillingForm

//token object
// {
//   "id": "tok_1IW4SFKsNNk3qPPUXO0Xt4KL",
//   "object": "token",
//   "card": {
//     "id": "card_1IW4SFKsNNk3qPPUdbjU0oJK",
//     "object": "card",
//     "address_city": null,
//     "address_country": null,
//     "address_line1": null,
//     "address_line1_check": null,
//     "address_line2": null,
//     "address_state": null,
//     "address_zip": null,
//     "address_zip_check": null,
//     "brand": "Visa",
//     "country": "US",
//     "cvc_check": "pass",
//     "dynamic_last4": null,
//     "exp_month": 8,
//     "exp_year": 2022,
//     "fingerprint": "Rm2rU0VU0UjVgDJh",
//     "funding": "credit",
//     "last4": "4242",
//     "metadata": {},
//     "name": null,
//     "tokenization_method": null
//   },
//   "client_ip": null,
//   "created": 1616007051,
//   "livemode": false,
//   "type": "card",
//   "used": false
// }
