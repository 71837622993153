import React, { useState } from "react";
import { Form, Formik, useField } from "formik";
// import * as Yup from "yup";
import { useSocket } from "../../libs/SocketProvider";
import { useHistory } from "react-router-dom";

const CustomTextInput = ({ errorDate, label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="custom-text-input">
      <input
        autoComplete="off"
        // required
        className="custom-text-input__input"
        {...field}
        {...props}
      />
      <label
        className="custom-text-input__label"
        htmlFor={props.id || props.name}
      >
        <span className="custom-text-input__label__content">{label}</span>
      </label>

      {meta.touched && meta.error ? (
        <div className={`error__text-input ${errorDate}`}>{meta.error}</div>
      ) : null}
    </div>
  );
};

const CustomTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="custom-text-area">
      <textarea
        // required
        className="custom-text-area__text-area"
        {...field}
        {...props}
      />
      <label
        className="custom-text-area__label"
        htmlFor={props.id || props.name}
      >
        <span className="custom-text-area__label__content">{label}</span>
      </label>

      {meta.touched && meta.error ? (
        <div className="error__text-area">{meta.error}</div>
      ) : null}
    </div>
  );
};

// const CustomSelect = ({ label, ...props }) => {
//   const [field, meta] = useField(props);
//   return (
//     <div>
//       <label htmlFor={props.id || props.name}>{label}</label>
//       <select {...field} {...props} />
//       {meta.touched && meta.error ? (
//         <div style={{ color: "red" }} className="error">
//           {meta.error}
//         </div>
//       ) : null}
//     </div>
//   );
// };

// const DropDownItems = ({ dropDownList }) => {
//   const listOfItems = dropDownList.map((item, index) => {
//     return (
//       <option key={index} value={item}>
//         {item}
//       </option>
//     );
//   });
//   return listOfItems;
// };

const VideoMetadataForm = (props) => {
  const reviewSubmit = () => {
    props.setSubmitted(true);
  };

  return (
    <div className="video-meta-data-form-container">
      {/* <div className="video-meta-data-form-container__left"> */}
      {/* <CustomTextInput label="ID" name="id" type="text" placeholder="" />
        <CustomTextInput
          className="video-meta-data-form__date"
          label="Date"
          name="date"
          type="date"
          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
          placeholder=""
          errorDate="error-date"
        />
        <CustomTextInput
          className="video-meta-data-form__date"
          label="Published At"
          name="publishedAt"
          type="date"
          pattern="[0-9]{2}-[0-9]{4}-[0-9]{2}"
          placeholder=""
          errorDate="error-date"
        /> */}

      {/* <CustomTextInput
          label="Duration"
          name="duration"
          type="number"
          placeholder=""
        />
        <CustomTextInput
          label="Like Count"
          name="likeCount"
          type="number"
          placeholder=""
        />
        <CustomTextInput
          label="Dislike Count"
          name="dislikeCount"
          type="number"
          placeholder=""
        /> */}
      <CustomTextInput
        label="Location"
        name="location"
        type="text"
        placeholder=""
      />
      <CustomTextInput
        label="Band Name"
        name="bandName"
        type="text"
        placeholder=""
      />
      {/* </div> */}
      {/* <div className="video-meta-data-form-container__right"> */}
      {/* <div className="video-meta-data-form-container__right__input"> */}
      <CustomTextInput label="Title" name="title" type="text" placeholder="" />
      <CustomTextArea
        label="Description"
        name="description"
        type="text"
        placeholder=""
      />
      <CustomTextInput label="Photo" name="photo" type="text" placeholder="" />
      <CustomTextInput label="Set" name="set" type="text" placeholder="" />
      <CustomTextInput label="Link" name="link" type="url" placeholder="" />
      {/* </div> */}
      <button type="button" onClick={reviewSubmit}>
        Review
      </button>
      {/* </div> */}
    </div>
  );
};

const VideoMetadataSubmittedForm = (props) => {
  console.log("[162] videoMetadata props", props.props.values);
  const history = useHistory();
  const socket = useSocket();

  const {location, bandName, title, description, photo, set, link} = props.props.values

  const submitForm = () => {
    alert(JSON.stringify(props.props.values, null, 2));
    const payload = { action: "upload-media-meta", message: props.props.values };
    console.log("[170] videometadataSubmitForm payload: ", payload)
    socket.send(JSON.stringify(payload));
    history.push("/");
  };
  // const {
  //   // location,
  //   bandName,
  //   title,
  //   description,
  //   photo,
  //   set,
  //   link,
  // } = props.values;
  return (
    <div className="video-meta-data-submitted">
      <div className="video-meta-data-submitted__title">Review</div>
      <ul>
        <li>
          <div className="video-meta-data-submitted__key">Location:</div>
          <div className="video-meta-data-submitted__value">
            {location}
          </div>
        </li>
        <li>
          <div className="video-meta-data-submitted__key">Band Name:</div>
          <div className="video-meta-data-submitted__value">
            {bandName}
          </div>
        </li>
        <li>
          <div className="video-meta-data-submitted__key">Title:</div>
          <div className="video-meta-data-submitted__value">
            {title}
          </div>
        </li>
        <li>
          <div className="video-meta-data-submitted__key">Description:</div>
          <div className="video-meta-data-submitted__value">
            {description}
          </div>
        </li>
        <li>
          <div className="video-meta-data-submitted__key">Photo:</div>
          <div className="video-meta-data-submitted__value">
            {photo}
          </div>
        </li>
        <li>
          <div className="video-meta-data-submitted__key">Set:</div>
          <div className="video-meta-data-submitted__value">
            {set}
          </div>
        </li>
        <li>
          <div className="video-meta-data-submitted__key">Link:</div>
          <div className="video-meta-data-submitted__value">
            {link}
          </div>
        </li>
      </ul>
      <div className="video-meta-data-submitted__buttons">
        <button type='button' onClick={() => props.setSubmitted(false)}>Back</button>
        <button type='submit' onClick={submitForm}>Submit</button>
      </div>
    </div>
  );
};

export const ConcertsDataForm = () => {
  const [submitted, setSubmitted] = useState(false);
  // const socket = useSocket();
  return (
    <div>
      <Formik
        initialValues={{
          id: "42",
          // date: "",
          // publishedAt: "",
          // duration: "",
          // likeCount: "",
          // dislikeCount: "",
          location: "",
          bandName: "",
          title: "",
          description: "",
          photo: "",
          set: "",
          link: "",
        }}
        //TODO finish validation schema
        // validationSchema={Yup.object({
        //   id: Yup.string().min(3, "too short").required("required"),
        //   date: Yup.date(),
        //   publishedAt: Yup.date(),
        //   duration: Yup.number().required("required"),
        //   likeCount: Yup.number()
        //     .min(0, "must be positive")
        //     .integer()
        //     .required("required"),
        //   dislikeCount: Yup.number()
        //     .min(0, "must be positive")
        //     .integer()
        //     .required("required"),
        //   location: Yup.string().required("required"),
        //   bandName: Yup.string().required("required"),
        //   title: Yup.string().required("required"),
        //   description: Yup.string().required("required"),
        //   photo: Yup.string().required("required"),
        //   set: Yup.string().required("required"),
        //   link: Yup.string().required("required"),
        // })}
        // onSubmit={async (values) => {
        //   console.log("hello");
        //   await new Promise((r) => setTimeout(r, 500));
        //   alert(JSON.stringify(values, null, 2));
        // }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // alert(JSON.stringify(values, null, 2));
          // const payload = { action: "upload-media-meta", message: { values } };
          // socket.send(JSON.stringify(payload));
          resetForm();
          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form>
            <div className="video-meta-data-form-body">
              {submitted ? (
                <VideoMetadataSubmittedForm
                  setSubmitted={setSubmitted}
                  props={props}
                />
              ) : (
                <VideoMetadataForm setSubmitted={setSubmitted} props={props} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
