import React, { useState, useEffect } from "react"
// import ReactHlsPlayer from "react-hls-player"
// import { Video } from "../Video/Video"
// import ReactPlayer from "react-player"
import Channel100 from "../../containers/Channel100"
import Channel101 from "../../containers/Channel101"
import Channel102 from "../../containers/Channel101"

// import { ReactComponent as Twitch } from "../../images/twitch.svg"
import { ReactComponent as Twitter } from "../../images/twitter.svg"
import { ReactComponent as Instagram } from "../../images/instagram.svg"
import { ReactComponent as Facebook } from "../../images/facebook.svg"
import { ReactComponent as Youtube } from "../../images/youtube.svg"

import tipExport from "../../hoc/hardCodeTipping"
import StripeCheckout from "../StripeCheckout/StripeCheckout"
import { useAppContext } from "../../libs/contextLib"
import { Auth } from "aws-amplify"
import { useSocket } from "../../libs/SocketProvider"
import { useHistory } from "react-router"

// Basically we need to have a widget per band that stack on the
// right side of the player in that empty box . So it’s tip (band name)
// and it give you a couple of price options to tip. The user then presses
// and option and it kicks you to stripe checkout to finish the transaction.
// Mike has hard coded the tip data in seed

export default function ViewVideoPage() {
  const [showHorrendous, setShowHorrendous] = useState(false)
  const [showWake, setShowWake] = useState(false)
  const [showFullOfHell, setShowFullOfHell] = useState(false)
  const [showMidnight, setShowMidnight] = useState(false)
  const [showKhemmis, setShowKhemmis] = useState(false)
  const [videoURL, setVideoURL] = useState(false)

  const { socketOpen, setShowBuyTixMsg } = useAppContext()
  const history = useHistory()
  const [localCogId, setLocalCogId] = useState("")
  const [userEmail, setUserEmail] = useState("")

  // const { productList, setProductList, socketOpen } = useAppContext()
  // const history = useHistory()
  const socket = useSocket()
  const handleCogId = async () => {
    try {
      const credentials = await Auth.currentUserCredentials()
      const cogIdTemp = credentials.identityId
      setLocalCogId(cogIdTemp)

      Auth.currentSession().then((data) => {
        const email = data.idToken.payload.email
        setUserEmail(email)
      })
    } catch (error) {
      console.log("[69] handleCogID error", JSON.stringify(error))
    }
  }

  useEffect(() => {
    if (socketOpen === true) {
      handleCogId()
    }
  }, [socketOpen])


  const getPurchasePayload = JSON.stringify({
    action: "get-tickets",
    message: { email: userEmail, cogId: localCogId },
  })

  // const handleGetTickets = async () => {
  //   console.log("[72], 445 socketRespAction function")
  //   try {
  //     socket.send(JSON.stringify(getPurchasePayload))
  //     socket.onmessage = async (message) => {
  //       const socketRespData = JSON.parse(message.data)
  //       const socketRespAction = socketRespData.action
  //       const socketRespMessage = socketRespData.message

  //       console.log("[76], 445 socketRespAction", socketRespData)

  //       if (socketRespAction === "get-tickets-resp-success") {
  //         console.log("[77], 445 socketRespAction", socketRespData)
  //       }
  //     }
  //   } catch (error) {
  //     console.log("[84] 445 handlegetitckets error", JSON.stringify(error))
  //   }
  // }
  const handleGetTix = () => {
    socket.send(getPurchasePayload)
    socket.onmessage = async (message) => {
      const socketRespData = JSON.parse(message.data)
      // const socketRespAction = socketRespData.action
      // const socketRespMessage = socketRespData.message
      const videoURLTemp = socketRespData.message.video_url
      setVideoURL(videoURLTemp)
      if (videoURLTemp === null) {
        setShowBuyTixMsg(true)
        history.push({
          pathname: "/for-sale",
          state: {
            videoURLTemp:videoURLTemp,
          },
        })
      }
     }
    }



  //     console.log("[98], 445 socketRespAction", socketRespData)

  //     if (socketRespAction === "get-tickets-resp-success") {
  //       console.log("[101], 445 socketRespAction", socketRespData)
  //     }
  //   }
  // }

  useEffect(() => {
    if (socketOpen === true) {
      handleGetTix()
    } else return
  }, [socketOpen])

  // const handleClickHorrendous = () => {
  //   setShowHorrendous(true)
  // }
  // const handleClickWake = () => {
  //   setShowWake(true)
  // }
  // const handleClickFullOfHell = () => {
  //   setShowFullOfHell(true)
  // }
  // const handleClickMidnight = () => {
  //   setShowMidnight(true)
  // }
  // const handleClickKhemmis = () => {
  //   setShowKhemmis(true)
  // }

  const handleStripeCheckout = (priceId) => {
    return <StripeCheckout priceId={priceId} />
  }

  const HandleTipHorrendous = () => {
    const eventFun = tipExport.map((event, id) => {
      if (event.bandName === "Horrendous") {
        return (
          <div key={id} className="view-video-page__right__band__tips__button">
            <StripeCheckout
              buttonText={`$${event.price / 100}`}
              priceId={event.priceId}
            />
          </div>
        )
      } else {
        return null
      }
    })
    return eventFun
  }

  const HandleTipWake = () => {
    const eventFun = tipExport.map((event, id) => {
      if (event.bandName === "Wake") {
        return (
          <div key={id} className="view-video-page__right__band__tips__button">
            <StripeCheckout
              buttonText={`$${event.price / 100}`}
              priceId={event.priceId}
            />
          </div>
        )
      } else {
        return null
      }
    })
    return eventFun
  }
  const HandleTipFullOfHell = () => {
    const eventFun = tipExport.map((event, id) => {
      if (event.bandName === "Full Of Hell") {
        return (
          <div key={id} className="view-video-page__right__band__tips__button">
            <StripeCheckout
              buttonText={`$${event.price / 100}`}
              priceId={event.priceId}
            />
          </div>
        )
      } else {
        return null
      }
    })
    return eventFun
  }
  const HandleTipMidnight = () => {
    const eventFun = tipExport.map((event, id) => {
      if (event.bandName === "Midnight") {
        return (
          <div key={id} className="view-video-page__right__band__tips__button">
            <StripeCheckout
              buttonText={`$${event.price / 100}`}
              priceId={event.priceId}
            />
          </div>
        )
      } else {
        return null
      }
    })
    return eventFun
  }
  const HandleTipKhemmis = () => {
    const eventFun = tipExport.map((event, id) => {
      if (event.bandName === "Khemmis") {
        return (
          <div key={id} className="view-video-page__right__band__tips__button">
            <StripeCheckout
              buttonText={`$${event.price / 100}`}
              priceId={event.priceId}
            />
          </div>
        )
      } else {
        return null
      }
    })
    return eventFun
  }

  // 1, 5, 10, 20, 50, 100
  // {
  // 	bandName: "Khemmis",
  // 	description: "Extreme!: Khemmis - Band Tip $100",
  // 	price: "10000",
  // 	priceId: "price_1IlhUEKsNNk3qPPUvwYIi3Sc"
  // }

  return (
    <div className="view-video-page">
      <div className="view-video-page__left">
        <div className="player-wrapper">
          <Channel101  />
        </div>
      </div>
      <div className="view-video-page__right">
        <div className="view-video-page__right__title">TIP THE BAND</div>

        <div className="view-video-page__right__band">
          <div className="view-video-page__right__band__bandName">
            Horrendous
          </div>
          <div className="view-video-page__right__band__tips">
            {HandleTipHorrendous()}
          </div>
        </div>
        <div className="view-video-page__right__band">
          <div className="view-video-page__right__band__bandName">Wake</div>
          <div className="view-video-page__right__band__tips">
            {HandleTipWake()}
          </div>
        </div>
        <div className="view-video-page__right__band">
          <div className="view-video-page__right__band__bandName">
            Full of Hell
          </div>
          <div className="view-video-page__right__band__tips">
            {HandleTipFullOfHell()}
          </div>
        </div>
        <div className="view-video-page__right__band">
          <div className="view-video-page__right__band__bandName">Midnight</div>
          <div className="view-video-page__right__band__tips">
            {HandleTipMidnight()}
          </div>
        </div>
        <div className="view-video-page__right__band">
          <div className="view-video-page__right__band__bandName">Khemmis</div>
          <div className="view-video-page__right__band__tips">
            {HandleTipKhemmis()}
          </div>
        </div>
      </div>

      <div className="view-video-page__bottom">
        <div className="view-video-page__bottom__links">
          <a
            className="view-video-page__bottom__links__instagram"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.instagram.com/decibelmagazine/"
          >
            <Instagram />
          </a>
          <a
            className="view-video-page__bottom__links__facebook"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.facebook.com/decibelmagazine/"
          >
            <Facebook />
          </a>
          <a
            className="view-video-page__bottom__links__twitter"
            rel="noopener noreferrer"
            target="_blank"
            href="https://twitter.com/dbmagazine"
          >
            <Twitter />
          </a>
          <a
            className="view-video-page__bottom__links__twitch"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.youtube.com/channel/UCVXce0AJL6hUtGcdRSWUVVQ"
          >
            <Youtube />
          </a>
        </div>
      </div>
    </div>
  )
 }