import React from "react"
import { useAppContext } from "../libs/contextLib"
import { useSocket } from "../libs/SocketProvider"

export const ErrorMessageBox = ({ children }) => {
  return <div className="error-message-box">{children}</div>
}

export const SocketDisconnectHandler = () => {
  const socket = useSocket()
  const { setSocketOpen } = useAppContext()
  const HandleRetrySocketClick = () => {
    console.log("[13] handleRetrySocke open")
    socket.onopen = () => {
      console.log("[15] SOCKET REOPENED")
      setSocketOpen(true)
    }
    socket.onopen()
    // window.location.reload();
  }

  return (
    // <div className="socket-disconnect-handler">
    <div onClick={HandleRetrySocketClick} className="socket-disconnect-handler">
      <div className="socket-disconnect-handler__text">
        You are no longer connected to the server. Please press "RETRY" to
        reconnect.
      </div>
      <div className="socket-disconnect-handler__button">
        <div onClick={HandleRetrySocketClick}>RETRY</div>
      </div>
    </div>
  )
}



export const ShowBuyTixMessage = () => {
  return (
    <div className="show-buy-tix-msg" >
      Please purchase a ticket to view the video player. 
    </div>
  )
}
