import React, { useState, useEffect } from "react"
import LoaderButton from "./LoaderButton"
import { useAppContext } from "../libs/contextLib"
import { useSocket } from "../libs/SocketProvider"
import AddCardToStripe from "../payments/AddCardToStripe"
import Modal from "./Modal"
// import { ReactComponent as GarbageCan } from "../images/garbageCan.svg"
// import { ReactComponent as CreditCardPlus } from "../images/creditCardPlus.svg"
import { useStripe } from "@stripe/react-stripe-js"
import { Auth } from "aws-amplify"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
// import { CardGiftcardSharp } from "@material-ui/icons"
import { ErrorMessageBox } from "./ErrorMessageBox"

// import { PaymentErrorReducer } from "./StripeReducer/StripeReducer"
import { useHistory } from "react-router"
import { ReactComponent as Checkmark } from "../images/checkDecagramIcon.svg"
import { ReactComponent as LockOutline } from "../images/lockOutline.svg"

import AllFourCards from "../images/All4Cards.png"

//? code: card_declined, decline_code: generic_decline
//? code: incorrect_cvc,
//? code: card_declined, decline_code: fraudulent         (use alternative payment method )
//? code: card_declined, decline_code: insufficient_funds (use alternative payment method )
//? code: card_declined, decline_code: lost_card
//? code: card_declined, decline_code: stolen_card
//? code: expired_card

export const ChoosePaymentMethod = ({ isLoading }) => {
  // const { errorMessage } = state
  const history = useHistory()

  const socket = useSocket()
  const stripe = useStripe()

  const stripeKey = process.env.REACT_APP_STRIPE_KEY
  const stripePromise = loadStripe(stripeKey)

  const {
    setUserId,
    userId,
    // cogId,
    socketOpen,
    paymentErrorMsg,
    setPaymentErrorMsg,
  } = useAppContext()

  const [cardData, setCardData] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [chosenCard, setChosenCard] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  // const [deleteWarningIsOpen, setDeleteWarningIsOpen] = useState(false)
  const [localCogId, setLocalCogId] = useState("")
  const [successMsg, setSuccessMsg] = useState(false)
  const [isCardUpdate, setIsCardUpdate] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  // const [errorMsg, setErrorMsg] = useState("")
  // const [initialDispatchState, setInitialDispatchState] = useState({})
  const [showUpdateCard, setShowUpdateCard] = useState("")
  const [transactionID, setTransactionID] = useState("")
  const [grandTotal, setGrandTotal] = useState(null)
  // const [isSubscription, setIsSubscription] = useState(false)
  const [customerId, setCustomerId] = useState("")
  const [acquiredId, setAcquiredId] = useState("")
  // const [statusMessage, setStatusMessage] = useState("")
  const [confirmCardStatus, setConfirmCardStatus] = useState("")
  const [confirmCardPaymentRequest, setConfirmCardPaymentRequest] = useState([])
  // const [paymentMethodIdError, setPaymentMethodIdError] = useState("")
  // const [paymentMethodId, setPaymentMethodId] = useState("")
  // const [state, dispatch] = useReducer(
  //   paymentErrorReducer,
  //   initialDispatchState
  // )
  const [intentId, setIntentId] = useState("")
  // useEffect(() => {
  //   const initialDispatchState = {
  //     errorMessage: "",
  //     userId: userId,
  //     cogId: localCogId,
  //     email: userEmail,
  //     cardId: chosenCard.cardId,
  //     paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
  //   }
  //   setInitialDispatchState(initialDispatchState)
  //   return () => {}
  // }, [userId, localCogId, userEmail, chosenCard])
  const [clientSecret, setClientSecret] = useState("")


  isLoading = isProcessing || isLoading

  // const getCardsPayload = JSON.stringify({
  //   action: "wallet-get-user-credit-cards",
  //   message: {
  //     userId: userId,
  //     cogId: localCogId,
  //     email: userEmail,
  //   },
  // })

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.subscription) {
        // setIsSubscription(true)
      }
    }
    return () => {}
  }, [history.location.state])

  const handleCogId = async () => {
    try {
      const credentials = await Auth.currentUserCredentials()
      const cogIdTemp = credentials.identityId
      setLocalCogId(cogIdTemp)

      Auth.currentSession().then((data) => {
        const email = data.idToken.payload.email
        setUserEmail(email)
        const getUserIdPayload = JSON.stringify({
          action: "get-user-id",
          message: { cogId: cogIdTemp, email: email },
        })
        socket.send(getUserIdPayload)
        socket.onmessage = async (message) => {
          const socketRespData = JSON.parse(message.data)
          const socketRespAction = socketRespData.action
          const socketRespMessage = socketRespData.message

          if (socketRespAction === "retrieved-user-success") {
            //todo
            // if (socketRespAction === "get-user-id-resp-success") {
            const userIdTemp = socketRespMessage.ID
            const customerIdTemp = socketRespMessage.stripeCustomerId
            setUserId(userIdTemp)
            setCustomerId(customerIdTemp)

            const getCardsPayloadThen = JSON.stringify({
              action: "wallet-get-user-credit-cards",
              message: {
                userId: userIdTemp,
                cogId: cogIdTemp,
                email: email,
              },
            })
            socket.send(getCardsPayloadThen)
          } else if (
            socketRespAction === "wallet-get-user-credit-cards-resp-success"
          ) {
            // setCardData(socketRespData.cards.active)
            const creditCards = socketRespData.creditCards
            const cardsCreated = creditCards.map((card, id) => {
              return card.paymentMethodObj.created
            })
            const latestCardCreated = Math.max(...cardsCreated)
            let creditCardData = creditCards
            setCardData(
              creditCardData.map((data, id) => {
                const lastCard = () => {
                  if (data.paymentMethodObj.created === latestCardCreated) {
                    let lastCardAdded = true
                    setChosenCard({
                      cardId: data.ID,
                      active: data.active,
                      brand: data.brand,
                      emailAddress: data.emailAddress,
                      expMonth: data.expMonth,
                      expYear: data.expYear,
                      lastFour: data.lastFour,
                      paymentMethodObj: data.paymentMethodObj,
                      isChecked: true,
                    })
                    return lastCardAdded
                  } else {
                    let lastCardAdded = false
                    return lastCardAdded
                  }
                }

                return {
                  cardId: data.ID,
                  active: data.active,
                  brand: data.brand,
                  emailAddress: data.emailAddress,
                  expMonth: data.expMonth,
                  expYear: data.expYear,
                  lastFour: data.lastFour,
                  paymentMethodObj: data.paymentMethodObj,
                  isChecked: lastCard(),
                }
              })
            )
          } else {
          }
        }
      })
    } catch (error) {
    }
  }

  //!===========================================================CREATE PAYMENT INTENT===================

  const createPaymentIntent = () => {


    const itemsPayload = () => {
      if (history.location.state) {
        if (history.location.state.products) {
          let items = []
          const productList = history.location.state.products
          const handleProductList = productList.map((product) => {
            const { price, item_id } = product
            return { item_id: item_id, price_cents: price }
          })
          items = items.concat(handleProductList)
        } else if (history.location.state.subscription) {
          const { item_id, cost } = history.location.state.subscription
          return { item_id: item_id, price_cents: cost }
        } else if (history.location.state.ticket) {

          // const {
          //   price,
          //   // ,ticketId
          // } = history.location.state.ticket
          const {
            item_id,
            // cost,
            // eventName,
            // bandName,
            // ticketDate,
          } = history.location.state
          return { item_id: item_id, price_cents: Number(grandTotal * 100) }
        }
      } else {
        return
      }
    }
    itemsPayload()

    const purchaseIntentItems = itemsPayload()
    const {
      // item_id,
      cost,
      eventName,
      bandName,
      ticketDate,
    } = history.location.state
    const {
      // price,
      ticketId,
    } = history.location.state.ticket
    const createIntentPayload = JSON.stringify({
      action: "create-purchase-intent",
      message: {
        userId: userId,
        cogId: localCogId,
        email: userEmail,
        cardId: chosenCard.cardId,
        paymentMethodId: chosenCard.paymentMethodObj?.id,
        items: [purchaseIntentItems],
        ticId: ticketId,
        ticDate: ticketDate,
        bandName: bandName,
        eventName: eventName,
        priceInCents: cost,
        // items: [
        //   { item_id: "42424545hj5jkh5kjh5k55l29er", price_cents: 200 },
        //   { item_id: "24534lkjlkjljklkj324lkj4lkj", price_cents: 200 },
        //   { item_id: "kjhsfkjppqa4324234kjhkjwh6j8", price_cents: 200 },
        // ],
      },
    })
    socket.send(createIntentPayload)
    socket.onmessage = async (message) => {
      const socketRespData = JSON.parse(message.data)
      const socketRespAction = socketRespData.action

      if (socketRespAction === "create-purchase-intent-resp-success") {
        const clientSecretTemp =
          socketRespData.message.paymentIntentobjResp.client_secret
        const transId = socketRespData.message.transactionID
        const intId = socketRespData.intentId
        const acquiredId = socketRespData.message.acquiredId
        setTransactionID(transId)
        setIntentId(intId)
        setClientSecret(clientSecretTemp)
        setAcquiredId(acquiredId)
      } else
        console.log(
          "[287] create purchase intent failure socketRespAction:",
          socketRespAction
        )

      //     transactionId: socketRespData.transactionID,
      //     statusResp: "FAILURE", //stripe status
      //     userId: userId,
      //     email: userEmail,
      //     cardId: chosenCard.cardId,
      //     intentId: socketRespData.intentId,
    }
  }

  useEffect(() => {
    if (socketOpen === true) {
      handleCogId()
    }
  }, [socketOpen, isOpen])

  useEffect(() => {
    if (userId && localCogId && userEmail && chosenCard) {
      createPaymentIntent()
      return () => {}
    }
  }, [userId, localCogId, userEmail, chosenCard])

  const handleSubmitChoosePayment = async (event) => {
    event.preventDefault()
    setIsProcessing(true)

    const paymentMethod = chosenCard.paymentMethodObj.id
    //! ====================================================CONFIRM CARD PAYMENT=============!!!!!!!!
    if (history.location.state.subscription) {
      const createUserSubscriptionPayload = JSON.stringify({
        action: "create-user-subscription",
        message: {
          stripeCustomerId: customerId,
          paymentMethodId: paymentMethod,
          // priceId: history.location.state.subscription.features.price_id,
          priceId: "price_1IiMljKsNNk3qPPUwBtO8VfL",
          // priceId: history.location.state.subscription.priceId,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
        },
      })
      socket.send(createUserSubscriptionPayload)
      socket.onmessage = async (message) => {
        const socketRespData = JSON.parse(message.data)
        const socketRespAction = socketRespData.action

        if (socketRespAction === "create-user-subscription-resp-success") {
          setIsProcessing(false)
          setSuccessMsg(true)
          setTimeout(() => {
            setSuccessMsg(false)
          }, 3000)
        } else {
          console.log("[338] socketresp data ", socketRespData)
        }
      }
    } else {
      const confirmCardPayment = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
      })

      const confirmCardPaymentRequest = [
        clientSecret,
        { payment_method: paymentMethod },
      ]
      setConfirmCardPaymentRequest(confirmCardPaymentRequest)

      if (confirmCardPayment.error) {
        const errorCode = confirmCardPayment.error.code
        const declineCode = confirmCardPayment.error.decline_code
        const codeType = declineCode ? declineCode : errorCode
        const errorMessage = confirmCardPayment.error.message
        // const statusErrorMessage =
        //   confirmCardPayment.error.payment_intent.status
        // const paymentMethodIdError = !!confirmCardPayment.error.payment_method.id
        //   ? confirmCardPayment.error.payment_method.id
        //   : confirmCardPayment.error.payment_intent.payment_method
        // const paymentMethodId = confirmCardPayment.paymentIntent.payment_method
        // setPaymentMethodIdError(paymentMethodIdError)
        // setPaymentMethodId(paymentMethodId)
        // setStatusMessage(statusErrorMessage)
        //!~~~~~~~~~~~~~~~~~

        errorCodeHandler(codeType, confirmCardPayment, errorMessage)

        // const failurePayload = JSON.stringify({
        //   action: "stripe-process-success-transaction-update",
        //   message: {
        //     stripeConfirmationPayload: confirmCardPayment,
        //     cogId: localCogId,
        //     transactionId: transactionID,
        //     statusResp: "ERROR", //stripe status
        //     userId: userId,
        //     email: userEmail,
        //     cardId: chosenCard.cardId,
        //     intentId: intentId,
        //   },
        // })
        // socket.send(failurePayload)
      } else {
        setConfirmCardStatus(confirmCardPayment.paymentIntent.status)
        // const {
        //   // item_id,
        //   cost,
        //   eventName,
        //   bandName,
        //   ticketDate,
        // } = history.location.state
        // const {
        //   //  price,
        //   ticketId,
        // } = history.location.state.ticket

        // const metaPayload = {
        //   ticId: ticketId,
        //   ticDate: ticketDate,
        //   bandName: bandName,
        //   eventName: eventName,
        //   priceInCents: cost,
        // }

        //     const genericDeclinePayload = JSON.stringify({
        //       action: "stripe-process-error-incorrect-cvc",
        //       message: {
        //          stripeCardConfirmRespPayload:       <js obj response cardConfirmAttempt>,
        //          stripeCardConfirmReqPayload:        <js obj request payload>,
        //          userId:                             userId,
        //          cogId:                              localCogId,
        //          email:                              userEmail,
        //          cardId:                             chosenCard.cardId,
        //          transactionId:                      transactionID,
        //          intentId:                           intentId,
        //          statusResp:                         "SUCCESS", //stripe status
        //          acquiredId:                         acquiredId,
        //          paymentMethodId:                    paymentMethodId
        //      }
        //  });

        const successPayload = JSON.stringify({
          action: "stripe-process-success-transaction-update",
          message: {
            stripeCardConfirmRespPayload: confirmCardPayment,
            stripeCardConfirmReqPayload: confirmCardPaymentRequest,
            userId: userId,
            cogId: localCogId,
            email: userEmail,
            cardId: chosenCard.cardId,
            transactionId: transactionID,
            intentId: intentId,
            statusResp: confirmCardStatus, //stripe status
            acquiredId: acquiredId,
            paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
            // stripeConfirmPaymentMetaPayload: metaPayload,
          },
        })
        socket.send(successPayload)
        // setError(null)
        setIsProcessing(false)
        setSuccessMsg(true)
        setTimeout(() => {
          setSuccessMsg(false)
        }, 3000)
      }
      // } else {
      //   console.log(
      //     "[223], CONFIRM CARD FAILURE",
      //     JSON.stringify(socketRespData)
      //   )
      // }
      // }
      //!<<<<<<<<<<<<<<<<<<<<<<<CREATE PURCHASE REQUEST END <<<<<<<<<<<<<<<<<<<<<<

      setIsProcessing(false)
    }
  }

  //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~ERROR CODE HANDLER ~~~~~~~~~~~~~~>>>>>>>>>>
  const errorCodeHandler = (codeType, confirmCardPayment, errorMessage) => {
    if (codeType === "generic_decline") {
      //? SOCKET COMMUNICATIONS GO HERE IE SOCKET.SEND AND SOCKET.ONMESSAGE
      const genericDeclinePayload = JSON.stringify({
        // action: "stripe-process-success-transaction-update",
        action: "stripe-process-error-card-deactivate",
        message: {
          stripeCardConfirmRespPayload: confirmCardPayment,
          stripeCardConfirmReqPayload: confirmCardPaymentRequest,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
          cardId: chosenCard.cardId,
          transactionId: transactionID,
          intentId: intentId,
          statusResp: confirmCardStatus, //stripe status
          acquiredId: acquiredId,
          paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
          // stripeConfirmPaymentMetaPayload: metaPayload,
        },
      })
      socket.send(genericDeclinePayload)
      // socket.onmessage = async (message) => {
      //   const socketRespData = JSON.parse(message.data)
      //   // const socketRespAction = socketRespData.action

      //   // if (socketRespAction === "create-purchase-intent-resp-success") {
      //   //   console.log("[34] socketresp data ", socketRespData)
      //   // } else {
      //   //   console.log("[38] socketresp data ", socketRespData)
      //   // }
      // }

      setPaymentErrorMsg(
        "Your card was declined. Please contact your card issuer for more information. Please add or choose another card to process this payment."
      )
    } else if (codeType === "fraudulent") {
      //? SOCKET COMMUNICATIONS GO HERE IE SOCKET.SEND AND SOCKET.ONMESSAGE
      console.log("[18] fraudulent")
      const fraudulentPayload = JSON.stringify({
        action: "stripe-process-error-card-deactivate",
        message: {
          stripeCardConfirmRespPayload: confirmCardPayment,
          stripeCardConfirmReqPayload: confirmCardPaymentRequest,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
          cardId: chosenCard.cardId,
          transactionId: transactionID,
          intentId: intentId,
          statusResp: confirmCardStatus, //stripe status
          acquiredId: acquiredId,
          paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
          // stripeConfirmPaymentMetaPayload: metaPayload,
        },
      })
      socket.send(fraudulentPayload)
      // socket.onmessage = async (message) => {
      //   // const socketRespData = JSON.parse(message.data)
      //   // const socketRespAction = socketRespData.action

      //   // if (socketRespAction === "create-purchase-intent-resp-success") {
      //   //   console.log("[34] socketresp data ", socketRespData)
      //   // } else {
      //   //   console.log("[38] socketresp data ", socketRespData)
      //   // }
      // }

      setPaymentErrorMsg(
        "Your card was declined. Please contact your card issuer for more information. Please add or choose another card to process this payment."
      )
    } else if (codeType === "insufficient_funds") {
      console.log("[25] insufficient_funds")
      //? SOCKET COMMUNICATIONS GO HERE IE SOCKET.SEND AND SOCKET.ONMESSAGE
      const insufficientFundsPayloads = JSON.stringify({
        action: "stripe-process-error-insufficient-funds",
        // action: "handle-payment-error",
        message: {
          stripeCardConfirmRespPayload: confirmCardPayment,
          stripeCardConfirmReqPayload: confirmCardPaymentRequest,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
          cardId: chosenCard.cardId,
          transactionId: transactionID,
          intentId: intentId,
          statusResp: confirmCardStatus, //stripe status
          acquiredId: acquiredId,
          paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
          // stripeConfirmPaymentMetaPayload: metaPayload,
        },
      })
      socket.send(insufficientFundsPayloads)
      // socket.onmessage = async (message) => {
      //   // const socketRespData = JSON.parse(message.data)
      //   // const socketRespAction = socketRespData.action

      //   // if (socketRespAction === "create-purchase-intent-resp-success") {
      //   //   console.log("[34] socketresp data ", socketRespData)
      //   // } else {
      //   //   console.log("[38] socketresp data ", socketRespData)
      //   // }
      // }
      setPaymentErrorMsg(
        "Your transaction has failed due to insufficient funds. Please use a different card."
      )
    } else if (codeType === "lost_card") {
      //? SOCKET COMMUNICATIONS GO HERE IE SOCKET.SEND AND SOCKET.ONMESSAGE
      const lostCardPayload = JSON.stringify({
        action: "stripe-process-error-card-deactivate",
        message: {
          stripeCardConfirmRespPayload: confirmCardPayment,
          stripeCardConfirmReqPayload: confirmCardPaymentRequest,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
          cardId: chosenCard.cardId,
          transactionId: transactionID,
          intentId: intentId,
          statusResp: confirmCardStatus, //stripe status
          acquiredId: acquiredId,
          paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
          // stripeConfirmPaymentMetaPayload: metaPayload,
        },
      })
      socket.send(lostCardPayload)
      // socket.onmessage = async (message) => {
      //   // const socketRespData = JSON.parse(message.data)
      //   // const socketRespAction = socketRespData.action

      //   // if (socketRespAction === "create-purchase-intent-resp-success") {
      //   //   console.log("[34] socketresp data ", socketRespData)
      //   // } else {
      //   //   console.log("[38] socketresp data ", socketRespData)
      //   // }
      // }
      setPaymentErrorMsg(
        "Your card was declined. Please contact your card issuer for more information. Please add or choose another card to process this payment."
      )
    } else if (codeType === "stolen_card") {
 
      //? SOCKET COMMUNICATIONS GO HERE IE SOCKET.SEND AND SOCKET.ONMESSAGE
      const stolenCardPayload = JSON.stringify({
        action: "stripe-process-error-card-deactivate",
        message: {
          stripeCardConfirmRespPayload: confirmCardPayment,
          stripeCardConfirmReqPayload: confirmCardPaymentRequest,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
          cardId: chosenCard.cardId,
          transactionId: transactionID,
          intentId: intentId,
          statusResp: confirmCardStatus, //stripe status
          acquiredId: acquiredId,
          paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
          // stripeConfirmPaymentMetaPayload: metaPayload,
        },
      })
      socket.send(stolenCardPayload)
      // socket.onmessage = async (message) => {
      //   // const socketRespData = JSON.parse(message.data)
      //   // const socketRespAction = socketRespData.action

      //   // if (socketRespAction === "create-purchase-intent-resp-success") {
      //   //   console.log("[34] socketresp data ", socketRespData)
      //   // } else {
      //   //   console.log("[38] socketresp data ", socketRespData)
      //   // }
      // }
      setPaymentErrorMsg(
        "Your card was declined. Please contact your card issuer for more information. Please add or choose another card to process this payment."
      )
    } else if (codeType === "incorrect_cvc") {
      //? SOCKET COMMUNICATIONS GO HERE IE SOCKET.SEND AND SOCKET.ONMESSAGE
      const incorrectCVCPayload = JSON.stringify({
        action: "stripe-process-error-incorrect-cvc",
        message: {
          stripeCardConfirmRespPayload: confirmCardPayment,
          stripeCardConfirmReqPayload: confirmCardPaymentRequest,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
          cardId: chosenCard.cardId,
          transactionId: transactionID,
          intentId: intentId,
          statusResp: confirmCardStatus, //stripe status
          acquiredId: acquiredId,
          paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
          // stripeConfirmPaymentMetaPayload: metaPayload,
        },
      })
      socket.send(incorrectCVCPayload)
      // socket.onmessage = async (message) => {
      //   // const socketRespData = JSON.parse(message.data)
      //   // const socketRespAction = socketRespData.action

      //   // if (socketRespAction === "create-purchase-intent-resp-success") {
      //   //   console.log("[34] socketresp data ", socketRespData)
      //   // } else {
      //   //   console.log("[38] socketresp data ", socketRespData)
      //   // }
      // }
      setPaymentErrorMsg(
        "Your transaction failed due to mismatched CVC numbers. Please check the card's security code or use a different card."
      )
      setShowUpdateCard(true)

      // <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      // <Elements stripe={stripePromise}>
      //   <AddCardToStripe
      //     cardId={chosenCard.cardId}
      //     isCardUpdate={isCardUpdate}
      //     onClose={() => setIsOpen(false)}
      //   />
    } else if (codeType === "expired_card") {
      //? SOCKET COMMUNICATIONS GO HERE IE SOCKET.SEND AND SOCKET.ONMESSAGE
      const expiredCardPayload = JSON.stringify({
        action: "stripe-process-error-card-expired",
        // action: "handle-payment-error",
        message: {
          stripeCardConfirmRespPayload: confirmCardPayment,
          stripeCardConfirmReqPayload: confirmCardPaymentRequest,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
          cardId: chosenCard.cardId,
          transactionId: transactionID,
          intentId: intentId,
          statusResp: confirmCardStatus, //stripe status
          acquiredId: acquiredId,
          paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
          // stripeConfirmPaymentMetaPayload: metaPayload,
        },
      })
      socket.send(expiredCardPayload)
      // socket.onmessage = async (message) => {
      //   // const socketRespData = JSON.parse(message.data)
      //   // const socketRespAction = socketRespData.action

      //   // if (socketRespAction === "create-purchase-intent-resp-success") {
      //   //   console.log("[34] socketresp data ", socketRespData)
      //   // } else {
      //   //   console.log("[38] socketresp data ", socketRespData)
      //   // }
      // }
      setPaymentErrorMsg(
        "Your card is expired. Please check the expiration date or use a different card."
      )
    } else if (codeType === "processing_error") {
      //? SOCKET COMMUNICATIONS GO HERE IE SOCKET.SEND AND SOCKET.ONMESSAGE
      const paymentFailurePayload = JSON.stringify({
        action: "stripe-process-error-payment-failure",
        // action: "handle-payment-error",
        message: {
          stripeCardConfirmRespPayload: confirmCardPayment,
          stripeCardConfirmReqPayload: confirmCardPaymentRequest,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
          cardId: chosenCard.cardId,
          transactionId: transactionID,
          intentId: intentId,
          statusResp: confirmCardStatus, //stripe status
          acquiredId: acquiredId,
          paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
          // stripeConfirmPaymentMetaPayload: metaPayload,
        },
      })
      socket.send(paymentFailurePayload)
      // socket.onmessage = async (message) => {
      //   // const socketRespData = JSON.parse(message.data)
      //   // const socketRespAction = socketRespData.action

      //   // if (socketRespAction === "create-purchase-intent-resp-success") {
      //   //   console.log("[34] socketresp data ", socketRespData)
      //   // } else {
      //   //   console.log("[38] socketresp data ", socketRespData)
      //   // }
      // }
      setPaymentErrorMsg(
        "Our payment processing partner has failed to execute your transaction. Please try again in a little bit."
      )
    } else if (codeType === "incorrect_zip") {
      //? SOCKET COMMUNICATIONS GO HERE IE SOCKET.SEND AND SOCKET.ONMESSAGE
      const incorrectZIPPayload = JSON.stringify({
        action: "stripe-process-error-incorrect-zipcode",
        message: {
          stripeCardConfirmRespPayload: confirmCardPayment,
          stripeCardConfirmReqPayload: confirmCardPaymentRequest,
          userId: userId,
          cogId: localCogId,
          email: userEmail,
          cardId: chosenCard.cardId,
          transactionId: transactionID,
          intentId: intentId,
          statusResp: confirmCardStatus, //stripe status
          acquiredId: acquiredId,
          paymentMethodId: chosenCard ? chosenCard.paymentMethodObj.id : null,
          // stripeConfirmPaymentMetaPayload: metaPayload,
        },
      })
      socket.send(incorrectZIPPayload)
      // socket.onmessage = async (message) => {
      //   // const socketRespData = JSON.parse(message.data)
      //   // const socketRespAction = socketRespData.action

      //   // if (socketRespAction === "create-purchase-intent-resp-success") {
      //   //   console.log("[34] socketresp data ", socketRespData)
      //   // } else {
      //   //   console.log("[38] socketresp data ", socketRespData)
      //   // }
      // }
      setPaymentErrorMsg(
        "Your transaction failed due to incorrect ZIP code. Please check the card's ZIP code or use a different card."
      )
      setShowUpdateCard(true)

      // <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      // <Elements stripe={stripePromise}>
      //   <AddCardToStripe
      //     cardId={chosenCard.cardId}
      //     isCardUpdate={isCardUpdate}
      //     onClose={() => setIsOpen(false)}
      //   />
    } else {
      setPaymentErrorMsg(errorMessage)
      // setPaymentErrorMsg("Unknown error. Please try again.")
    }
  }
  //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~END ERROR MESSAGE HANDLING~~~~~~~~~~~~~~~~~~~~~

  const onChangeHandler = (val, initData) => {
    let checked = val.target.checked
    setCardData(
      cardData.map((data, id) => {
        if (data.cardId === initData.cardId) {
          data.isChecked = checked
          setChosenCard(data)
        } else {
          data.isChecked = false
        }
        return data
      })
    )
  }
  //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~delete card
  // const handleRemoveCard = (cardData, cardId) => {
  //   //send payload to server to delete card.
  //   const users = Object.assign([], cardData)
  //   users.splice(cardId, 1)
  //   setCardData(users)

  //   const deleteCardPayload = JSON.stringify({
  //     action: "wallet-delete-user-credit-card",
  //     message: {
  //       userId: userId,
  //       cogId: cogId,
  //       email: userEmail,
  //       stripeCardPayment: cardData.paymentMethodId,
  //       cardId: cardData.cardId,
  //     },
  //   })
  //   socket.send(deleteCardPayload)
  //   socket.send(getCardsPayload)
  //   // setDeleteWarningIsOpen(false)
  // }

  const handleAddCardBtn = () => {
    setIsCardUpdate(false)
    setIsOpen(true)
    setPaymentErrorMsg("")
  }

  const handleUpdateCard = (cardData, cardId) => {
    setIsCardUpdate(true)
    setIsOpen(true)
    setPaymentErrorMsg("")
    setShowUpdateCard(false)
  }

  // const DeleteWarningMessage = (data, id) => {
  //   return (
  //     <div className="delete-card">
  //       <div className="delete-card__question">
  //         Are you sure you want to delete card?
  //       </div>
  //       <div className="delete-card__buttons">
  //         <button
  //           className="delete-card__buttons__no"
  //           type="button"
  //           // onClick={() => setDeleteWarningIsOpen(false)}
  //         >
  //           Back
  //         </button>
  //         <button
  //           className="delete-card__buttons__yes"
  //           type="button"
  //           onClick={() => handleRemoveCard(data, id)}
  //         >
  //           Delete
  //         </button>
  //       </div>
  //     </div>
  //   )
  // }

  const SuccessMessage = () => {
    return (
      <div className="credit-card-success">
        <div>Thank you for your payment</div>
      </div>
    )
  }

  const HandleProducts = () => {
    if (history.location.state.products) {
      const productList = history.location.state.products
      const handleProductList = productList.map((product, id) => {
        const { title, price, date, platformFee } = product

        // const totalPriceOfProduct = (price + platformFee) / 100
        const actualPrice = price / 100
        const actualPlatformFee = platformFee / 100

        // const totalPriceOfProductWithTwoDecimalPlaces = parseFloat(
        //   totalPriceOfProduct
        // ).toFixed(2)
        const priceWithTwoDecimalPlaces = parseFloat(actualPrice).toFixed(2)
        const platformFeeWithTwoDecimalPlaces = parseFloat(
          actualPlatformFee
        ).toFixed(2)

        return (
          <div key={id} className="choose-payment-method__billing">
            <div className="choose-payment-method__billing__billing-header">
              <div className="choose-payment-method__billing__billing-header__title">
                {title}
              </div>
              <div className="choose-payment-method__billing__billing-header__date">
                {date}
              </div>
            </div>
            <div className="choose-payment-method__billing__products">
              <div className="choose-payment-method__billing__products__checkout">
                Checkout
              </div>
              <div className="choose-payment-method__billing__products__line-skinny"></div>
              <div className="choose-payment-method__billing__products__item">
                <p className="choose-payment-method__billing__products__item__item-name">
                  Ticket
                </p>
                <span className="choose-payment-method__billing__products__item__price">
                  ${priceWithTwoDecimalPlaces}
                </span>
              </div>
              <div className="choose-payment-method__billing__products__item">
                <p className="choose-payment-method__billing__products__item__item-name">
                  Platform Fee
                </p>
                <span className="choose-payment-method__billing__products__item__price">
                  ${platformFeeWithTwoDecimalPlaces}
                </span>
              </div>
              {/* <div className="choose-payment-method__billing__products__line-skinny"></div> */}
              <div className="choose-payment-method__billing__products__total">
                {/* <p className="choose-payment-method__billing__products__total__item-name-total">
                  Price
                </p>
                <span className="choose-payment-method__billing__products__total__price">
                  ${totalPriceOfProductWithTwoDecimalPlaces}
                </span> */}
              </div>
            </div>
          </div>
        )
      })
      return handleProductList
    } else if (history.location.state.ticket) {
      // console.log("[888] ticket", history.location.state.ticket)
      const productList = history.location.state.ticket
      const productTicket = history.location.state
      console.log("[890] productLIst", productList)
      console.log("[891] producttix", productTicket)
      const {
        title,
        price,
        image,
        // boxedItems,
        attributes,
      } = history.location.state.ticket
      const halfLengthOfFeatures = attributes.length / 2

      const platformFee = price * 0.08
      const totalPriceOfProduct = Number(price) + Number(platformFee)
      const actualPrice = price
      const actualPlatformFee = platformFee

      // const totalPriceOfProductWithTwoDecimalPlaces = parseFloat(
      //   totalPriceOfProduct
      // ).toFixed(2)
      const priceWithTwoDecimalPlaces = parseFloat(actualPrice).toFixed(2)
      const platformFeeWithTwoDecimalPlaces = parseFloat(
        actualPlatformFee
      ).toFixed(2)

      setGrandTotal(totalPriceOfProduct)

      return (
        <div className="checkout-tickets">
          <div className="checkout-tickets__top">
            <div className="checkout-tickets__top__image">
              <img alt="image" src={image} />
            </div>
            <div className="checkout-tickets__top__right">
              <div className="checkout-tickets__top__right__title-price">
                <div className="checkout-tickets__top__right__title-price__title">
                  {title}
                </div>
                {/* <div className="checkout-tickets__top__right__title-price__price">
                {price}
              </div> */}
              </div>
              <div className="checkout-tickets__top__right__attribute">
                <div className="checkout-tickets__top__right__attribute__left">
{/* //https://stackoverflow.com/questions/45014094/how-do-i-fix-expected-to-return-a-value-at-the-end-of-arrow-function-warning */}
                  {attributes.map((item, id) => {
                    if (id < halfLengthOfFeatures) {
                      if (isNaN(item)) {
                        return (
                          <div
                            key={id}
                            className="checkout-tickets__top__right__attribute__left"
                          >
                            {/* <Checkmark /> */}
                            {item}
                          </div>
                        )
                      }
                    } else {
                      return null
                    }
                  })}
                </div>
                <div className="checkout-tickets__top__right__attribute__right">
                  {attributes.map((item, id) => {
                    if (id >= halfLengthOfFeatures) {
                      if (isNaN(item)) {
                        return (
                          <div
                            key={id}
                            className="checkout-tickets__top__right__attribute__right"
                          >
                            {/* <Checkmark /> */}
                            {item}
                          </div>
                        )
                      }
                    } else {
                      return null
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="checkout-tickets__bottom">
            <div className="checkout-tickets__bottom__line"></div>

            <div className="checkout-tickets__bottom__price">
              <div className="checkout-tickets__bottom__price__left">
                Ticket Price
              </div>
              <div className="checkout-tickets__bottom__price__right">
                ${priceWithTwoDecimalPlaces}
              </div>
            </div>
            <div className="checkout-tickets__bottom__tax">
              {/* ${parseFloat(card.price/100).toFixed(2)} USD */}
              <div className="checkout-tickets__bottom__tax__left">
                Processing Fee (8%)
              </div>
              <div className="checkout-tickets__bottom__tax__right">
                ${platformFeeWithTwoDecimalPlaces}
              </div>
            </div>
          </div>
        </div>
      )

      // const handleProductList = productList.map((product, id) => {
      //   const { title, price, date, platformFee } = product

      //   const totalPriceOfProduct = (price + platformFee) / 100
      //   const actualPrice = price / 100
      //   const actualPlatformFee = platformFee / 100

      //   const totalPriceOfProductWithTwoDecimalPlaces = parseFloat(
      //     totalPriceOfProduct
      //   ).toFixed(2)
      //   const priceWithTwoDecimalPlaces = parseFloat(actualPrice).toFixed(2)
      //   const platformFeeWithTwoDecimalPlaces = parseFloat(
      //     actualPlatformFee
      //   ).toFixed(2)

      //   return (
      //     <div key={id} className="choose-payment-method__billing">
      //       <div className="choose-payment-method__billing__billing-header">
      //         <div className="choose-payment-method__billing__billing-header__title">
      //           {title}
      //         </div>
      //         <div className="choose-payment-method__billing__billing-header__date">
      //           {date}
      //         </div>
      //       </div>
      //       <div className="choose-payment-method__billing__products">
      //         <div className="choose-payment-method__billing__products__checkout">
      //           Checkout
      //         </div>
      //         <div className="choose-payment-method__billing__products__line-skinny"></div>
      //         <div className="choose-payment-method__billing__products__item">
      //           <p className="choose-payment-method__billing__products__item__item-name">
      //             Ticket
      //           </p>
      //           <span className="choose-payment-method__billing__products__item__price">
      //             ${priceWithTwoDecimalPlaces}
      //           </span>
      //         </div>
      //         <div className="choose-payment-method__billing__products__item">
      //           <p className="choose-payment-method__billing__products__item__item-name">
      //             Platform Fee
      //           </p>
      //           <span className="choose-payment-method__billing__products__item__price">
      //             ${platformFeeWithTwoDecimalPlaces}
      //           </span>
      //         </div>
      //         {/* <div className="choose-payment-method__billing__products__line-skinny"></div> */}
      //         <div className="choose-payment-method__billing__products__total">
      //           {/* <p className="choose-payment-method__billing__products__total__item-name-total">
      //             Price
      //           </p>
      //           <span className="choose-payment-method__billing__products__total__price">
      //             ${totalPriceOfProductWithTwoDecimalPlaces}
      //           </span> */}
      //         </div>
      //       </div>
      //     </div>
      //   )
      // })
      // return handleProductList
    } else if (history.location.state.subscription) {
      const { title, cost, features } = history.location.state.subscription
      setGrandTotal(cost)
      const halfLengthOfFeatures = features.length / 2
      return (
        <div className="choose-payment-method__billing">
          <div className="choose-payment-method__billing__billing-header">
            <div className="choose-payment-method__billing__billing-header__title">
              {title}
            </div>
            <div className="choose-payment-method__billing__billing-header__date">
              ${cost / 100} per month
            </div>
          </div>
          <div className="choose-payment-method__billing__products">
            <div className="choose-payment-method__billing__products__item"></div>
            <div className="choose-payment-method__billing__products__line-skinny"></div>
            <div className="choose-payment-method__billing__products__attributes">
              <div className="choose-payment-method__billing__products__attributes__left">
                {features.map((item, id) => {
                  if (id < halfLengthOfFeatures) {
                    if (isNaN(item)) {
                      return (
                        <div
                          key={id}
                          className="choose-payment-method__billing__products__attributes__left"
                        >
                          <Checkmark />
                          {item}
                        </div>
                      )
                    }
                  } else {
                    return
                  }
                })}
              </div>
              <div className="choose-payment-method__billing__products__attributes__right">
                {features.map((item, id) => {
                  if (id >= halfLengthOfFeatures) {
                    if (isNaN(item)) {
                      return (
                        <div
                          key={id}
                          className="choose-payment-method__billing__products__attributes__right"
                        >
                          <Checkmark />
                          {item}
                        </div>
                      )
                    }
                  } else {
                    return
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const calculateTotal = () => {
    if (history.location.state) {
      if (history.location.state.products) {
        // const productList = history.location.state.products
        let total = []
        // const productTotal = productList.map((product) => {
        //   let prices = product.platformFee + product.price
        //   total = total.concat(prices)
        //   return total
        // })
        const grandTotal = total.reduce((a, b) => a + b, 0)
        setGrandTotal(grandTotal)
      } else {
        return
      }
    } else {
      return
    }
  }

  useEffect(() => {
    calculateTotal()
    return () => {}
  }, [])

  const DisplayGrandTotal = () => {
    const actualGrandTotal = grandTotal
    var grandTotalWithTwoDecimalPlaces = parseFloat(actualGrandTotal).toFixed(2)
    return (
      <div className="choose-payment-method__billing__products">
        <div className="choose-payment-method__billing__products__line"></div>
        <div className="choose-payment-method__billing__products__total">
          {/* <hr /> */}
          <p className="choose-payment-method__billing__products__total__item-name-total">
            Total
          </p>
          <span className="choose-payment-method__billing__products__total__price">
            ${grandTotalWithTwoDecimalPlaces}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="choose-payment-method-container">
      {successMsg ? (
        <>
          <SuccessMessage />
        </>
      ) : (
        <div className="choose-payment-method">
          {history.location.state ? (
            <>
              <HandleProducts />
              <DisplayGrandTotal />
            </>
          ) : (
            <div>No Products Selected</div>
          )}

          <div className="choose-payment-method__form">
            <div className="choose-payment-method__form__options-title">
              <div className="choose-payment-method__form__options-title__card-options">
                Card Options
                <img alt="cards" src={AllFourCards} />
                {/* <img src={MasterCard} />
                <div className="choose-payment-method__form__options-title__card-options__cards">
                <img src={AmericanExpress} />
                <img src={DiscoverCard} />

                </div> */}
              </div>
              <div className="choose-payment-method__form__options-title__lock">
                <LockOutline />
              </div>
            </div>
            <div className="choose-payment-method__form__table">
              <div className="choose-payment-method__form__table__title">
                <div className="choose-payment-method__form__table__title__space-holder"></div>
                <div className="choose-payment-method__form__table__title__cards">
                  Last 4
                </div>
                <div className="choose-payment-method__form__table__title__name">
                  Type
                </div>
                <div className="choose-payment-method__form__table__title__expires">
                  Expires
                </div>
              </div>
              <div className="choose-payment-method__form__table__checkbox">
                {cardData.map((data, id) => {
                  return (
                    <div
                      title="Select Card"
                      className="choose-payment-method__form__table__checkbox__card"
                      key={id}
                    >
                      <input
                        key={`payment-option${id}`}
                        type="checkbox"
                        onChange={(val) => onChangeHandler(val, data)}
                        checked={data.isChecked}
                        id={`payment-option${id}`}
                        className="choose-payment-method__form__table__checkbox__card__input"
                      />
                      <label
                        className="choose-payment-method__form__table__checkbox__card__label"
                        htmlFor={`payment-option${id}`}
                      >
                        <div className="choose-payment-method__form__table__checkbox__card__label__last4">
                          {data.lastFour}
                        </div>
                        <div className="choose-payment-method__form__table__checkbox__card__label__name">
                          {data.brand}
                        </div>
                        <div className="choose-payment-method__form__table__checkbox__card__label__expiration">
                          {data.expMonth}/{data.expYear}
                        </div>
                        {/* <div className="choose-payment-method__form__table__checkbox__card__label__garbage">
                          <div
                            title="Delete Card"
                            onClick={() => setDeleteWarningIsOpen(true)}
                            className="choose-payment-method__form__table__checkbox__card__label__garbage__delete"
                          >
                            Delete
                          </div>
                          <Modal
                            open={deleteWarningIsOpen}
                            onClose={() => setDeleteWarningIsOpen(false)}
                          >
                            <DeleteWarningMessage data={data} id={id} />
                          </Modal>
                        </div> */}
                      </label>
                    </div>
                  )
                })}
              </div>
              <div className="choose-payment-method__form__add-payment-option">
                <button
                  className="choose-payment-method__form__add-payment-option__button"
                  type="button"
                  onClick={() => handleAddCardBtn()}
                >
                  {/* <CreditCardPlus /> */}
                  ADD CARD
                </button>
              </div>
              <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <Elements stripe={stripePromise}>
                  <AddCardToStripe
                    cardId={chosenCard.cardId}
                    isCardUpdate={isCardUpdate}
                    setIsCardUpdate={setIsCardUpdate}
                    onClose={() => setIsOpen(false)}
                  />
                </Elements>
              </Modal>
            </div>
          </div>
          <form
            className="choose-payment-method__form"
            onSubmit={handleSubmitChoosePayment}
          >
            {paymentErrorMsg && (
              <ErrorMessageBox>{paymentErrorMsg}</ErrorMessageBox>
            )}
            {showUpdateCard ? (
              <div
                style={{
                  marginBottom: "20px",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => handleUpdateCard(cardData, chosenCard.cardId)}
              >
                Click here to update card
              </div>
            ) : null}
            <div className="choose-payment-method__form__use-payment-method">
              <LoaderButton
                block
                type="submit"
                bssize="large"
                isLoading={isProcessing}
                disabled={!chosenCard.isChecked ? true : false}
                className="choose-payment-method__form__user-payment-method__button"
              >
                PURCHASE
              </LoaderButton>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

// # PaymentMethod Object:

// {
//   "id": "pm_1IWWRr2eZvKYlo2C5L5hFZFo", //A6 stripe cloud saves the card info and user info submitted and returns a
//                                        ///////CARD_TOKEN = card_a4f923gf9u3fg
//   "object": "payment_method",
//   "billing_details": {
//     "address": {
//       "city": "Abbotsford",
//       "country": "CA",
//       "line1": "32497 South Fraser Way",
//       "line2": null,
//       "postal_code": "V2T 1X4",
//       "state": "British Columbia"
//     },
//     "email": "jenny@example.com",
//     "name": null,
//     "phone": "+15555555555"
//   },

//   //B3. 3. web-client request from sls-api , current payment methods aka current
//   //status: live cards
//   "card": {
//     "brand": "visa",
//     "checks": {
//       "address_line1_check": null,
//       "address_postal_code_check": null,
//       "cvc_check": "pass"    //A3
//     },
//     "country": "US",
//     "exp_month": 8,
//     "exp_year": 2022,
//     "fingerprint": "Xt5EWLLDS7FJjR1c",
//     "funding": "credit",
//     "generated_from": null,
//     "last4": "4242",
//     "networks": {
//       "available": [
//         "visa"
//       ],
//       "preferred": null
//     },
//     "three_d_secure_usage": {
//       "supported": true
//     },
//     "wallet": null
//   },
//   "created": 123456789,
//   "customer": null,
//   "livemode": false,
//   "metadata": {
//     "order_id": "123456789"
//   },
//   "type": "card"
// }
