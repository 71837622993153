const hardCodeTipsDev = [
	{
		bandName: "Horrendous",
		description: "Extreme!: Horrendous - Band Tip $1",
		price: "100",
		priceId: "price_1IlgycKsNNk3qPPU1jUKujxp"
	},
	{
		bandName: "Horrendous",
		description: "Extreme!: Horrendous - Band Tip $5",
		price: "500",
		priceId: "price_1Ilh0uKsNNk3qPPUe7jPmYQC"
	},
	{
		bandName: "Horrendous",
		description: "Extreme!: Horrendous - Band Tip $10",
		price: "1000",
		priceId: "price_1Ilh4ZKsNNk3qPPUzO7vvGvs"
	},
	{
		bandName: "Horrendous",
		description: "Extreme!: Horrendous - Band Tip $20",
		price: "2000",
		priceId: "price_1Ilh8AKsNNk3qPPUdxmVFvOc"
	},
	{
		bandName: "Horrendous",
		description: "Extreme!: Horrendous - Band Tip $50",
		price: "5000",
		priceId: "price_1IlhAHKsNNk3qPPUZNEVawAu"
	},
	{
		bandName: "Horrendous",
		description: "Extreme!: Horrendous - Band Tip $100",
		price: "10000",
		priceId: "price_1IlhBXKsNNk3qPPUfhkc6VBe"
	},
	// -- Wake
	{
		bandName: "Wake",
		description: "Extreme!: Wake - Band Tip $1",
		price: "100",
		priceId: "price_1IlhGjKsNNk3qPPU1nhTKG9x"
	},
	{
		bandName: "Wake",
		description: "Extreme!: Wake - Band Tip $5",
		price: "500",
		priceId: "price_1IlhH8KsNNk3qPPUwQlYMdgo"
	},
	{
		bandName: "Wake",
		description: "Extreme!: Wake - Band Tip $10",
		price: "1000",
		priceId: "price_1IlhHeKsNNk3qPPUbeNOyD5l"
	},
	{
		bandName: "Wake",
		description: "Extreme!: Wake - Band Tip $20",
		price: "2000",
		priceId: "price_1IlhIlKsNNk3qPPUYtNTwQAF"
	},
	{
		bandName: "Wake",
		description: "Extreme!: Wake - Band Tip $50",
		price: "5000",
		priceId: "price_1IlhJFKsNNk3qPPUQRL5hiw7"
	},
	{
		bandName: "Wake",
		description: "Extreme!: Wake - Band Tip $100",
		price: "10000",
		priceId: "price_1IlhJaKsNNk3qPPUPqf63fFF"
	},
	// -- Full of Hell
	{
		bandName: "Full Of Hell",
		description: "Extreme!: Full Of Hell - Band Tip $1",
		price: "100",
		priceId: "price_1IlhKsKsNNk3qPPUSBic1Pa6"
	},
	{
		bandName: "Full Of Hell",
		description: "Extreme!: Full Of Hell - Band Tip $5",
		price: "500",
		priceId: "price_1IlhLGKsNNk3qPPUVsijt4qw"
	},
	{
		bandName: "Full Of Hell",
		description: "Extreme!: Full Of Hell - Band Tip $10",
		price: "1000",
		priceId: "price_1IlhLbKsNNk3qPPUBNmtFZXU"
	},
	{
		bandName: "Full Of Hell",
		description: "Extreme!: Full Of Hell - Band Tip $20",
		price: "2000",
		priceId: "price_1IlhLzKsNNk3qPPU0irMihJJ"
	},
	{
		bandName: "Full Of Hell",
		description: "Extreme!: Full Of Hell - Band Tip $50",
		price: "5000",
		priceId: "price_1IlhMIKsNNk3qPPUZTwpqPUi"
	},
	{
		bandName: "Full Of Hell",
		description: "Extreme!: Full Of Hell - Band Tip $100",
		price: "10000",
		priceId: "price_1IlhMdKsNNk3qPPU5tBmzr0m"
	},
	// -- MidNight
	{
		bandName: "Midnight",
		description: "Extreme!: Midnight - Band Tip $1",
		price: "100",
		priceId: "price_1IlhPxKsNNk3qPPUuDoP419N"
	},
	{
		bandName: "Midnight",
		description: "Extreme!: Midnight - Band Tip $5",
		price: "500",
		priceId: "price_1IlhQbKsNNk3qPPUi23SjICp"
	},
	{
		bandName: "Midnight",
		description: "Extreme!: Midnight - Band Tip $10",
		price: "1000",
		priceId: "price_1IlhR0KsNNk3qPPUy7Fb4AOa"
	},
	{
		bandName: "Midnight",
		description: "Extreme!: Midnight - Band Tip $20",
		price: "2000",
		priceId: "price_1IlhROKsNNk3qPPUiTCo706N"
	},
	{
		bandName: "Midnight",
		description: "Extreme!: Midnight - Band Tip $50",
		price: "5000",
		priceId: "price_1IlhRuKsNNk3qPPURoFLUXQu"
	},
	{
		bandName: "Midnight",
		description: "Extreme!: Midnight - Band Tip $100",
		price: "10000",
		priceId: "price_1IlhSFKsNNk3qPPU3BKVGHp9"
	},
	// -- Khemmis
	{
		bandName: "Khemmis",
		description: "Extreme!: Khemmis - Band Tip $1",
		price: "100",
		priceId: "price_1IlhSaKsNNk3qPPUE0MWGv4I"
	},
	{
		bandName: "Khemmis",
		description: "Extreme!: Khemmis - Band Tip $5",
		price: "500",
		priceId: "price_1IlhSuKsNNk3qPPUbz4vDPwl"
	},
	{
		bandName: "Khemmis",
		description: "Extreme!: Khemmis - Band Tip $10",
		price: "1000",
		priceId: "price_1IlhTFKsNNk3qPPURbEuiKIv"
	},
	{
		bandName: "Khemmis",
		description: "Extreme!: Khemmis - Band Tip $20",
		price: "2000",
		priceId: "price_1IlhTZKsNNk3qPPUTgeyvv9h"
	},
	{
		bandName: "Khemmis",
		description: "Extreme!: Khemmis - Band Tip $50",
		price: "5000",
		priceId: "price_1IlhTtKsNNk3qPPUqkZ982Px"
	},
	{
		bandName: "Khemmis",
		description: "Extreme!: Khemmis - Band Tip $100",
		price: "10000",
		priceId: "price_1IlhUEKsNNk3qPPUvwYIi3Sc"
	}
]
const hardCodeTipsProd = [
	{
		priceId: "price_1IlkALKsNNk3qPPUP277ljQ4",
		bandName: "Horrendous",
		price: "100",
		description: "Extreme!: Horrendous - Band Tip $1",
	},
	{
		priceId: "price_1IlkAiKsNNk3qPPUyyiZF18R",
		bandName: "Horrendous",
		price: "500",
		description: "Extreme!: Horrendous - Band Tip $5",
	},
	{
		priceId: "price_1IlkADKsNNk3qPPUiLkyG40M",
		bandName: "Horrendous",
		price: "1000",
		description: "Extreme!: Horrendous - Band Tip $10",
	},
	{
		priceId: "price_1IlkA9KsNNk3qPPUAQfdRO08",
		bandName: "Horrendous",
		price: "2000",
		description: "Extreme!: Horrendous - Band Tip $20",
	},
	{
		priceId: "price_1IlkA5KsNNk3qPPUeOgWDjEr",
		bandName: "Horrendous",
		price: "5000",
		description: "Extreme!: Horrendous - Band Tip $50",
	},
	{
		priceId: "price_1IlkA1KsNNk3qPPU7BcQQMcK",
		bandName: "Horrendous",
		price: "10000",
		description: "Extreme!: Horrendous - Band Tip $100",
	},
	{
		priceId: "price_1Ilk9xKsNNk3qPPUOcYV7GxQ",
		bandName: "Wake",
		price: "100",
		description: "Extreme!: Wake - Band Tip $1",
	},
	{
		priceId: "price_1Ilk9rKsNNk3qPPUoQPDsja0",
		bandName: "Wake",
		price: "500",
		description: "Extreme!: Wake - Band Tip $5",
	},
	{
		priceId: "price_1Ilk9kKsNNk3qPPUJfjdZcV1",
		bandName: "Wake",
		price: "1000",
		description: "Extreme!: Wake - Band Tip $10",
	},
	{
		priceId: "price_1Ilk9hKsNNk3qPPUaC5xPYfP",
		bandName: "Wake",
		price: "2000",
		description: "Extreme!: Wake - Band Tip $20",
	},
	{
		priceId: "price_1Ilk9VKsNNk3qPPUhJv5sAwq",
		bandName: "Wake",
		price: "5000",
		description: "Extreme!: Wake - Band Tip $50",
	},
	{
		priceId: "price_1Ilk9QKsNNk3qPPUNl9kx8va",
		bandName: "Wake",
		price: "10000",
		description: "Extreme!: Wake - Band Tip $100",
	},
	{
		priceId: "price_1Ilk9LKsNNk3qPPUNMNGBFOq",
		bandName: "Full Of Hell",
		price: "100",
		description: "Extreme!: Full Of Hell - Band Tip $1",
	},
	{
		priceId: "price_1Ilk99KsNNk3qPPUUBZI2Hfq",
		bandName: "Full Of Hell",
		price: "500",
		description: "Extreme!: Full Of Hell - Band Tip $5",
	},
	{
		priceId: "price_1Ilk8oKsNNk3qPPUeGb68B4C",
		bandName: "Full Of Hell",
		price: "1000",
		description: "Extreme!: Full Of Hell - Band Tip $10",
	},
	{
		priceId: "price_1Ilk8kKsNNk3qPPUws2B4dJ3",
		bandName: "Full Of Hell",
		price: "2000",
		description: "Extreme!: Full Of Hell - Band Tip $20",
	},
	{
		priceId: "price_1Ilk8eKsNNk3qPPUJFz9d47H",
		bandName: "Full Of Hell",
		price: "5000",
		description: "Extreme!: Full Of Hell - Band Tip $50",
	},
	{
		priceId: "price_1Ilk8YKsNNk3qPPUAcpSRSnj",
		bandName: "Full Of Hell",
		price: "10000",
		description: "Extreme!: Full Of Hell - Band Tip $100",
	},
	{
		priceId: "price_1Ilk8UKsNNk3qPPUR19zeFCY",
		bandName: "Midnight",
		price: "100",
		description: "Extreme!: Midnight - Band Tip $1",
	},
	{
		priceId: "price_1Ilk8OKsNNk3qPPUzVbxgw4q",
		bandName: "Midnight",
		price: "500",
		description: "Extreme!: Midnight - Band Tip $5",
	},
	{
		priceId: "price_1Ilk8FKsNNk3qPPUa6wwoD9g",
		bandName: "Midnight",
		price: "1000",
		description: "Extreme!: Midnight - Band Tip $10",
	},
	{
		priceId: "price_1Ilk80KsNNk3qPPUOTBLmrvh",
		bandName: "Midnight",
		price: "2000",
		description: "Extreme!: Midnight - Band Tip $20",
	},
	{
		priceId: "price_1IljUJKsNNk3qPPU9XntjC7o",
		bandName: "Midnight",
		price: "5000",
		description: "Extreme!: Midnight - Band Tip $50",
	},
	{
		priceId: "price_1IljUBKsNNk3qPPUhDvclWvH",
		bandName: "Midnight",
		price: "10000",
		description: "Extreme!: Midnight - Band Tip $100",
	},
	{
		priceId: "price_1Ilk7dKsNNk3qPPU1QIo2pMb",
		bandName: "Khemmis",
		price: "100",
		description: "Extreme!: Khemmis - Band Tip $1",
	},
	{
		priceId: "price_1IljU6KsNNk3qPPU0AiuDR2k",
		bandName: "Khemmis",
		price: "500",
		description: "Extreme!: Khemmis - Band Tip $5",
	},
	{
		priceId: "price_1IljTeKsNNk3qPPURvCDpega",
		bandName: "Khemmis",
		price: "1000",
		description: "Extreme!: Khemmis - Band Tip $10",
	},
	{
		priceId: "price_1IljTZKsNNk3qPPU5W2cwB7J",
		bandName: "Khemmis",
		price: "2000",
		description: "Extreme!: Khemmis - Band Tip $20",
	},
	{
		priceId: "price_1IljTWKsNNk3qPPUwElWTlAC",
		bandName: "Khemmis",
		price: "5000",
		description: "Extreme!: Khemmis - Band Tip $50",
	},
	{
		priceId: "price_1IljTNKsNNk3qPPUep8BppFQ",
		bandName: "Khemmis",
		price: "10000",
		description: "Extreme!: Khemmis - Band Tip $100",
	}
]
const tipExport = process.env.REACT_APP_ENVIRONMENT === 'production' ? hardCodeTipsProd : hardCodeTipsDev

export default tipExport;