import React, {useEffect, useState} from "react"
// import { createEmitAndSemanticDiagnosticsBuilderProgram } from "typescript"
// import fakeImage from "../../images/landing.jpeg"
// import barCode from "../../images/CouchTourLogoRed.png"
import realBarCode from "../../images/barCode.svg"
// import { Card } from "react-bootstrap"
// import { useHistory } from "react-router"
import { useAppContext } from "../../libs/contextLib"
import { Link } from "react-router-dom"
// import { useSocket } from "../../libs/SocketProvider"
import { Auth } from "aws-amplify"


// import decibelLanding from "../../images/decibelLanding.jpeg"
// import decibelPoster from "../../images/decibelPoster.png"

// import decibelFavicon from "../../../public/images/decibel-header-logo.png"

// const headerImageLogo = "/decibel-header-logo.png"

// const handleYourTicketsButton = (history) => {
//   history.push("/your-tickets")
// }
// const handleGetTicketsButton = (history) => {
//   history.push("/tickets")
// }

const YourTickets = () => {
  const { currentUserEmail, cogId } = useAppContext()
  const [localCogId, setLocalCogId] = useState("")
  const [userEmail, setUserEmail] = useState("")


  console.log("[28] currentUserEmail, cogId", currentUserEmail, cogId)
  // const { productList, setProductList, socketOpen } = useAppContext()
  // const history = useHistory()
  // const socket = useSocket()
  const handleCogId = async () => {
    try {
      const credentials = await Auth.currentUserCredentials()
      const cogIdTemp = credentials.identityId
      setLocalCogId(cogIdTemp)

      Auth.currentSession().then((data) => {
        const email = data.idToken.payload.email
        setUserEmail(email)
      })
    } catch (error) {
      console.log("[69] handleCogID error", JSON.stringify(error))
    }
  }

  useEffect(() => {
    handleCogId()
    return () => {}
  })
  // get-users-tickets

  // useEffect(() => {
  //   if (socketOpen) {
  //     socket.send(JSON.stringify({ action: "get-user-tickets" }))
  //     socket.onmessage = async (message) => {
  //       const socketRespData = JSON.parse(message.data)
  //       const socketRespAction = socketRespData.action
  //       if (socketRespData.action === "get-all-events-resp-success") {
  //         // const events = JSON.parse(message.data.message.Items)
  //         // const events = socketRespData.message.items
  //         // console.log("[50] socketrespdata get all tickets", events)

  //         // setEvents(events)
  //         console.log("[53] socketrespdata get all events", socketRespData)
  //         // const events = socketRespData.events
  //         // const eventId = events.map((event) => {
  //         //   return event.eventId
  //         // })
  //         // console.log("[58]  events, event_id", events, eventId)
  //         // setEvents(events)
  //         // setEventId(eventId)
  //         // } else if (socketRespData.action === "get-all-tickets-resp-success") {
  //         //   console.log("[55] get all tickets", socketRespData)
  //       } else {
  //         console.log("[55]err", socketRespData)
  //       }
  //     }
  //   }
  //   return () => {}
  // }, [socketOpen])

  // const numOfTickets = history.location?.state?.tickets.length

  // const getPurchasePayload = {
  //   action: "get-tickets",
  //   message: { email: userEmail, cogId:localCogId },
  // }


  // socket.send(JSON.stringify(getPurchasePayload))
  // socket.onmessage = async (message) => {
  //   const socketRespData = JSON.parse(message.data)
  //   const socketRespAction = socketRespData.action
  //   const socketRespMessage = socketRespData.message

  //   if (socketRespAction === "get-tickets-resp-success") {


  //   }

  // get-tickets-resp-success


  const numOfTickets = 0
  const eventData = []

  return (
    <div className="your-tickets">
      <div className="your-tickets__top">
        <div className="your-tickets__top__left">
          {/* <div className="your-tickets__top__left__global-artists">
            Your Tickets [{numOfTickets}]
          </div> */}
          {/* <div className="your-tickets__top__left__see-all">See all</div> */}
        </div>
      </div>
      <div className="your-tickets__bottom ">
        {eventData.map((card, id) => {
          return (
            <div key={id} className="your-tickets__bottom__card">
              <Link
                style={{ textDecoration: "none" }}
                to="/view-videos"
                className="your-tickets__bottom__card__left"
              >
                <div
                  className={
                    card.isLive
                      ? "your-tickets__bottom__card__left__top card-live"
                      : "your-tickets__bottom__card__left__top card-not-live"
                  }
                >
                  <img alt="" src={card.image} />
                </div>
                <div
                  className={
                    card.isLive
                      ? "your-tickets__bottom__card__left__bottom isLive"
                      : "your-tickets__bottom__card__left__bottom notLive"
                  }
                >
                  {card.isLive ? (
                    <div className="your-tickets__bottom__card__left__bottom__live-logo">
                      LIVE
                    </div>
                  ) : null}
                  <div className="your-tickets__bottom__card__left__bottom__couchtour">
                    {/* <img src={headerImageLogo} /> */}
                    <div className="your-tickets__bottom__card__left__bottom__couchtour__text">
                      {card.promoter} Presents
                    </div>
                  </div>
                  <div
                    className={
                      card.isLive
                        ? "your-tickets__bottom__card__left__bottom__band band-live"
                        : "your-tickets__bottom__card__left__bottom__band band-not-live"
                    }
                  >
                    {card.bandName}
                  </div>
                  <div
                    className={
                      card.isLive
                        ? "your-tickets__bottom__card__left__bottom__live"
                        : "your-tickets__bottom__card__left__bottom__date"
                    }
                  >
                    {card.isLive ? "LIVE NOW!" : `${card.timeOfEvent}`}
                  </div>
                  <div className="your-tickets__bottom__card__left__bottom__circles">
                    <div className="your-tickets__bottom__card__left__bottom__circles__left"></div>
                    <div className="your-tickets__bottom__card__left__bottom__circles__right"></div>
                  </div>
                  <div className="your-tickets__bottom__card__left__bottom__ticket-name">
                    {card.title}
                  </div>
                  <div className="your-tickets__bottom__card__left__bottom__ticket-price">
                    {/* ${card.price} USD */}
                  </div>
                  <div className="your-tickets__bottom__card__left__bottom__bar-code">
                    <img alt="" src={realBarCode} />
                  </div>
                  <div className="your-tickets__bottom__card__left__bottom__admit-one">
                    ADMIT ONE
                  </div>

                  {/* <div
                    className="your-tickets__bottom__card__left__bottom__get-tickets"
                    onClick={() => handleGetTicketsButton(history)}
                  >
                    GET TICKETS
                    <img src={realBarCode} />
                  </div> */}
                </div>
              </Link>
              <div className="your-tickets__bottom__card__right"></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default YourTickets
