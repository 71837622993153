import React, { useState, useEffect } from "react"
import { Auth } from "aws-amplify"
import { Link, useHistory, useLocation } from "react-router-dom"
import { FormGroup, FormControl, FormLabel } from "react-bootstrap"
import LoaderButton from "../components/LoaderButton"
import { useAppContext } from "../libs/contextLib"
import { useFormFields } from "../libs/hooksLib"
import { onError } from "../libs/errorLib"
import { routeException } from "../libs/routeException"
import "./Signup.css"
import { ErrorMessageBox } from "../components/ErrorMessageBox"

// AWS.AMPLIFY AUTH COMMANDS FOR VERIFICATION
// Auth.userAttributes(currentUser)
// attributes["email_verified"] !== "false"
// Auth.verifyCurrentUserAttribute("email")
// Auth.verifyCurrentUserAttributeSubmit("email", this.state.code)

export default function ConfirmAccount() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  })
  const location = useLocation()
  console.log("[26] location", location)
  const history = useHistory()
  const [newUser, setNewUser] = useState({
    username: location.customNameData,
    customNameData: location.customNameData,
  })
  const {
    // isAuthenticated,
    setIsAuthenticated,
    // currentUser,
    // setCurrentUser,
  } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(
    (newUser) => {
      // console.log("[38] ConfirmAccount.js ---- USE_EFFECT - HOOK"); // result: '/secondpage'
      // console.log(location.pathname); // result: '/secondpage'
      // console.log(location.customNameData); // result: '?query=abc'
      // console.log(location.username); // result: 'some_value'
      setNewUser({
        username: location.customNameData,
        customNameData: location.customNameData,
      })
    },
    [location]
  )
  console.log("[49] new user: ", newUser) // result: 'some_value'

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(location.customNameData)
      alert("Check your email for Couchtour.TV verification code")
    } catch (err) {
      // console.log('Error resending verification email: ', location.customNameData);
      // console.log("New User", newUser);
      // console.log('Error verification email: ', err);
      // history.push("/login");
      // history.push('/confirm_account', { customNameData: location.customNameData });
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault()
    setIsLoading(true)

    // console.log("[61] FIELDS CONF:", location.customNameData, fields);

    let username = fields.email.toLowerCase()
    if (location.customNameData) {
      username = location.customNameData
    }
    setNewUser({ username: username, customNameData: location.customNameData })
    // console.log("[69] NEW USER:handleSubmit", username, newUser, AppContext);

    try {
      await Auth.confirmSignUp(username, fields.confirmationCode)
      console.log("[87] confirmSignup success")

      await Auth.signIn(username, location.customFormData)
        .then((user) => console.log("[199] Auth.signin user", user))
        .catch((err) => {
          onError(err)
          setErrorMessage(err.message)
          setIsLoading(false)
          history.push("/login")
          console.log("[200] Auth.signin err", err)
        })

      // await Auth.signIn(username, fields.password);
      setIsAuthenticated(true)
      history.push("/")
      // console.log("NEW USER:handleConfirmationSubmit", newUser, AppContext );
    } catch (e) {
      const routeExceptions = routeException(e)
      const route = routeExceptions.route

      // console.log('[83]HERE IS ROUTE RESPONSE', route , { customNameData:  username } );
      if (route === "/") {
        // # user is already confirmed skip this code and log in
        // setIsAuthenticated(true);
        history.push("/")
      } else {
        setIsLoading(false)
        // console.log('[90] HERE IS THE ERROR', e.code , e );
        onError(e)
        if (route !== null) {
          history.push({ pathname: route, customNameData: username })
        }
      }
    }
  }

  function renderConfirmationForm() {
    return (
      <div className="confirm-account">
        {errorMessage && <ErrorMessageBox>{errorMessage}</ErrorMessageBox>}
        <form onSubmit={handleConfirmationSubmit}>
          <FormGroup controlId="confirmationCode" bssize="large">
            <FormLabel>Please Check your email for the code.</FormLabel>
            <FormLabel>
              To resend confirmation code
              <Link className="confirm-account-link-blue" to="/confirm-account" onClick={resendConfirmationCode}>
                {" "}
                Click Here
              </Link>
            </FormLabel>
            <FormLabel>Confirmation Code</FormLabel>
            <FormControl
              autoFocus
              type="tel"
              onChange={handleFieldChange}
              value={fields.confimationCode}
            />
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bssize="large"
            isLoading={isLoading}
            disabled={!validateConfirmationForm()}
            className="login-button"
          >
            Verify
          </LoaderButton>
        </form>
      </div>
    )
  }

  return <div className="Signup">{renderConfirmationForm()}</div>
}
