import React, { useEffect } from "react"
import { useHistory } from "react-router"
// import { details } from "../../store/actions/watch"
// import { ChoosePaymentMethod } from "../ChoosePaymentMethod"

import { ReactComponent as CoffeeCup } from "../../images/coffeeCup.svg"
import { ReactComponent as MoneySign } from "../../images/moneySign.svg"
import { ReactComponent as Flag } from "../../images/flag.svg"

// import fakeImage from "../../images/musicalHeart.jpg"
// import { useAppContext } from "../../libs/contextLib"
// import { useSocket } from "../../libs/SocketProvider"
// import { alignPropType } from "react-bootstrap/esm/DropdownMenu"

// import decibelLanding from "../../images/decibelLanding.jpeg"
// import decibelPoster from "../../images/decibelPoster.png"

import StripeCheckout from "../StripeCheckout/StripeCheckout"

import ticketData from "../../hoc/hardCodeTickets"

const displayIcon = (type) => {
  if (type === "coffee") {
    return <CoffeeCup />
  } else if (type === "money") {
    return <MoneySign />
  } else {
    return <Flag />
  }
}

export default function Tickets() {
  // const { productList, setProductList, socketOpen } = useAppContext()
  const history = useHistory()
  // const socket = useSocket()
  useEffect(() => {
    if (!history.location.state) {
      history.push("/for-sale")
    }
    return () => {}
  })

  const {
    // eventId,
    eventDetail,
    eventFeature,
    priceDescription,
    landingImageUrl,
    // bandName,
    // ticketDate,
    // eventName,
  } = history.location.state

  // const listOfDetails = [eventDetail, eventFeature, priceDescription]
  // const [productCount, setProductCount] = useState([])
  // const [tickets, setTickets] = useState([])

  // const handleOnClick = (priceId) => {
  //   history.push({
  //     pathname: "/stripe-checkout",
  //     state: {
  //       priceId,
  //     },
  //   })
  //   // history.push({
  //   //   pathname: "/checkout",
  //   //   state: {
  //   //     ticket: product,
  //   //     cost: cost * 100,
  //   //     item_id: item_id,
  //   //     bandName: bandName,
  //   //     ticketDate: ticketDate,
  //   //     eventName: eventName,
  //   //   },
  //   // })
  // }

  // useEffect(() => {
  //   if (socketOpen) {
  //     socket.send(JSON.stringify({ action: "get-all-events" }))
  //     socket.send(JSON.stringify({ action: "get-all-tickets" }))
  //     socket.onmessage = async (message) => {
  //       const socketRespData = JSON.parse(message.data)
  //       const socketRespAction = socketRespData.action
  //       if (socketRespData.action === "get-all-events-resp-success") {
  //         // const events = JSON.parse(message.data.message.Items)
  //         // const events = socketRespData.message.items
  //         // console.log("[50] socketrespdata get all tickets", events)

  //         // setEvents(events)
  //         console.log("[75] socketrespdata get all events", socketRespData)
  //         const events = socketRespData.events
  //         // const eventId = events.map(event => {
  //         //   return (
  //         //     event.eventId
  //         //   )
  //         // })
  //         // console.log("[82]  events, event_id", events, eventId)
  //       } else if (socketRespData.action === "get-all-tickets-resp-success") {
  //         console.log("[85] get all tickets", socketRespData)
  //         const allTickets = socketRespData.tickets
  //         if (allTickets) {
  //           const filtTickets = allTickets.filter((ticket) => {
  //             return ticket.eventId === eventId
  //           })
  //           console.log("[95]filteredTix", filtTickets)
  //           const sortedTix = filtTickets.sort((a, b) => b.price - a.price)
  //           setTickets(sortedTix)

  //           //   const tix = allTickets.map(ticket => {
  //           //     console.log("[94] ticket", ticket)

  //           //     let filteredTickets
  //           //     if(ticket.eventId === eventId) {
  //           //       filteredTickets = filteredTickets.concat(ticket)
  //           //     }
  //           //     return filteredTickets
  //           //   })
  //           //   console.log("[95] filtered ticket", tix)
  //           //   setTickets(tix)
  //         }
  //       } else {
  //         console.log("[88]err", socketRespData)
  //       }
  //     }
  //   }
  //   return () => {}
  // }, [socketOpen])

  // const handleCheckout = () => {
  //   // ChoosePaymentMethod(productList)
  //   history.push({
  //     pathname: "/checkout",
  //     state: { products: productList },
  //   })
  // }

  // const calcNumberOfProducts = () => {
  //   const listOfItemIds = productList.map((product) => {
  //     let listOfItemIds = []
  //     listOfItemIds = listOfItemIds.concat(product.item_id)
  //     return listOfItemIds
  //   })
  //   console.log("[67] listOfItemIds", listOfItemIds)
  //   var counts = {}
  //   listOfItemIds.forEach((x) => {
  //     counts[x] = (counts[x] || 0) + 1
  //   })
  //   console.log("[71] counts", counts)
  //   setProductCount(counts)
  // }

  // useEffect(() => {
  //   calcNumberOfProducts()
  //   return () => {}
  // }, [productList])

  const eventDataList = [eventDetail, eventFeature, priceDescription]
  return (
    <div className="tickets">
      <div className="tickets__top">
        <div className="tickets__top__left">
          <img alt="" src={landingImageUrl} />
        </div>
        <div className="tickets__top__right">
          {eventDataList
            ? eventDataList.map((detail, id) => {
                return (
                  <div key={id} className="tickets__top__right__details">
                    <div className="tickets__top__right__details__icon">
                      {displayIcon(detail.type)}
                    </div>
                    <div className="tickets__top__right__details__detail">
                      {detail.description}
                    </div>
                  </div>
                )
              })
            : null}
        </div>
      </div>
      <div className="tickets__bottom">
        <div className="tickets__bottom__title">Select Your Ticket</div>
        <div className="tickets__bottom__cards">
          {ticketData
            ? ticketData.map((card, id) => {
                return (
                  <div key={id} className="tickets__bottom__cards__card">
                    <div className="tickets__bottom__cards__card__title">
                      {card.title}
                    </div>
                    <div className="tickets__bottom__cards__card__price">
                      ${parseFloat(card.price / 100).toFixed(2)} USD
                    </div>
                    {card.price === "666" ? (
                      <div className="tickets__bottom__cards__card__no-shipping">
                      </div>
                    ) : (
                      <div className="tickets__bottom__cards__card__shipping">
                        + $10 shipping fee
                      </div>
                    )}
                    <div className="tickets__bottom__cards__card__image">
                      <img alt="" src={card.image} />
                    </div>
                    <div className="tickets__bottom__cards__card__boxed-items">
                      {card.boxedItems.map((item, id) => {
                        return (
                          <div
                            key={id}
                            className="tickets__bottom__cards__card__boxed-items__item"
                          >
                            {item}
                          </div>
                        )
                      })}
                    </div>
                    <ul className="tickets__bottom__cards__card__attributes">
                      {card.attributes.map((item, id) => {
                        return (
                          <li key={id}>
                            {/* <li className="tickets__bottom__cards__card__attributes__list__attribute"> */}
                            {item}
                          </li>
                        )
                      })}
                    </ul>
                    <div className="tickets__bottom__cards__card__bottom">
                      <div
                        className={
                          card.soldOut
                            ? "tickets__bottom__cards__card__bottom__left-soldout"
                            : "tickets__bottom__cards__card__bottom__left-available"
                        }
                      >
                        {card.soldOut ? "SOLD OUT!" : "Still Available!"}
                      </div>
                      <div className="tickets__bottom__cards__card__bottom__right">
                        <StripeCheckout buttonText="Checkout" priceId={card.priceId} card={card} />
                        {/* <button
                          onClick={() => handleOnClick(card.priceId)}
                          disabled={card.soldOut}
                        >
                          Buy
                        </button> */}
                        {/* <button
                          onClick={() =>
                            handleOnClick(
                              card,
                              card.price,
                              card.ticketId,
                              card.date
                            )
                          }
                          disabled={card.soldOut}
                        >
                          Buy
                        </button> */}
                      </div>
                    </div>
                  </div>
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}

// const eventData = [
//   {
//     ID: "24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     bandName: "200th Issue Show",
//     image: decibelPoster,
//     promoter: "Decibel Magazine",
//     promoterLogo: null,
//     timeOfEvent: "FRI/8:00 PM",
//     isLive: false,
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     landingImageUrl: "/dB200thIssueShow_logoRGB.png",
//     eventFeature: {
//       description: "200th Issue Show Extremely Ex-Stream",
//       type: "coffee",
//     },
//     priceDescription: {
//       description: "Tickets start at $6.66",
//       type: "money",
//     },
//     eventDetail: {
//       description: "Watch ",
//       type: "flag",
//     },
//   },
// ]

// const ticketData = [
//   {
//     ID: "f42298f7-bda8-4806-88ca-6dd1dc2ab985",
//     ticketId: "tic_f42298f7-bda8-4806-88ca-6dd1dc2ab985",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     title: "Decibel 200th: Extremely Ex-Stream",
//     price: "2424",
//     priceId: "price_1IlaqVKsNNk3qPPU1eKk7NOP",
//     image: heavyMetal_astronaut,
//     boxedItems: ["GA", "Free DVD", "T-SHIRT", "POSTER"],
//     attributes: [
//       "General Admission to Decibel 200th: Extremely Ex-Stream",
//       "Bonus DVD! The Decibel 200th Issue Show Extremely Ex-Stream, presented by Metal Blade Records.",
//       "Signed One-Time Offering Poster From The Label",
//       "Exclusive limited edition event T-shirt ",
//       "Available to ship within the U.S. and Canada only.",
//     ],
//     soldOut: false,
//   },
//   {
//     ID: "0b109cac-dba1-4ab3-a15f-f1ec64e580ad",
//     ticketId: "tic_0b109cac-dba1-4ab3-a15f-f1ec64e580ad",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     title: "Decibel 200th: Extremely Ex-Stream",
//     price: "1818",
//     priceId: "price_1Ilaq6KsNNk3qPPUDhxU9Yo6",
//     image: heavyMetal_fireSkull,
//     boxedItems: ["GA", "Free DVD", "T-SHIRT"],
//     attributes: [
//       "General Admission to Decibel 200th: Extremely Ex-Stream",
//       "Bonus DVD! The Decibel 200th Issue Show Extremely Ex-Stream, presented by Metal Blade Records.",
//       "Exclusive limited edition event T-shirt ",
//       "Available to ship within the U.S. and Canada only.",
//     ],
//     soldOut: false,
//   },
//   {
//     ID: "cfb84032-6618-447e-b9f5-665f1122f8f1",
//     ticketId: "tic_cfb84032-6618-447e-b9f5-665f1122f8f1",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     title: "Decibel 200th: Extremely Ex-Stream",
//     price: "1212",
//     priceId: "price_1IlapIKsNNk3qPPUoEGuf9DG",
//     image: heavyMetal_skeleton,
//     boxedItems: ["GA", "Free DVD"],
//     attributes: [
//       "General Admission to Decibel 200th: Extremely Ex-Stream",
//       "Bonus DVD! The Decibel 200th Issue Show Extremely Ex-Stream, presented by Metal Blade Records.",
//       "Available to ship within the U.S. and Canada only.",
//     ],
//     soldOut: false,
//   },
//   {
//     ID: "c73947fc-2a00-4518-ae35-090dad1fe9a4",
//     ticketId: "tic_c73947fc-2a00-4518-ae35-090dad1fe9a4",
//     eventId: "evt_24f60b47-f814-4ece-8d98-fee2ec4e5e54",
//     title: "Decibel 200th: Extremely Ex-Stream",
//     price: "666",
//     priceId: "price_1IlaoGKsNNk3qPPU3DzX68eW",
//     image: heavyMetal_dude,
//     boxedItems: ["GA"],
//     attributes: [
//       "General Admission to Decibel 200th: Extremely Ex-Stream",
//       "Bonus DVD! The Decibel 200th Issue Show Extremely Ex-Stream, presented by Metal Blade Records.",
//       "Available to ship within the U.S. and Canada only.",
//     ],
//     soldOut: false,
//   },
// ]

// return (
//   <div className="tickets-container">
//     Tickets
//     {products.map((product, id) => {
//       const productId = product.item_id
//       const numOfItems = productCount[productId]
//       return (
//         <div className="tickets" key={id} onClick={() => handleOnClick(product)}>
//           <div className="ticket">
//             {product.title} {product.date}
//           </div>
//           <div className="ticket-count">
//             {numOfItems}
//             {/* {productCount ? productCount.map((count, id)=> {
//               console.log("[124] count", count)
//               // if (product.item_id === productCount )
//             }) : null } */}
//           </div>
//           {/* {productList[id].length} */}
//         </div>
//       )
//     })}
//     <button onClick={handleCheckout}>
//       CHECKOUT
//     </button>
//   </div>
// )

// const listOfDetails = [
//   {
//     description: "The Disco Biscuits Present 'In Space when I'm With You'",
//     type: "coffee",
//   },
//   { description: "Tickets start at $15", type: "money" },
//   {
//     description: "New 60+ minute show from Ardmore Music Hall",
//     type: "flag",
//   },
// ]

// const listOfCardDetails = [
//   {
//     item_id: "42424545hj5jkh5kjh5k55l29324234er",
//     title: "Take a Walk on the Moon",
//     price: 13000,
//     image: fakeImage,
//     boxedItems: [
//       "GA",
//       "VOD ACCESS",
//       "T-SHIRT",
//       "POSTER",
//       "COASTER",
//       "POST SHOW EVENT",
//     ],
//     attributes: [
//       "General Admission to In Space when I'm With You",
//       "Bonus VOD access for 1 week after the show",
//       "Exclusive Disco Biscuits T-shirt, Poster, and Custom Wood-Burned Coaster Set",
//       "An exclusive and rare oppurtunity for a post-show analysis & intimate chat with Marc Brownstein. Marc will answer fan questions and more.",
//       "Available to ship within the U.S. and Canada only.",
//       "Shipping and handling include in price.",
//       "New 60+ minute show form Ardmore Music Hall",
//     ],
//     soldOut: true,
//   },
//   {
//     item_id: "42424545hj5jkh5kjh5k55l29e3243434r",
//     title: "Floating Around",
//     price: 8500,
//     image: fakeImage,
//     boxedItems: ["GA", "VOD ACCESS", "POSTER", "COASTER"],
//     attributes: [
//       "General Admission to In Space when I'm With You",
//       "Bonus VOD access for 1 week after the show",
//       "Exclusive Disco Biscuits Poster, and Custom Wood-Burned Coaster Set",
//       "Available to ship within the U.S. and Canada only.",
//       "Shipping and handling include in price.",
//       "New 60+ minute show form Ardmore Music Hall",
//     ],
//     soldOut: false,
//   },
//   {
//     item_id: "42424545hj5jkh5kjh5k55l29454554er",
//     title: "Mountains Hidden in Your Soul",
//     price: 8000,
//     image: fakeImage,
//     boxedItems: ["GA", "VOD ACCESS", "T-SHIRT", "POSTER"],
//     attributes: [
//       "General Admission to In Space when I'm With You",
//       "Bonus VOD access for 1 week after the show",
//       "Exclusive Disco Biscuits T-shirt, and Poster",
//       "Available to ship within the U.S. and Canada only.",
//       "Shipping and handling include in price.",
//       "New 60+ minute show form Ardmore Music Hall",
//     ],
//     soldOut: false,
//   },
//   {
//     item_id: "4545345342424545hj5jkh5kjh5k55l29er",
//     title: "A Poem for You",
//     price: 4000,
//     image: fakeImage,
//     boxedItems: ["GA", "VOD ACCESS", "POST SHOW EVENT"],
//     attributes: [
//       "General Admission to In Space when I'm With You",
//       "Bonus VOD access for 1 week after the show",
//       "An exclusive and rare oppurtunity for a post-show analysis & intimate chat with Marc Brownstein. Marc will answer fan questions and more.",
//       "New 60+ minute show form Ardmore Music Hall",
//     ],
//     soldOut: true,
//   },
//   {
//     item_id: "42424545hj5jkh5kjh5k55l2rtertrter9er",
//     title: "Minions",
//     price: 1500,
//     image: fakeImage,
//     boxedItems: ["GA"],
//     attributes: [
//       "General Admission to In Space when I'm With You",
//       "New 60+ minute show form Ardmore Music Hall",
//     ],
//     soldOut: false,
//   },
// ]
