import React from 'react';
import ReactDOM from 'react-dom';

import { Amplify } from 'aws-amplify';
import { initSentry } from './libs/errorLib';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import store from './redux-toolkit';

import './index.css';
import App from './App';


initSentry();


Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: process.env.REACT_APP_COGNITO_REGION,
		userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
		identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
	},
	Storage: {
		region: process.env.REACT_APP_S3_REGION,
		bucket: process.env.REACT_APP_S3_BUCKET_NAME,
		identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
	},
	API: {
		endpoints: [
			{
				name: "notes",
				endpoint: process.env.REACT_APP_API_GATEWAY_URL,
				region: process.env.REACT_APP_API_GATEWAY_REGION
			},
		]
	}
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
