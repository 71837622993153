import React from 'react';
import {
	Player,
	BigPlayButton,
	ControlBar,
	LoadingSpinner,
	// PosterImage,
	Shortcut,
	// ReplayControl
} from 'video-react';
import HLSSource from './HLSSource';


// import channelStream from "../hoc/channelStream";
// const videoURL = channelStream.channel100;


export default props => {
	let { videoURL, posterImage } = props;
	if (!posterImage) {
		posterImage = "/couchtour-icon.png";
	}
	// Add customized HLSSource component into video-react Player
	// The Component with `isVideoChild` attribute will be added into `Video` component
	// Please use this url if you test it from local:
	// http://www.streambox.fr/playlists/x36xhzz/x36xhzz.m3u8
	return (
		<div>
			<Player
				playsInline
				poster={posterImage}
			>
				<HLSSource
					isVideoChild
					src={videoURL}
				/>
				<LoadingSpinner />
				<Shortcut clickable={true} />
				<BigPlayButton position="center" />
				<ControlBar autoHide={true} className="ControlBar">
					{/*<ReplayControl seconds={30} order={2.3} />*/}
				</ControlBar>
			</Player>
		</div>
	);
};
