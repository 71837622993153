import React, { Component } from "react"

import "./Settings.css"

export default class Support extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="Settings">
        {/* <br /> */}
        {/* <p>If you would like support using Couchtour.tv</p> */}
        {/* <a href="https://www.instagram.com/couchtour.tv/" target="_blank" rel="noopener noreferrer">
					Please DM Couchtour.tv Instagram page
				</a> */}
        <h1 className="settings_title">How can we help you?</h1>
				<div
					className="settings_link"
				>
          Please email support@couchtour.tv for any questions.
				</div>
				<div
					className="settings_link"
				>
          Please email contact@decibelmagazine.com for merch support.
				</div>
      </div>
    )
  }
}

//How can we help you? //large white font

// please email support@couchtour.tv for any questions
// contact@decibelmagazine.com for merch support.
