import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Carousel, Button } from "react-bootstrap"
import "./Livestreams.css"


export default function Livestreams() {
	const movies = useSelector((state) => state.movies)

	// function renderLander() {
	// 	return (
	// 		<div className="lander">
	// 			<Image src="apple-touch-icon.png" fluid className="icon-center" />
	// 			<p>See the best shows live from the comfort of your home</p>
	// 			<Link to="/login" className="btn btn-info btn-lg">Login</Link>
	// 			<Link to="/signup" className="btn btn-success btn-lg">Signup </Link>
	// 		</div>
	// 	)
	// }

	function handleMoreInfoRequest(movie_id) {
		// console.log("handleMoreInfoRequest", movie_id)
	}

	function renderCarouselItem(movie, key_index) {
		let captionCarousel
		const link_string = "/livestreams/" + movie._id
		if (movie.text_overlay) {
			captionCarousel =
				<Carousel.Caption>
					<h3>{movie.title}</h3>
					<h4>{movie.air_date}</h4>
					<h4>{movie.showtime} - {movie.runtime}</h4>
					<p>{movie.overview}</p>
					<Link to={`/purchase/${movie.movie_id}`} className="btn btn-primary">More Info</Link>
				</Carousel.Caption>
		} else {
			captionCarousel = <div />
		}

		return (
			<Carousel.Item onClick={handleMoreInfoRequest(movie.movie_id)} key={key_index}>
				<Button color="success" href={link_string}>BUY TICKETS</Button>{' '}
				<img
					className="d-block w-100"
					src={movie.image}
					alt={movie.name}
				/>
				{captionCarousel}
			</Carousel.Item>
		)
	}

	function renderHome() {
		return (
			<div className="livestreams">
				<Carousel className="Testers__header">
					{ movies.map((movie, index) => renderCarouselItem(movie, index)) }
				</Carousel>
			</div>
		)
	}

	return (
		<div>
			<div className="Home">
				{ renderHome() }
			</div>
		</div>
	)
}
